<template>
  <bookings-list
      v-if="amenitiesBookings"
      :get-type-image="getTypeImage"
      :amenities-bookings="amenitiesBookings"
  ></bookings-list>
</template>

<script>

import BookingsList from '@/components/OwnersAssociation/Amenities/BookingsList/BookingsList';

export default {
  components:
      {

        BookingsList,
      },
  data() {
    return { };
  },
  methods:
      {

        getTypeImage(type) {
          switch (type) {
            case 'Quincho/parrilla':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/quincho-parrilla.svg');
            case 'Salón de usos múltiples':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/salon-de-usos-multiples.svg');
            case 'Pileta de natación':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/pileta-de-natacion.svg');
            case 'Gimnasio':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/gimnasio.svg');
            case 'Spa/Sauna':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/spa-sauna.svg');
            case 'Playroom':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/playroom.svg');
            case 'Solarium':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/solarium.svg');
            case 'Laundry':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/laundry.svg');
              // eslint-disable-next-line global-require
            case 'Microcine':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/microcine.svg');
            case 'Minigolf':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/minigolf.svg');
            default: return 'Null';
          }
        },
      },
  computed:
      {

        amenitiesBookings() {
          const bookings = this.$store.getters.amenitiesBookings;
          const { amenities } = this.$store.getters;
          const bookingsWithAmenities = [];
          console.log('bookings');
          console.log(bookings);
          const parent = this;
          // eslint-disable-next-line array-callback-return
          bookings.map((booking) => {
            const amenityId = JSON.parse(Object.keys(booking));
            const slotsDate = JSON.parse(JSON.stringify(booking[Object.keys(booking)[0]]));
            slotsDate.forEach((checkBelongsToUser) => {
              const datekey = (Object.keys(checkBelongsToUser));
              checkBelongsToUser[datekey.toString()].forEach((bookData) => {
                const currentKeyDate = this._.first(datekey);
                const bookingDate = parent.$moment(currentKeyDate);
                if (bookData.belongs_to_user === true && bookData.slotId) {
                  // eslint-disable-next-line consistent-return
                  amenities.forEach((amenity) => {
                    if (amenity.id === amenityId) {
                      const newObject = {
                        ...bookData,
                        image: amenity.amenity_data.image_url,
                        title: amenity.name,
                        cancel_reason: bookData.cancel_reason,
                        is_cancelled: bookData.is_cancelled,
                        is_bookable: amenity.is_bookable,
                        type: amenity.type.name,
                        date: bookingDate.format('DD-MM-Y'),
                        amenity_id: amenity.id,
                      };
                      return bookingsWithAmenities.push(newObject);
                    }
                  });
                }
              });
            });
          });

          return bookingsWithAmenities;
        },

      },
};
</script>
