<template>
  <div class="wrapper">
      <AttachmentsModal @upload="uploadFile" :uploading="isUploading" />
    <Messages :messages="data.messages" />
    <SendMessageForm @send="sendMessageToAdministrator" @uploadPhoto="uploadPhoto"/>
  </div>
</template>

<script>

import HeaderBack from '@/components/Layouts/HeaderBack';
import Messages from '@/components/OwnersAssociation/Chats/Messages';
import SendMessageForm from '@/components/OwnersAssociation/Chats/SendMessageForm';
import AttachmentsModal from '@/components/OwnersAssociation/Chats/AttachmentsModal';

export default {
  name: 'administration-chat',
  components:
      {
        HeaderBack,
        Messages,
        SendMessageForm,
        AttachmentsModal,
      },
  data() {
    return {
      data: [],
      originalData: [],
      messagesInterval: {},
      input_message: '',
      isUploading: false,
      fileToUpload: {},
      upload:
          {
            attachments: [],
            images: [],
          },
    };
  },

  methods:
      {

      async sendMessageToAdministrator(message, imagesArray = [], attachmentsArray = []) {
          const messageObject = {
            body: message,
            attachments: attachmentsArray.map((a) => a.id),
            images: imagesArray.map((i) => i.id),
          };

          try {
            const newMessage = await this.sendMessage(messageObject, this.data.administration.id);

            this.data.messages.push(
              {
                is_sent_by_administration: false,
                attachments: attachmentsArray,
                created_at: this.$moment(),
                images: imagesArray,
                body: newMessage.body,
              },
            );
          } catch (err) {
            console.log(err);
          }
        },

        async loadMessages() {
          try {
            const newData = await this.getMessagesByAdministration(this.$route.params.administration_id);
            console.log(`2-${this.originalData}`);
            console.log(`1-${JSON.stringify(newData.messages)}`);

         if (this.originalData == [] || this.originalData != JSON.stringify(newData.messages)) {
             console.log('es distinta');
           // this.originalData = newData;
             this.data = newData;
           this.originalData = JSON.stringify(newData.messages);
             this.data.messages = this.data.messages.reverse();
          } else console.log('es igual');

          } catch (err) {
            console.log(err);
          }
        },
        async uploadPhoto(eventData) {
          const isImage = eventData.mimeType.indexOf('image') > -1;
          const imagesArray = [];
          const attachmentsArray = [];

          if (isImage) {
            const image = await this.uploadChatImage(eventData.data, eventData.mimeType);
            imagesArray.push(image);
          } else {
            const attachment = await this.uploadChatAttachment(eventData.data, eventData.mimeType);
            attachmentsArray.push(attachment);
          }
          if (isImage) {
            setTimeout(async () => {
              await this.sendMessageToAdministrator('', imagesArray, attachmentsArray);
              // eslint-disable-next-line no-undef
              this.isUploading = false;
            }, 3000);
          } else {
            await this.sendMessageToAdministrator('', imagesArray, attachmentsArray);
            // eslint-disable-next-line no-undef
            this.isUploading = false;
          }
        },
        async uploadFile(eventData) {
          this.isUploading = true;
          const imagesArray = [];
          const attachmentsArray = [];

          const isImage = eventData.mimeType.indexOf('image') > -1;
          try {
            if (isImage) {
              const image = await this.uploadChatImage(eventData.data, eventData.mimeType);
              imagesArray.push(image);
            } else {
              const attachment = await this.uploadChatAttachment(eventData.data, eventData.mimeType);
              attachmentsArray.push(attachment);
            }
            if (isImage) {
              setTimeout(async () => {
                await this.sendMessageToAdministrator('', imagesArray, attachmentsArray);
                // eslint-disable-next-line no-undef
                $('#attachmentsModal').modal('toggle');
                this.isUploading = false;
              }, 3000);
            } else {
              await this.sendMessageToAdministrator('', imagesArray, attachmentsArray);
              // eslint-disable-next-line no-undef
              $('#attachmentsModal').modal('toggle');
              this.isUploading = false;
            }
          } catch (err) {
            console.log(err);
          }
        },
      },
  async created() {
    await this.loadMessages();

    this.messagesInterval = setInterval(async () => {
     await this.loadMessages();
    },
    (process.env.VUE_APP_CHAT_TIME_REFRESH * 5000));
  },
  beforeDestroy() {
    clearInterval(this.messagesInterval);
  },
  computed: {
    owners_association() {
      return this.$store.getters.ownersAssociation && this.$store.getters.ownersAssociation;
    },
  },
};
</script>
