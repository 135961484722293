<template>
  <div class="page-account-view amenity-page-wrapper">
    <header-back title="VOLVER" message="true"></header-back>
    <section class="bar theme-blue">
      <div class="container" v-if="amenity.name">
        <div class="bar-img">
          <img :src="getTypeImage(amenity.type.name)" />
        </div>
        <h3 class="bar-firm-name">
          {{ amenity.name }}
        </h3>
        <h5 class="bar-tagline">
          {{ amenity.type.name }} &mdash;
          <span>
            <span v-if="amenity.is_available">Habilitado</span>
            <span v-else>No habilitado</span>
          </span>
        </h5>
      </div>
    </section>

    <div class="container amenity bar-details">
      <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />
      <div class="card border-box" v-if="amenity.name">
        <!--- TODO: Cambiar :src a imagen amplificada del amenity ----->
        <img class="card-img-top" :src="amenity.amenity_data.image_url">
        <div class="card-body">
          <span v-if="amenity.description">
            <h5 class="title">Descripción</h5>
            <p class="text-table">{{ amenity.description }}</p>
          </span>
          <span v-if="amenity.amenity_data.terms_of_use">
            <h5 class="title">Términos de uso</h5>
            <p class="text-table">{{ amenity.amenity_data.terms_of_use }}
              <br>
            </p>
          </span>
          <span v-if="amenity.amenity_conditions.booking_cost">
            <h5 class="title">Valor de Reserva</h5>
            <p class="text-table">$ {{ amenity.amenity_conditions.booking_cost }}
              <br>
            </p>
          </span>
          <span v-if="days_available && checkBookingSlotsRangeDiffs(amenity.booking_slots) === false">
            <h5 class="title">Horarios</h5>
            <p class="text-table max-12">
              {{ days_available }} de {{ amenity.amenity_opening_hours }} a {{ amenity.amenity_closing_hours }}
            </p>
          </span>
          <span v-if="amenity.amenity_data.attachment_file_name">
            <h5 class="title">Adjunto</h5>
            <a v-if="!is_capacitor" :href="amenity.amenity_data.attachment_url"
              @click="downloadFallback(amenity.amenity_data.attachment_url)" target='_blank'
              class="btn btn-white value">
              <img src="@/assets/img/icons/document-alt.svg" class="icon"
                style="vertical-align: top;margin-left:-1.25em;" alt="" /> {{ amenity.amenity_data.attachment_file_name }}
            </a>
            <a v-else @click="downloadFallback(amenity.amenity_data.attachment_url)" target='_blank'
              class="btn btn-white value">
              <img src="@/assets/img/icons/document-alt.svg" class="icon"
                style="vertical-align: top;margin-left:-1.25em;" alt="" /> {{ amenity.amenity_data.attachment_file_name }}
            </a>
          </span>
          <div class="data_actions">
            <div class="actions">
              <div v-if="amenity.is_bookable &&
        amenity.is_available" @click="goToReservation(amenity)"
                class="btn btn-rounded btn-outline-primary" style="display: inline-block; ">
                Solicitar Reserva
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import axios from 'axios';
import { Dialog } from '@capacitor/dialog';
import moment from 'moment';
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'AmenityDetail',
  components: { HeaderBack },
  props: ['amenity', 'isLoading', 'getTypeImage', 'bookings'],
  computed: {
    is_capacitor() {
      if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {
        return true;
      }
      return false;
    },

    days_available() {
      return this.amenity && this.amenity.amenity_conditions.days_available.join(', ');
    },
  },
  methods: {
    goToReservation(amenity) {
      if (amenity.blocked_for_user) {
        this.$swal({
          icon: 'warning',
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: 'Contactar administración',
          showDenyButton: true,

          denyButtonText: 'Cerrar',
          title: 'Amenity bloqueado',
          text: 'En este momento no puedes solicitar una reserva',
        }).then((result) => {
          if (result) {
            if (result.isConfirmed) {
              this.$router.push(
                {
                  name: 'contacto',

                },
              );

            }
          }
        });
      } else {
        this.$router.push(

          { name: 'reservar-amenity', params: { amenity_id: amenity.id } },
        );

      }
    },
    async writeAndPreviewPdf(base64Data, fileName) {
      await Filesystem.mkdir({ path: 'joinup', directory: Directory.Cache }).then(async (createdDirectory) => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;

          console.log(`local uri  ${writedFile.uri}`);
        });
      }).catch(async (error) => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;

          console.log(`local uri  ${writedFile.uri}`);
        });
      });
    },
    async hasFilePermisions() {
      if (Capacitor.getPlatform() === 'ios') {
        return Filesystem.checkPermissions()
          .then(async (result) => {
            if ((`${result.publicStorage}` === 'prompt') || (`${result.publicStorage}` === 'denied') || (`${result.publicStorage}` === 'prompt-with-rationale')) {
              return Filesystem.requestPermissions()
                .then(async (publicStorage) => {
                  const permissionsRequested = await publicStorage.publicStorage;
                  console.log('prompt || denied || prompt-with-rationale');
                  return `${permissionsRequested}` === 'granted';
                });
            }
            return true;
          });
      }
      return true;
    },
    checkBookingSlotsRangeDiffs(bookingSlots) {
      const slots_duration_collection = [];
      bookingSlots.forEach((slot, index) => {
        const slotValues = Object.values(slot);
        const slotKey = Object.keys(slot)[0];
        const start = moment(slotValues[0].start, 'HH:mm');
        const finish = moment(slotValues[0].finish, 'HH:mm');
        const diff = moment.duration(finish.diff(start)).asMinutes();
        if (`${slotValues[0].start} - ${slotValues[0].finish}` !== slotKey.toString()) {
          if (Object.keys(slots_duration_collection).length) {
            slots_duration_collection.push(slots_duration_collection[0] + 1);
          } else {
            slots_duration_collection.push(diff);
          }
        } else {
          slots_duration_collection.push(diff);
        }
      });
      let newDiff = slots_duration_collection.length !== 0 && new Set(slots_duration_collection).size !== 1;
      if (slots_duration_collection.length === 1) {
        newDiff = true;

      }
      console.log(slots_duration_collection);
      console.log(newDiff);
      return newDiff;
    },
    async downloadFallback(doc) {
      this.loading = true;
      this.pdf = null;
      console.log('doc:', doc);
      // eslint-disable-next-line no-shadow

      await this.hasFilePermisions().then(result => {
        axios.get(doc, {
          responseType: 'arraybuffer',
        }).then(async (success) => {
          this.hasFilePermisions().then(async (resultData) => {
            //   if (resultData) {
            const arrayUrl = doc.split('/');
            const fileName = arrayUrl[arrayUrl.length - 1];
            this.title = fileName;

            const pdfData = await success.data;
            await this.writeAndPreviewPdf(pdfData, fileName.replace(' ', '-'));
            this.pdf = doc;
            this.title = fileName;
            await this.$router.push({
              name: 'pdf-view',
              params: { url: this.pdf, localuri: this.localuri },
            });
            // }
            /* else {
              await Dialog.alert({
                title: 'Información',
                message: 'Denegaste los permisos de almacenamiento y esta operación no puede ser realizada',
              });
            } */
          });

        });

      });

    },

  },
};
</script>

<style lang='scss'></style>
