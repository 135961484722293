<template>
  <div>
    <header :class="is_ios ? 'hed ios' : 'hed'">
      <div>
        <section class="main">
          <div class="col">
            <div class="action-first" @click="redirect">
              <img src="@/assets/img/icons/arrow-back-white.svg" class="icon icon-lg icon-arrow-back" alt="">
            </div>
          </div>
          <div class="col-8 text-center">
            <h5>Vista Previa</h5>
          </div>
          <div class="col" >
<!--en el if !isAndroid14&&-->
            <img v-if="localurl" src="@/assets/img/icons/compartir-icon.svg" class="icon" @click="shareLocalFile()" alt="">
          </div>
        </section>
      </div>
    </header>
    <div>
      <vue-element-loading :active="loader" spinner="spinner" color="#CE4169"></vue-element-loading>
      <div style="height: 92.5vh;">
        <!--<pdf
            @loaded="onLoad"
            v-for="i in numPages"
            :key="i"
            :src="src"
            :page="i"
        ></pdf>-->
        <PinchScrollZoom
                ref="zoomer"
                :width="widthApp"
                :height="heightApp"
                :scale="scale"

                style="border: 1px solid black"

                v-if="docType.toLowerCase() === 'pdf'">

          <vue-pdf-app   theme="light" :page-scale="40" :pdf="dataUrl" :config="config"   @open="openHandler"
                         @pages-rendered="pagesRendered"
          ></vue-pdf-app>
        </PinchScrollZoom>
        <div v-else>
          <pinch-zoom>
            <v-zoomer style="width: 100vw; border: solid 1px silver;">
              <img :src="dataUrl" style="width: 100%" alt="Img">
            </v-zoomer>
          </pinch-zoom>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import { Share } from '@capacitor/share';
  import { Device } from '@capacitor/device';
  import pdf from 'vue-pdf';
  import panzoom from 'panzoom';
  import VuePdfApp from 'vue-pdf-app';
  import { component as Viewer } from 'v-viewer';
  import VueZoomer from 'vue-zoomer';
  import PinchScrollZoom, { PinchScrollZoomEmitData } from '@coddicat/vue-pinch-scroll-zoom';
  // import this to use default icons for buttons
  import 'vue-pdf-app/dist/icons/main.css';
  import { Capacitor } from '@capacitor/core';

  export default {
    name: 'PDFview',
    props: {
      localuri: {
        type: String,
        default: null,
      },
      url: {
        type: String,
        default: null,
      },

      docType: {
        type: String,
        default: 'pdf',
      },
    },
    components: {
      pdf,
      'vue-pdf-app': () => ({
        component: new Promise(res => setTimeout(
                () => res(import(/* webpackChunkName: "pdf-viewer" */ 'vue-pdf-app')),
                3000,
        )),

      }),
      Viewer,
      VZoomer: VueZoomer.Zoomer,
      PinchScrollZoom,
    },
    data() {
      return {
        src: null,
        numPages: null,
        loader: true,
        widthApp: window.innerWidth,
        heightApp: window.innerHeight,
        scale: 1,
        isLoading: true,
        isAndroid14: false,
        localurl: null,
        dataUrl: null,
        config: {
          sidebar: false,
          toolbar: false,

          secondaryToolbar: false,

          errorWrapper: true,
        },
      };
    },
    computed: {
      is_ios() {

        return (Capacitor.getPlatform() === 'ios');
      },
    },
    watch: {
      url() {
        this.setSrc();
      },
    },
    methods: {
      redirect() {
        if (this.src !== null) {
          URL.revokeObjectURL(this.src);
          this.src = null;
          this.numPages = null;
        }
        this.$router.back();
      },
      inited(viewer) {
        this.$viewer = viewer;
        this.$viewer.view(this.index);
      },
      openHandler() {
        this.loader = true;

      },
      is_capacitor() {
        return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
      },
      async isAndroidVersion14OrHigher() {
        const info = await Device.getInfo();

        // Verifica si la plataforma es Android y la versión es mayor o igual a 14
        if (info.platform === 'android' && parseFloat(info.osVersion) >= 14) {
          return true;
        }
        return false;
      },
      pagesRendered() {
        this.loader = false;
      },
      reset() {
        this.$refs.zoomer.setData({
          scale: 1,
          originX: 0,
          originY: 0,
          translateX: 0,
          translateY: 0,
        });
      },
      setSrc() {
        this.loader = false;
        this.src = pdf.createLoadingTask(this.url);

        this.localurl = this.localuri;
        this.src.promise.then(PDF => {

          this.numPages = PDF.numPages;
          this.loader = false;
        });
      },
      async fileURL_to_blob(file_url) {
        return new Promise((resolve, reject) => {
          const request = new XMLHttpRequest();
          request.open('GET', file_url, true);
          request.responseType = 'blob';
          request.onload = function () {
            const reader = new FileReader();
            reader.readAsDataURL(request.response);
            reader.onload = function (e) {
              console.log('DataURL:', e.target.result);
              resolve(e.target.result);
            };
          };
          request.onerror = function (e) {
            reject(e);
          };
          request.send();
        });
      },
      onLoad() {
        const elem = document.getElementById('pdf');
        panzoom(elem);
      },
      async shareLocalFile() {
        return Share.share({
          title: this.title,
          text: 'Te envío este documento desde la aplicación de idata',
          url: `${this.localuri}`,
          dialogTitle: 'Compartelo con tus contactos',
        });
      },
    },
    async mounted() {
      console.log('url:', this.url);
      this.loader = true;
      this.src = null;
      this.isAndroid14 = await this.isAndroidVersion14OrHigher();
      console.log('----');
      this.dataUrl = await this.fileURL_to_blob(this.url);
      await this.setSrc();
      this.loader = false;
      if (this.url && this.docType.toLowerCase() != 'pdf') {
        this.loader = false;

      }
      // this.loader = false;
    },
  };
</script>

<style lang='scss'>
  .pdf-app .pdfViewer .page {
    border: none;
  }
  .ios
  {

    padding-top: 1.7rem !important;
  }
</style>
