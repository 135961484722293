<template >
  <NewsDetail v-if="owners_association" :owners_association="owners_association" :news="news" :img-no="imgNo" :is-loading="isLoading" :no_news="no_news"></NewsDetail>
</template>

<script>
import NewsDetail from '@/components/NewsDetail/NewsDetail';

export default {

  props: ['id'],

  components: {
    NewsDetail,
  },
  created() {
    this.loadNewsInfo();
  },
  computed: {
    no_news() {
      return !this.isLoading && this.news_type;
    },
    owners_association() {
      return this.$store.getters.ownersAssociation;
    },
  },
  methods: {
    async loadNewsInfo() {
      try {
        let idNoticia;
        if (process.env.VUE_APP_MODE === 'web') idNoticia = this.$route.params.id;
        else idNoticia = this.id;
        this.news = await this.getNewsDetail(idNoticia);
        this.news_type = true;
        this.isLoading = false;
      } catch (err) {
        this.imgNo = true;
        this.isLoading = false;
        this.news_type = false;
        // console.log(err);
      }
    },
  },
  data() {
    return {
      news: {},
      news_type: true,
      isLoading: true,
      imgNo: false,
    };
  },

};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*@import '~styles/style-one';
@import '~styles/style-two';

img, Image {
  height: 20%;
  width: 20%;
  display: block;
  margin: auto;
  margin-top: 4em;

}
*/
</style>
