<template>
  <div class="page-account-view amenity-page-wrapper">
    <header-back title="VOLVER" message="true"></header-back>

    <div class="container">
      <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />
      <div class="card border-box shadow" v-if="amenity">
        <img class="card-img-top" :src="amenity.amenity_data.image_url">
        <div class="card-body">
          <div class="row">
            <img class="document-icon col-3" style="width: 100%; " alt="" :src="getTypeImage(amenity.type.name)" />
            <span class="col-9 px-0">
              <h5 class="title">{{ amenity.name }}</h5>
              <span class="text-table text-muted">
                {{ amenity.type.name }} &mdash;
                <span>
                  <span v-if="amenity.is_available">Habilitado</span>
                  <span v-else>No habilitado</span>
                </span>
              </span>
            </span>
          </div>
          <div class="container mt-4" v-if="amenity.description">
            {{ amenity.description }}
          </div>
          <div class="data_actions">
            <div class="actions">
              <div v-if="amenity.is_bookable &&
        amenity.is_available" @click="goToReservation(amenity)"
                class="btn btn-rounded btn-outline-primary" style="display: inline-block; ">
                Solicitar Reserva
              </div>
              <div @click="redirectToDetail(amenity)" class="btn btn-rounded btn-outline-primary d-block mt-4">
                Ver Detalles
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'AmenityCard',
  components: { HeaderBack },
  data() {
    return {
      isLoading: false,
      amenity: null,
    };
  },
  computed: {
  },
  methods: {

    goToReservation(amenity) {
      if (amenity.blocked_for_user) {
        this.$swal({
          icon: 'warning',
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonText: 'Contactar administración',
          showDenyButton: true,

          denyButtonText: 'Cerrar',
          title: 'Amenity bloqueado',
          text: 'En este momento no puedes solicitar una reserva',
        }).then((result) => {
          if (result) {
            if (result.isConfirmed) {
              this.$router.push(
                {
                  name: 'contacto',

                },
              );

            }
          }
        });
      } else {
        this.$router.push(

          { name: 'reservar-amenity', params: { amenity_id: amenity.id } },
        );

      }
    },
    getTypeImage(type) {
      switch (type) {
        case 'Quincho/parrilla':
          // eslint-disable-next-line global-require
          return require('@/assets/img/amenities/quincho-parrilla.svg');
        case 'Salón de usos múltiples':
          // eslint-disable-next-line global-require
          return require('@/assets/img/amenities/salon-de-usos-multiples.svg');
        case 'Pileta de natación':
          // eslint-disable-next-line global-require
          return require('@/assets/img/amenities/pileta-de-natacion.svg');
        case 'Gimnasio':
          // eslint-disable-next-line global-require
          return require('@/assets/img/amenities/gimnasio.svg');
        case 'Spa/Sauna':
          // eslint-disable-next-line global-require
          return require('@/assets/img/amenities/spa-sauna.svg');
        case 'Playroom':
          // eslint-disable-next-line global-require
          return require('@/assets/img/amenities/playroom.svg');
        case 'Solarium':
          // eslint-disable-next-line global-require
          return require('@/assets/img/amenities/solarium.svg');
        case 'Laundry':
          // eslint-disable-next-line global-require
          return require('@/assets/img/amenities/laundry.svg');
        case 'Microcine':
          // eslint-disable-next-line global-require
          return require('@/assets/img/amenities/microcine.svg');
        case 'Minigolf':
          // eslint-disable-next-line global-require
          return require('@/assets/img/amenities/minigolf.svg');
        default: return 'null';
      }
    },
    getTypeImageNative(type) {
      switch (type) {
        case 'Quincho/parrilla':
          // eslint-disable-next-line global-require
          return '~/assets/img/amenities/quincho-parrilla.svg';
        case 'Salón de usos múltiples':
          // eslint-disable-next-line global-require
          return '~/assets/img/amenities/salon-de-usos-multiples.svg';
        case 'Pileta de natación':
          // eslint-disable-next-line global-require
          return '~/assets/img/amenities/pileta-de-natacion.svg';
        case 'Gimnasio':
          // eslint-disable-next-line global-require
          return '~/assets/img/amenities/gimnasio.svg';
        case 'Spa/Sauna':
          // eslint-disable-next-line global-require
          return '~/assets/img/amenities/spa-sauna.svg';
        case 'Playroom':
          // eslint-disable-next-line global-require
          return '~/assets/img/amenities/playroom.svg';
        case 'Solarium':
          // eslint-disable-next-line global-require
          return '~/assets/img/amenities/solarium.svg';
        case 'Laundry':
          // eslint-disable-next-line global-require
          return '~/assets/img/amenities/laundry.svg';
        case 'Microcine':
          // eslint-disable-next-line global-require
          return '~/assets/img/amenities/microcine.svg';
        case 'Minigolf':
          // eslint-disable-next-line global-require
          return '~/assets/img/amenities/minigolf.svg';
        default: return 'null';
      }
    },
    redirectToDetail(amenity) {

      console.log(JSON.stringify(amenity));
      this.$router.push({ name: 'detalle-amenity', params: { amenity_id: amenity.id } });
    },
  },
  mounted() {
    this.isLoading = true;
    this.getAmenity(this.$route.params.amenity_id).then((data) => {
      this.amenity = data;
      this.isLoading = false;
    }).catch((err) => {
      this.isLoading = false;
      console.log(err);
    });
  },
};
</script>

<style lang='scss'></style>
