<template>
  <header :class="is_ios ? 'hed ios' : 'hed'">
    <div >
      <section class="main">
        <div class="col">
            <div class="action-first" @click="redirect">
              <img src="@/assets/img/icons/arrow-back-white.svg" class="icon icon-lg icon-arrow-back" alt="">
            </div>
        </div>
        <div class="title text-center" v-if="isHeaderLoading" id="header-loading-message">
          Cargando...
        </div>
        <div class="title col-8 mt-2"  v-else>
            <marquee-text>
              <h1 class="owners-associations-name mr-5">{{owners_association.name}}</h1>
            </marquee-text>
          <h3 class="owners-associations-address">
            <span class="owners-associations-address-street">{{owners_association.address}}</span>,
            <span class="owners-associations-city">{{owners_association.city}}</span>
            <span v-if="getPlatform === 'web'" class="owners-associations-postal-code"> ({{owners_association.postal_code}})</span>
          </h3>
        </div>
        <div class="title-normal col-8" v-if="!isHeaderLoading">
          <h1 class="owners-associations-name">{{owners_association.name}}</h1>
          <h3 class="owners-associations-address">
            <span class="owners-associations-address-street">{{owners_association.address}}</span>,
            <span class="owners-associations-city">{{owners_association.city}}</span>
            <span v-if="getPlatform === 'web'" class="owners-associations-postal-code"> ({{owners_association.postal_code}})</span>
          </h3>
        </div>
        <div :class="is_capacitor? 'col help-button text-center' : 'col help-button'">
              <button type="button" class="btn btn-default sendRequestButton" @click="openModal">
                <img src="@/assets/img/icons/icono-sugerencia.svg" class="icon" alt="">
              </button>
        </div>
      </section>
    </div>
    <send-request-modal></send-request-modal>
    <owners-association-nav />
  </header>
</template>

<script>

import { Capacitor } from '@capacitor/core';
import SendRequestModal from '@/components/Layouts/sendRequest';
import OwnersAssociationNav from '@/components/Layouts/OwnersAssociationNav';

export default {
  data() {
    return {
      isSending: false,
      modal: false,
    };
  },
  components:
      {
        SendRequestModal,
        OwnersAssociationNav,
      },
  props: ['owners_association'],
  computed:
      {
        isHeaderLoading() {
          return !this.owners_association || !this.owners_association.name;
        },
        getPlatform() {
          return Capacitor.getPlatform();
        },
        is_capacitor() {
          return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
        },
        is_ios() {

          return (Capacitor.getPlatform() === 'ios');
        },
      },
  methods: {
    openModal() {

      // eslint-disable-next-line no-undef
      $('#sendRequestModal').appendTo('body').modal('show');

    },
    redirect() {
      this.$router.push('/');
    },
  },
};
</script>
<style>
.title-normal h1 {
  font-size: 1.25rem;
  font-family: Roboto-Medium, sans-serif !important;
  text-align: center;
  margin-bottom: 0;
}

.title-normal h3 {
  white-space: nowrap;
  font-size: inherit;
  color: #c5cace;
  text-align: center;
  text-overflow: ellipsis;
  font-family: Roboto, sans-serif;
  margin: 0;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}

.hed{
  position: sticky;
  top: 0;
  z-index: 10000;

}
.ios
{

  padding-top: 1.7rem !important;
}
.main{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 3rem !important;
}
.sendRequestButton {
  padding: 0 !important;
}
@media (min-width: 576px) {
  .help-button {
    text-align: right;
  }
}

@media (max-width: 576px) {
  .title-normal {
    display: none;
  }
}
</style>
