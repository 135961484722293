<template>
  <booking-detail
      v-if="owners_association && booking"
      :owners_association="owners_association"
      :booking="booking"
      @doCancelBooking="doCancelBooking"
  ></booking-detail>
</template>
<script>

import { Capacitor } from '@capacitor/core';
import BookingDetail from '@/components/OwnersAssociation/Amenities/BookingDetail/BookingDetail';

export default {
  components:
      {
        BookingDetail,
      },
  props: ['id'],
  data() {
    console.log('id', this.id);
    return {
      isLoading: false,
      cancelationResponse: {
        success: false,
        error: false,
      },
    };
  },
  methods:
      {
        async doCancelBooking() {
            const cancelFormData = {
              id: this.booking.booking_id,
            };

            this.$swal({
              text: '¿Confirma la cancelación de la reserva?',
              icon: 'warning',
              width: this.is_capacitor ? '100%' : '35%',
              padding: '1.5em',
              customClass: {
                confirmButton: 'btn btn-rounded btn-primary btn-spacing',
                cancelButton: 'btn btn-rounded btn-outline-primary btn-spacing',
              },
              buttonsStyling: false,
              showCancelButton: true,
              showCloseButton: true,
              showLoaderOnConfirm: true,
              confirmButtonText: 'Sí, cancelar',
              cancelButtonText: 'Volver',
              allowOutsideClick: () => !this.$swal.isLoading(),
              preConfirm: async () => {
                await this.newCancelBooking(this.booking.amenity_id, JSON.stringify(cancelFormData))
                  .then(() => this.$swal({
                    title: 'Reserva cancelada',
                    text: 'Enviamos a tu correo el detalle de la cancelación',
                    icon: 'success',
                    width: this.is_capacitor ? '100%' : '35%',
                    padding: '1.5em',
                    customClass: {
                      confirmButton: 'btn btn-rounded btn-primary',
                    },
                    buttonsStyling: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    confirmButtonText: 'Gracias',
                    allowOutsideClick: () => !this.$swal.isLoading(),
                    didClose: () => this.$router.push(`/consorcios/${this.owners_association.id}/bookings`),
                  }))
                  .catch((err) => {
                    if (err.status === 400) {
                      return this.$swal({
                        text: err.data.message,
                        icon: 'error',
                        width: this.is_capacitor ? '100%' : '35%',
                        padding: '1.5em',
                        customClass: {
                          confirmButton: 'btn btn-rounded btn-primary',
                        },
                        buttonsStyling: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        confirmButtonText: 'Cerrar',
                        allowOutsideClick: () => !this.$swal.isLoading(),
                        didClose: () => this.$router.push(`/consorcios/${this.owners_association.id}/bookings`),
                      });
                    }
                    return this.$swal({
                      text: `No podemos cancelar la reserva del día ${this.booking.date} en el turno de ${this.booking.slot_name}`,
                      icon: 'error',
                      width: this.is_capacitor ? '100%' : '35%',
                      padding: '1.5em',
                      customClass: {
                        confirmButton: 'btn btn-rounded btn-primary',
                      },
                      buttonsStyling: false,
                      showCancelButton: false,
                      showCloseButton: true,
                      confirmButtonText: 'Cerrar',
                      allowOutsideClick: () => !this.$swal.isLoading(),
                      didClose: () => this.$router.push(`/consorcios/${this.owners_association.id}/bookings`),
                    });
                  });
              },
            });

        },
      },
  computed:
      {
        owners_association() {
          return this.$store.getters.ownersAssociation;
        },
        is_capacitor() {
          return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
        },
        booking() {
            return this.$route.params.booking && this.$route.params.booking;
        },
      },
  mounted() {
    console.log('cap:', this.is_capacitor);
    if (!this.booking) {
      this.$router.push({ name: 'listado-reservas' });
    }
  },
};

</script>
<style>
.btn-spacing {
  margin: 0px .25em !important;
}
</style>
