<template>
  <div>
    <section class="settlement-details">
      <div class="container">

        <div class="card border-none">
          <vue-element-loading :active="isLoadingSettlements" spinner="spinner" color="#CE4169" />
          <div class="data settlement-extra" v-if="period_info.first_due_date">
            <div class="settlement-due-date" >
              <div class="value">{{period_info.first_due_date | moment('DD/MM/YY')}}</div>
              <div class="label">{{period_info.second_due_date!=null ? "1er " : ""}}Vencimiento</div>
            </div>
            <div class="settlement-amount" >
              <div class="value">{{period_info.amount_on_first_due_date | currency}}</div>
              <div class="label">{{period_info.second_due_date!=null ? "Monto 1er Vto." : "Monto total"}}</div>
            </div>
          </div>
          <div class="data settlement-extra" v-if="period_info.second_due_date">
            <div class="settlement-due-date" >
              <div class="value">{{period_info.second_due_date | moment('DD/MM/YY')}}</div>
              <div class="label">2do Vencimiento</div>
            </div>
            <div class="settlement-amount" >
              <div class="value">{{period_info.amount_on_second_due_date | currency}}</div>
              <div class="label">Monto 2do Vto</div>
            </div>
          </div>
          <div class="settlement-extra card-body text-center">
            <p class="card-text" v-if="period_info.closing_date_cta_cte">
              Total por {{ total_functional_units }}
              <br>
              Emitida {{period_info.closing_date_cta_cte | moment('DD/MM/YY') }}
            </p>
          </div>
          <div class="card-body">
            <h6 class="download-header">Descargar talón de pago</h6>
          </div>
          <ul v-if="period_info.functional_units > 1" class="functional-units list-group list-group-flush" :style="settlements.length > 1 ? 'border-bottom: none !important;':''">
            <li v-for="settlement in settlements" class="list-group-item settlement-extra" :key="settlement.id">
              <div class="form-group form-check">
                <input v-if="settlements.length > 1" type="checkbox" class="form-check-input" :disabled="!settlement.functional_unit.payment_notice" v-model.number="settlements_selected[settlement.id]" :id="settlement.id">
                <label class="form-check-label" :for="settlement.id">
                  {{settlement.functional_unit.location}}<br>
                  <span class="text-muted">Importe a pagar: {{settlement.amount | currency}}</span>
                </label>
              </div>

              <a v-if="settlement.functional_unit.payment_notice && !is_capacitor && settlements.length === 1" :href="settlement.functional_unit.payment_notice" class="btn btn-outline-primary btn-rounded">Descargar</a>

              <a v-else-if="settlements.length === 1"  @click="downloadFallback(settlement.functional_unit.payment_notice)" class="btn btn-outline-primary btn-rounded">Descargar</a>
            </li>
          </ul>

          <ul v-else class="functional-units list-group list-group-flush settlement-extra" :style="settlements.length > 1 ? 'border-bottom: none !important;':''">
            <li v-for="settlement in settlements" class="list-group-item-one" :key="settlement.id">
              <div class="form-group form-check">
                <input v-if="settlements.length > 1" type="checkbox" class="form-check-input" :disabled="!settlement.functional_unit.payment_notice" v-model.number="settlements_selected[settlement.id]"  :id="settlement.id">
                <label class="form-check-label" :for="settlement.id">
                  {{settlement.functional_unit.location}}<br>
                  <span class="text-muted">Importe a pagar: {{settlement.amount | currency}}</span>
                </label>
              </div>
              <a v-if="settlement.functional_unit.payment_notice && !is_capacitor && settlements.length === 1" :href="settlement.functional_unit.payment_notice" class="btn btn-outline-primary btn-rounded">Descargar</a>

              <a v-else-if="settlements.length === 1"  @click="downloadFallback(settlement.functional_unit.payment_notice)" class="btn btn-outline-primary btn-rounded">Descargar</a>
            </li>
          </ul>
          <div class="card-body generated_pdf settlement-extra" v-if="settlements.length > 1">
            <button id="generate-documents" type="button" :disabled="!selected" class="btn btn-outline-primary btn-rounded" @click="downloadSelected">{{doc_generator_text}}</button>
          </div>

          <div class="card-body " style="padding-bottom: 0">
            <h6 class="download-header">Planilla de gastos y comprobantes</h6>
          </div>

          <section class="documents-index">
            <div class="container">
              <div>

                <div>

                  <vue-element-loading :active="isDownloadingPeriodDocuments" spinner="spinner" color="#CE4169" />
                  <a class="document-item" >

                    <div class="document-data" style="align-items: center; margin-left: 0">
                      <div id="expensas" class="document-title" v-if="period_info.closing_date_cta_cte">
                        <span class="">Adjuntos liquidación {{period.title}}</span>
                      </div>

                      <div class="document-date descargar">

                        <button  @click="mergePeriodDocuments"  class="btn btn-outline-primary btn-rounded descargar">DESCARGAR</button>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </section>
    <section class="section-notify">
      <div class="container">
        <div class="banner">
          <img src="@/assets/img/megaphone.svg" class="notify-image">
          <h6>Notificar pago</h6>
          <p>Si pagaste con depósito o transferencia avisale a la administración para que puedan acreditar el pago.</p>
          <button v-if="bankAccounts.length !== 0 || cbuFunctionalUnit.length !== 0" class="btn btn-outline-light btn-rounded" @click="payNotification" style="height: 100%">
            Notificar pago
          </button>
        </div>
      </div>
    </section>
    <default-modal v-if="showModal">
      <div slot="header">
        <div class="col-12" style="padding: 2rem 1rem" >
          <h3 v-if="isCBU.length === 0 ">¿Has realizado la transferencia hacia alguno de estos CVU?</h3>
          <h3 v-else-if="isCVU.length === 0 ">¿Has realizado la transferencia hacia alguno de estos CBU?</h3>
          <h3 v-else>¿Has realizado la transferencia hacia alguno de estos (CBU/CVU)?</h3>
        </div>
      </div>
      <div slot="body">
        <div>
          <div v-for="unidad of cbuFunctionalUnit" :key="unidad.cbu" class="mb-2 col-12">
            <template v-if="unidad.cbu && unidad.alias_cbu">
                <span v-if="unidad.cbu_type === 'b'" class="text-muted">
                    CBU: {{unidad.cbu}}<br>
                    Alias: {{unidad.alias_cbu}}
                  </span>
              <span v-else class="text-muted">
                CVU: {{unidad.cbu}}<br>
                    Alias: {{unidad.alias_cbu}}
              </span>
              <hr>
            </template>
          </div>

        </div>
      </div>
      <template slot="footer">
        <div >
          <button class="btn btn-rounded" @click="showModal = false;">Cerrar</button>
        </div>
        <div>
          <button class="btn btn-rounded btn-outline-primary mr-2" style="width: 57px" @click="redirect">No</button>
          <button class="btn btn-rounded btn-primary" style="width: 57px" @click="showModal = false; showModal2 = true">Si</button>
        </div>
      </template>
    </default-modal>
    <default-modal id="modal-2" v-if="showModal2">
      <img slot="header" style="width: 100%" src="https://assets-latam-suite.s3.sa-east-1.amazonaws.com/joinup/GeneralAssets/header-top-comunicado.png">
      <div slot="body">
        <h4 v-if="isCBU.length === 0 ">
          Al transferir a estos CVU no es necesario que nos informes cada
          transferencia, el sistema identificará automáticamente tu pago y unidades
          funcionales
        </h4>
        <h4 v-else-if="isCVU.length === 0">
          Al transferir a estos CBU no es necesario que nos informes cada
          transferencia, el sistema identificará automáticamente tu pago y unidades
          funcionales
        </h4>
        <h4 v-else>
          Al transferir a estos (CBU/CVU) no es necesario que nos informes cada
          transferencia, el sistema identificará automáticamente tu pago y unidades
          funcionales
        </h4>
      </div>
      <div slot="footer">
        <button class="btn btn-rounded btn-primary" @click="showModal2 = false">Finalizar</button>
      </div>
    </default-modal>
<!--
    <p-d-fview v-if="pdf" :url="pdf" :title="title" :localuri="localuri"></p-d-fview>
-->
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import axios from 'axios';
import { Dialog } from '@capacitor/dialog';
import DefaultModal from '@/components/Modal/DefaultModal';

export default {
  name: 'Settlements',
  props: ['period_info', 'settlements', 'isDownloadingPeriodDocuments', 'owners_association', 'period', 'doc_generator_text', 'isLoadingSettlements'],
  components:
      {
        DefaultModal,
      },
  data() {
    return {
      settlements_selected: {},
      seleccionadas: [],
      isLoading: false,
      showModal: false,
      showModal2: false,
      period_title: '',
      pdf: null,
      title: null,
      localuri: null,
    };
  },
  computed: {
    total_functional_units() {
      return Number(this.period_info.functional_units) === 1
        ? '1 unidad funcional'
        : `${this.period_info.functional_units} unidades funcionales`;
    },
    selected() {
      const settlements_ids = Object.keys(this.settlements_selected);
      return settlements_ids.filter((i) => this.settlements_selected[i]).length > 0;
    },
    cbuFunctionalUnit() {
      return this.owners_association.functional_units.filter((unidad) => unidad.cbu !== null);
    },
    isCBU() {
      return this.cbuFunctionalUnit && this.owners_association.functional_units.filter((unidad) => unidad.cbu_type === 'b');
    },
    isCVU() {
      return this.cbuFunctionalUnit && this.owners_association.functional_units.filter((unidad) => unidad.cbu_type === 'v');
    },
    bankAccounts() {
      return this.owners_association.bank_accounts;
    },
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },

  },
  methods: {
    async writeAndPreviewPdf(base64Data, fileName) {
      await Filesystem.mkdir({ path: 'joinup', directory: Directory.Cache }).then(async (createdDirectory) => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;

          console.log(`local uri  ${writedFile.uri}`);
        });
      }).catch(async (error) => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;

          console.log(`local uri  ${writedFile.uri}`);
        });
      });
    },
    async hasFilePermisions() {
      if (Capacitor.getPlatform() === 'ios') {
        return Filesystem.checkPermissions()
                .then(async (result) => {
                  if ((`${result.publicStorage}` === 'prompt') || (`${result.publicStorage}` === 'denied') || (`${result.publicStorage}` === 'prompt-with-rationale')) {
                    return Filesystem.requestPermissions()
                            .then(async (publicStorage) => {
                              const permissionsRequested = await publicStorage.publicStorage;
                              console.log('prompt || denied || prompt-with-rationale');
                              return `${permissionsRequested}` === 'granted';
                            });
                  }
                  return true;
                });
      }
      return true;
    },
    async downloadFallback(doc) {
      this.loading = true;
      this.pdf = null;
      console.log('doc:', doc);
      // eslint-disable-next-line no-shadow

      await this.hasFilePermisions().then(result => {
        axios.get(doc, {
          responseType: 'arraybuffer',
        }).then(async (success) => {
          this.hasFilePermisions().then(async (resultData) => {
         //   if (resultData) {
              const arrayUrl = doc.split('/');
              const fileName = arrayUrl[arrayUrl.length - 1];
              const pdfData = await success.data;
              await this.writeAndPreviewPdf(pdfData, fileName.replace(' ', '-'));
              this.pdf = doc;
              this.title = fileName;
              await this.$router.push({
                name: 'pdf-view',
                params: { url: this.pdf, localuri: this.localuri },
              });
          //  } 
          /*  else {
              await Dialog.alert({
                title: 'Información',
                message: 'Denegaste los permisos de almacenamiento y esta operación no puede ser realizada',
              });
            } */
          });

        });

      });

    },

    mergePeriodDocuments() {
      this.$emit('mergePeriodDocuments');
    },
    downloadSelected() {
      console.log('this.settlements_selected:', this.settlements_selected);
      this.$emit('downloadSelected', this.settlements_selected);
    },
    getPeriodInfo(period_id) {
      this.getSettlementsSummary(this.owners_association.id, period_id)
        .then((data) => {
          this.period_title = this.owners_association.periods.filter((p) => p.id === data.period_id)[0].title;
          this.isLoading = false;
        }).catch(() => {
          this.isLoading = false;
        });
    },
    payNotification() {
      if (this.cbuFunctionalUnit.length !== 0) {
        this.showModal = true;
      } else {
        this.$router.push({ name: 'notificar-pago', params: { owners_association_id: this.owners_association.id, addUrl: '/expensas' } });
      }
    },
    redirect() {
      this.$router.push({ name: 'notificar-pago', params: { owners_association_id: this.owners_association.id, addUrl: '/expensas' } });
    },
  },
  created() {
    this.isLoading = true;
  },
  mounted() {
    console.log('periodo:', JSON.stringify(this.period_info));
    console.log('settlements:', this.settlements);
    this.getPeriodInfo(this.owners_association.periods[this.owners_association.periods.length - 1].id);
  },
};
</script>

<style lang='scss'>
@media (max-width: 650px) {
  .descargar {
    width: 100%;
  }
  .document-data {
    flex-direction: column
  }
  #expensas {
    font-size: 0.7rem;
    margin-bottom: 1rem;
    letter-spacing: 0;
  }
  @media (max-width: 500px) {
    #generate-documents{
      width: 100%;
    }
  }

}

</style>
