<template web>
  <header :class="is_ios ? 'hed ios' : 'hed'">
   <div :class="color ? 'header_login' : ''">
    <div class="container-fluid">
      <section class="main">

        <router-link v-if="url" :to="url">
          <div class="action-first">
            <img src="@/assets/img/icons/arrow-back-white.svg" class="icon icon-lg icon-arrow-back" alt="">
            <h1 class="btn back_header"
                :style="is_capacitor==true ? 'color: #FFFFFF; margin-bottom: auto;text-align:left !important' : 'color: #CE4169; margin-bottom: auto;text-align:left !important'" >VOLVER</h1>

          </div>

        </router-link>
        <div class="router-link-active" v-else>
          <div

              class="action-first" @click="$router.go(-1)">
            <img src="@/assets/img/icons/arrow-back-white.svg" class="icon icon-lg icon-arrow-back" alt="">
            <h1 class="btn back_header" :style="is_capacitor==true ? 'color: #FFFFFF; margin-bottom: auto; text-align:left !important' : 'color: #CE4169; margin-bottom: auto; text-align:left !important'">VOLVER</h1>
          </div>
        </div>

      </section>
    </div>
   </div>
  </header>
</template>

<script>
import { Capacitor } from '@capacitor/core';

export default {
  props: ['title', 'url', 'message', 'color'],
  computed:
      {
        is_capacitor() {
          if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {
            return true;
          }
          return false;
        },
        is_ios() {

          return (Capacitor.getPlatform() === 'ios');
        },
      },
};

</script>
<style>
.icon-arrow-back{
  width: 26px !important;
}
.ios
{

  padding-top: 1.7rem !important;
}
</style>
