<template>
  <div class="modal fade" style="z-index: 10000; top: 15%" id="sendRequestModal" @hide="tab = 1" tabindex="-1" role="dialog" aria-labelledby="sendRequestModal" aria-hidden="true">
    <div class="modal-dialog" role="document" v-if="tab === 1">
      <div class="modal-content">
        <div class="modal-header sendRequestHeader">
          <h5 class="modal-title sendRequestTitle" id="attachmentsModalLabel">¿En qué podemos ayudarte?</h5>
        </div>

        <div class="modal-body">

          <div class="row my-5" style="align-items: flex-end;" align="center">
            <div class="col" @click="contactAdmin">
              <img src="@/assets/img/administrador.png"  alt="" style="width: 50%">
              <h5 class="modal-title sendRequestTitle" id="attachmentsModalLabel">Contactarme con el administrador</h5>
            </div>
            <div class="col" @click="tab = 2">
              <img src="@/assets/img/pajaro.png"  alt="" style="width: 65%">
              <h5 class="modal-title sendRequestTitle" id="attachmentsModalLabel">Enviar sugerencia sobre la App</h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-dialog" role="document" v-else>
      <div class="modal-content">
        <div class="modal-header sendRequestHeader">
          <div class="row">
            <div class="col-1">
              <img src="@/assets/img/icons/arrow-back-white.svg" @click="tab = 1" class="icon icon-lg icon-arrow-back" alt="">
            </div>
            <div class="col-10">
              <img src="@/assets/img/pajaro.png"  alt="">
            </div>
            <div class="col-1"></div>
          </div>
          <h5 class="modal-title sendRequestTitle" id="attachmentsModalLabel" v-if="isSending">Tu sugerencia ha sido enviada</h5>
          <h5 class="modal-title sendRequestTitle" id="attachmentsModalLabel" v-else>Envianos las sugerencias que te resulten importantes para mejorar el servicio</h5>
        </div>

        <div class="modal-body">

          <div style="display: grid;">

            <div v-if="isSending" style="text-align:center;">
              <br><br>
              <h1 class="modal-title" id="attachmentsModalLabel" v-if="isSending">GRACIAS</h1>
              <br><br>
              <h5 class="modal-title sendRequestTitle" id="attachmentsModalLabel" v-if="isSending">En breve nos comunicaremos contigo</h5>
            </div>

            <label class="field" style="color: #272529;" v-else>
   <textarea rows="4" cols="50" placeholder="Escribe tu Sugerencia" maxlength="250" class="field__input sendRequestText a-field__input"
             id="commentary" name="comment" form="usrform" style="text-align: left;">
</textarea>
            </label>

          </div>
        </div>
        <div class="modal-footer">
          <vue-element-loading :active="isSending" spinner="spinner" color="#CE4169" />
          <button type="button" class="btn btn-primary" @click="send">
            <span v-if="isSending">Enviando</span>
            <span v-else>Enviar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>

  .modal-body {

    height: auto !important;

  }

</style>
<script>

import { App } from '@capacitor/app';

export default {
  name: 'SendRequestModal',
  data() {
    return {
      fileToUpload: {},
      isSending: false,
      tab: 1,
    };
  },

 async created() {
   App.addListener('backButton', () => {
     // eslint-disable-next-line no-undef

     console.log('backubutton modal');
     // eslint-disable-next-line no-undef
     this.closeWindow();
     // eslint-disable-next-line no-undef

     // eslint-disable-next-line no-undef

   });

   // eslint-disable-next-line no-undef
   $('#sendRequestModal').on('hide.bs.modal', () => {
    // this.closeWindow();
     this.$store.commit('openRequestModal', false);
     console.log('hide');
   });
   // eslint-disable-next-line no-undef
   $(window).on('show.bs.modal', () => {
     this.$store.commit('openRequestModal', true);
     console.log('show');
   });
   // eslint-disable-next-line no-undef

   // eslint-disable-next-line no-undef

   // eslint-disable-next-line no-undef
  /* $('#sendRequestModal').on('hidden.bs.modal', () => {
     // do something…
     // this.closeWindow();
     console.log('modal close');
   });
   */
   // eslint-disable-next-line no-undef

 },
  methods:
      {
        async closeWindow() {
          // eslint-disable-next-line no-undef
          $('#sendRequestModal').appendTo('body').modal('hide');
                    await this.$store.commit('openRequestModal', false);
          // eslint-disable-next-line no-undef

          // eslint-disable-next-line no-undef
          console.log('cerro');
          // eslint-disable-next-line no-undef

         // this.$store.commit('openRequestModal', false);
        },
        async openWindow() {
          // eslint-disable-next-line no-undef

          await this.$store.commit('openRequestModal', true);

          // eslint-disable-next-line no-undef
          console.log('abrio');
          // eslint-disable-next-line no-undef

          // this.$store.commit('openRequestModal', false);
        },
        async send() {
          // eslint-disable-next-line no-undef
          const messageValue = $('#commentary').val();
          if (!messageValue) {
            this.$swal({
              type: 'error',
              title: 'Ocurrió un error',
              text: 'Recuerda agregar tu sugerencia!',
            });
            return;
          }
          this.isSending = true;
          const messageObject = {
            data: messageValue,
          };
          await this.sendRequestSuite(messageObject);
          // eslint-disable-next-line no-undef
          await $('#sendRequestModal').modal('hide');
          await this.$store.dispatch('openRequestModal', false);
          this.tab = 1;
          this.isSending = false;
          // eslint-disable-next-line no-undef
          $('#commentary').val = '';
        },
        async contactAdmin() {
          if (this.$store.getters.ownersAssociation === null) {
            this.$store.dispatch('setOwnersAssociation', Number(this.$store.getters.ownersAssociations[0].id)).then((data) => {

              // eslint-disable-next-line no-undef
              $('#sendRequestModal').modal('hide');

              this.tab = 1;
              this.$router.push(`/consorcios/${data.id}/contacto`);
            });
          } else {
            // eslint-disable-next-line no-undef
            $('#sendRequestModal').modal('hide');
            this.tab = 1;

            if (this.$store.getters.ownersAssociation.administration.messages_enabled) {
              this.$router.push(`/mensajes/administracion/${this.$store.getters.ownersAssociation.administration.id}`);
            } else {
              this.$router.push(`/consorcios/${this.$store.getters.ownersAssociation.id}/contacto`);
            }
          }
          await this.$store.dispatch('openRequestModal', false);
        },
      },
};
</script>
