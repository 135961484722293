var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.$route.name==='digital-expenses-promo')?_c('div',{staticClass:"fondo-gradiente",style:(_vm.getPlatform !== 'web' || (this.$route.name!=='digital-expenses' && this.$route.name!=='success-message') ? "background:  #002639;" : "background:  #002639 url('/assets/img/trama.png')  center 110% repeat-x!important;")},[_c('transition',{attrs:{"name":"fade","appear":"","mode":"out-in"}},[_c('router-view')],1),(this.$route.name!=='expenses_landing'
         && this.$route.name!=='ayuda'
         && this.$route.name!=='confirmar-clave'
         && this.$route.name!=='news_landing'
         &&  this.$route.name!=='digital-expenses'
         &&  this.$route.name!=='comprobantes_landing'
         &&  this.$route.name!=='documentos_landing'
         &&  this.$route.name!=='detalle-comprobante-landing'
         && this.$route.name!=='success-message'
         && this.$route.name!=='digital-expenses-promo'
         && this.showBottomIcon
)?_c('div',{staticClass:"footer-image",attrs:{"id":"img_foot"}},[_c('img',{staticClass:"idata-profile",staticStyle:{"width":"10% !important"},attrs:{"src":require("@/assets/img/idata-alt.svg"),"alt":"idata-profile-pic"}}),( this.is_capacitor )?_c('div',{staticStyle:{"color":"white","margin-top":".3em","width":"100%"}},[_c('div',{staticStyle:{"font-size":"1em"}},[_vm._v(" Version: "),_c('small',[_vm._v(_vm._s(_vm.version)+_vm._s(_vm.environment == "production" ? '' : 'D'))])])]):_vm._e()]):_vm._e(),(Number(_vm.windowWidth) < 480 && this.$route.name==='digital-expenses-promo'
         && this.showBottomIcon
)?_c('div',{staticStyle:{"width":"100% !important","bottom":"0px !important","max-height":"100%"},attrs:{"id":"img_foot_2"}},[_c('img',{staticStyle:{"transform":"scale(0.8)"},attrs:{"src":require("@/assets/img/img-promo-inferior.svg"),"alt":"idata-profile-pic"}}),( this.is_capacitor )?_c('div',[_c('div',{staticStyle:{"font-size":"1em"}},[_vm._v(" Version: "),_c('small',[_vm._v(_vm._s(_vm.version)+_vm._s(_vm.environment == "production" ? '' : 'D'))])])]):_vm._e()]):(Number(_vm.windowWidth) >= 480 && this.$route.name==='digital-expenses-promo'
         && this.showBottomIcon
)?_c('div',{staticClass:"footer-image",staticStyle:{"width":"55vw !important","bottom":"0px !important","max-height":"55vw"},attrs:{"id":"img_foot_2"}},[_c('img',{staticStyle:{"transform":"scale(0.8)"},attrs:{"src":require("@/assets/img/img-promo-inferior.svg"),"alt":"idata-profile-pic"}}),( this.is_capacitor )?_c('div',[_c('div',{staticStyle:{"font-size":"1em"}},[_vm._v(" Version: "),_c('small',[_vm._v(_vm._s(_vm.version)+_vm._s(_vm.environment == "production" ? '' : 'D'))])])]):_vm._e()]):_vm._e()],1):_c('div',{class:this.$route.name!=='expenses_landing'
  && this.$route.name!=='news_landing'
  && this.$route.name!=='comprobantes_landing'
  && this.$route.name!=='documentos_landing'
  && this.$route.name!=='detalle-comprobante-landing'

  ?
  'fondo' :
  'sin-fondo',style:(_vm.getPlatform !== 'web' || (this.$route.name!=='digital-expenses' && this.$route.name!=='success-message') ? "background:  #002639;" : "background:  #002639 url('/assets/img/trama.png')  center 110% repeat-x!important;")},[_c('transition',{attrs:{"name":"fade","appear":"","mode":"out-in"}},[_c('router-view')],1),(this.$route.name!=='expenses_landing'
         && this.$route.name!=='ayuda'
         && this.$route.name!=='confirmar-clave'
           && this.$route.name!=='comprobantes_landing'
  && this.$route.name!=='documentos_landing'
  && this.$route.name!=='detalle-comprobante-landing'
         && this.$route.name!=='news_landing'
         &&  this.$route.name!=='digital-expenses'
         && this.$route.name!=='success-message'
         && this.$route.name!=='digital-expenses-promo'
         && this.showBottomIcon
)?_c('div',{staticClass:"footer-image",attrs:{"id":"img_foot"}},[_c('img',{staticClass:"idata-profile",staticStyle:{"width":"10% !important"},attrs:{"src":require("@/assets/img/idata-alt.svg"),"alt":"idata-profile-pic"}}),( this.is_capacitor )?_c('div',{staticStyle:{"color":"white","margin-top":".3em","width":"100%"}},[_c('div',{staticStyle:{"font-size":"1em"}},[_vm._v(" Version: "),_c('small',[_vm._v(_vm._s(_vm.version)+_vm._s(_vm.environment == "production" ? '' : 'D'))])])]):_vm._e()]):_vm._e(),(this.$route.name==='digital-expenses-promo'
         && this.showBottomIcon
)?_c('div',{staticClass:"footer-image",staticStyle:{"width":"100% !important","bottom":"0px !important","top":"55rem !important"},attrs:{"id":"img_foot_2"}},[_c('img',{staticClass:"idata-profile",staticStyle:{"color":"white","margin-top":".3em","width":"100%"},attrs:{"src":require("@/assets/img/img-promo-inferior.svg"),"alt":"idata-profile-pic"}}),( this.is_capacitor )?_c('div',[_c('div',{staticStyle:{"font-size":"1em"}},[_vm._v(" Version: "),_c('small',[_vm._v(_vm._s(_vm.version)+_vm._s(_vm.environment == "production" ? '' : 'D'))])])]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }