<template>
    <div>
        <div class="card card-expenses mb-4">
            <div class="card-body text-center">
                <h2>Sin Conexión</h2>
                <p>Parece que no tienes conexión a internet en tu dispositivo para seguir operando.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default { name: 'no-connection' };
</script>
