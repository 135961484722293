<template web>
  <div class="page-account-view page-news-view">
       <section class="bar theme-blue">
   
      <div class="container" >
        <div class="bar-avatar">
          <span><img src="@/assets/img/telephones.svg"  class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">Teléfonos Útiles</h3>

        <br>
      </div>
    </section>

    <div class="container bar-news">

      <p class="news-detail shadow" style=" min-height: 12rem;"
         v-html="owners_association.useful_telephones" />

  </div>
  </div>

</template>

<script>

import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  components: {
    HeaderBack,
  },
  name: 'Telephones',
  props: ['owners_association'],

};
</script>

<style lang='scss'>

</style>
