import { render, staticRenderFns } from "./loginLayout.vue?vue&type=template&id=28a7a2ed&"
import script from "./loginLayout.vue?vue&type=script&lang=js&"
export * from "./loginLayout.vue?vue&type=script&lang=js&"
import style0 from "./loginLayout.vue?vue&type=style&index=0&id=28a7a2ed&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./loginLayout.vue?vue&type=custom&index=0&blockType=link&href=https%3A%2F%2Ffonts.cdnfonts.com%2Fcss%2Famplesoft-pro&rel=stylesheet"
if (typeof block0 === 'function') block0(component)

export default component.exports