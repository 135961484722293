<template >
  <Telephones :owners_association="owners_association">
  </Telephones >
</template>

<script>
import Telephones from '@/components/Telephones/Telephones';

export default {

  props: ['id'],

  components: {
    Telephones,
  },
  computed: {

        owners_association() {
      return this.$store.getters.ownersAssociation;
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*@import '~styles/style-one';
@import '~styles/style-two';

img, Image {
  height: 20%;
  width: 20%;
  display: block;
  margin: auto;
  margin-top: 4em;

}
*/
</style>
