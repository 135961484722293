<template>
  <div id="newSplash">
    <div class="wrapper_new">
      <div class="main_login">
        <div class="login-form">

          <div class="head" align="center">
            <img src="@/assets/img/usuario.svg" alt="user-profile" class="img_login">
          </div>

          <h1>Datos del usuario</h1>
          <form v-on:submit="login_submit">
            <div class="form-group">
              <input
                  id="email"
                  required="required"
                  v-model="credentials.user"
                  class="form-control"
                  aria-label="login.email"
                  type="email"
                  :v-focus="is_capacitor ? false :true"
                  placeholder="Ingresa tu correo electrónico">
            </div>

            <div class="form-group cont_pass">
              <input
                  id="password"
                  required="required"
                  aria-label="login.password"
                  v-model="credentials.password"
                  :type="passwordType"
                  class="form-control more_margin"
                  placeholder="Ingresa tu contraseña">
              <div class="btn_eye" style="margin-top: -60px;">
                <img aria-label="login.showInputText" @click="switchPassword" :src="[passwordIcon]" class="img_info" alt="passwordIcon" style="cursor: pointer">
              </div>
            </div>
            <div class="alert alert-danger" v-if="error" style="font-size: .8em !important;">
              <img src="@/assets/img/error.svg" alt="error" class="img_info"> {{error}}
            </div>

            <div class="alert alert-danger" v-if="$route.params.error_message"  style="font-size: .8em !important;">
              <img src="@/assets/img/error.svg" alt="error" class="img_info">
              {{$route.params.error_message}}
            </div>
            <div
                class="alert alert-danger"
                v-if="$route.query.error_message ==='UserNotFoundException'"  style="font-size: .8em !important;">
              <img src="@/assets/img/error.svg" alt="error" class="img_info">
              Los datos ingresados son incorrectos
            </div>
            <div class="alert alert-success" v-if="$route.params.success_message"  style="font-size: .8em !important;">
              <img src="@/assets/img/exito.svg" alt="exito" class="img_info">
              {{$route.params.success_message}}
            </div>
            <button :disabled="loading" id="submitButton" type="submit" aria-label="login.submitButton" class="btn btn-rounded btn-primary btn-block">
              <div v-if="loading" class="spinner-border spinner-border-sm ma-auto" role="status">
              </div>
              <span v-else>ACCEDER</span>
            </button>
          </form>

          <div class="copy-login">
            <p>
              <router-link to="/resetear-clave" class="btn_login">¿Olvidaste tu contraseña?</router-link>
              <br><br>
            <!--  <router-link to="/contacto-administracion" class="btn_login">
                ¿Olvidaste el correo electronico?
              </router-link>  <br><br> -->
            
              <router-link to="/menu-ayuda" class="btn_login">¿Necesitas ayuda para el ingreso?</router-link>
            </p>
            <p v-if="is_capacitor">
              <br>
              <a  href="https://www.i-data.com.ar/politicas-de-usuario/" style="color:white"><small>Términos y Condiciones de uso</small></a>
            </p>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';

export default {
  name: 'loginWeb',

  props: {
    passwordType: String,
    passwordIcon: String,
    
    error: {
      type: String,
      default: null,
    },
    loading: Boolean,
  },
  created() {
    this.getVersion();
    this.getBuild();

  },
  data() {

    return {
      hidePassword: true,

      credentials:
          {
            user: null,
            password: null,
          },
      version: null,
      build: null,
      environment: process.env.NODE_ENV,
    };
  },
  computed: {
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },

  },
  methods: {
  
    async getVersion() {
       this.version = await App.getInfo().then(result => result.version);
    },
    async getBuild() {
       this.build = await App.getInfo().then(result => result.build);
    },
    switchPassword() {
      this.$emit('onSwitch');
    },
    switchIcon() {
      this.$emit('onKeyboardShow');
    },
    login_submit(e) {
      e.preventDefault();
      this.$emit('onLogin', this.credentials);
    },

  },
};
</script>

<style lang='scss' scoped>
.footer-image{
  @media (max-width: 576px) { display: none }
}
.login-form{
  z-index: 10;
}
.hide
{
  display:none !important;
}
.idata-profile{
  z-index: 0;
}
.copy-login{
  z-index: 10;
  background: rgb(0, 38, 57);
}
.btn_login
{
  color: #CE4169;
  display: inline-table;
  text-transform: uppercase;
  text-align: center;
  padding: 0.5rem 1rem;
  font-size: .8em;
  line-height: 1.5;
  border-radius: 0.4rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
border: 2px solid #CE4169 !important;
  border-radius: 18px;
  text-decoration: none;
}
</style>
