<template>
  <div id="app" class="app">
    <default-layout v-if="isLogged"></default-layout>
    <login-layout v-else></login-layout>
  </div>

</template>
<script>

import { Capacitor, Plugins, AppState } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Network } from '@capacitor/network';
import axios from 'axios';
import loginLayout from '@/components/Layouts/loginLayout';
import defaultLayout from '@/components/Layouts/defaultLayout';
import ButtomMenu from '@/components/BottomMenu/bottomMenu';

const { App } = Plugins;
export default {
  components: {
    ButtomMenu,
    defaultLayout,
    loginLayout,
    //   isWindowOpen,
  },

  data() {
    let ruta = '/login';
    const token = localStorage.getItem('AccessToken');
    if (token !== null && token !== '') {
      ruta = '/home';
    }
    return {
      route: ruta,
    };
  },
  computed: {
    isLogged() {
      return this.$store.getters.logged;
    },
    is_ios() {
      console.log(Capacitor.getPlatform());
      if ((Capacitor.getPlatform() === 'ios')) {
        return true;
      }
      return false;
    },
  },
  methods: {
    showExitConfirm() {
      // invierto por los colores

      this.$swal({
        icon: 'question',
        title: '',
        showDenyButton: true,
        text: '¿Estás seguro que deseas salir',
        confirmButtonText: 'Si',
        denyButtonText: 'No',

      }).then((result) => {
        if (result.isConfirmed) {
          App.exitApp();
        }
      });

    },
    setBackButton() {
      App.addListener('backButton', ({ canGoBack }) => {
        console.log('backbutton app');
        console.log(this.$store.getters.openRequestModal);
        if (!this.$store.getters.openRequestModal) {
          if (this.$route.name === 'consorcios' || this.$route.name === 'mensajes-administracion' || this.$route.name === 'notificaciones' || this.$route.name === 'cuenta') {
            this.showExitConfirm();
          } else {
            window.history.back();
          }
        }
      });
    },
    unSetBackButton() {
      App.removeAllListeners();
      App.addListener('backButton', ({ canGoBack }) => {
        if (this.$route.name === 'consorcios' || this.$route.name === 'mensajes-administracion' || this.$route.name === 'notificaciones' || this.$route.name === 'cuenta') {
          this.showExitConfirm();
        } else {
          window.history.back();
        }
        console.log('BackButton disabled');
      });
    },
    async checkNetworkStatus() {
      const status = await Network.getStatus();
      if (`${status.connectionType}` === 'wifi' || `${status.connectionType}` === 'cellular') {
        axios.get('https://suite.i-data.com.ar').then(result => {
          if (this.$route.name === 'sin-conexion') {
            App.removeAllListeners();
            this.setBackButton();
            this.$store.dispatch('setActiveConnection', true);
            this.$router.push({ name: 'consorcios' });
          } else {
            return true;
          }
        }).catch(error => {
          if (this.$route.name === 'sin-conexion') {
            return true;
          }
          this.unSetBackButton();
          this.$store.dispatch('setActiveConnection', false);
          this.$router.push({ name: 'sin-conexion' });
        });
      } else if (`${status.connectionType}` === 'unknown') {
        this.unSetBackButton();
        this.$store.dispatch('setActiveConnection', false);
        this.$router.push({ name: 'sin-conexion' });
      } else {
        this.unSetBackButton();
        this.$store.dispatch('setActiveConnection', false);
        this.$router.push({ name: 'sin-conexion' });
      }
      console.log('Network status:', `${status.connectionType}`);
    },
  },
  async created() {
    await this.$store.dispatch('runFirstLoad');

    // eslint-disable-next-line global-require
    this.setBackButton();
    if ((Capacitor.getPlatform() === 'android') || (Capacitor.getPlatform() === 'ios')) {
      await this.checkNetworkStatus();
      Network.addListener('networkStatusChange', status => {
        if ((`${status.connectionType}` === 'wifi') || (`${status.connectionType}` === 'cellular')) {
          axios.get('https://suite.i-data.com.ar').then(result => {
            if (this.$route.name === 'sin-conexion') {
              App.removeAllListeners();
              this.setBackButton();
              this.$store.dispatch('setActiveConnection', true);
              this.$router.push({ name: 'consorcios' });
            } else {
              return true;
            }
          }).catch(error => {
            if (this.$route.name === 'sin-conexion') {
              return true;
            }
            this.unSetBackButton();
            this.$store.dispatch('setActiveConnection', false);
            this.$router.push({ name: 'sin-conexion' });

          });
        } else if (`${status.connectionType}` === 'unknown') {
          this.unSetBackButton();
          this.$store.dispatch('setActiveConnection', false);
          this.$router.push({ name: 'sin-conexion' });
        } else {
          this.unSetBackButton();
          this.$store.dispatch('setActiveConnection', false);
          this.$router.push({ name: 'sin-conexion' });
        }
        console.log('Network status changed', status);
      });
    }
  },
  mounted() {
    // Check the device with Capacitor
    const device = Capacitor.getPlatform();
    console.log(`Capacitor platform is: ${device}`);

  },

};
</script>
<style></style>
<!-- compilar lo hace bien, proba haciendo click en el boton y por la consola vemos Add "scoped" attribute to limit CSS to this component only -->
