<template>
 <amenity-detail
     :bookings="bookings"
     :is-loading="isLoading"
     :amenity="amenity"
     :get-type-image="getTypeImage"></amenity-detail>
</template>
<script>

import AmenityDetail from '@/components/OwnersAssociation/Amenities/AmenityDetail/AmenityDetails';

export default {
  components:
      {
        AmenityDetail,
      },
  props: ['amenityNative'],
  data() {
    return {
      amenity: [],
      isLoading: true,
    };
  },
  methods:
      {
        getTypeImage(type) {
          switch (type) {
            case 'Quincho/parrilla':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/quincho-parrilla.svg');
            case 'Salón de usos múltiples':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/salon-de-usos-multiples.svg');
            case 'Pileta de natación':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/pileta-de-natacion.svg');
            case 'Gimnasio':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/gimnasio.svg');
            case 'Spa/Sauna':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/spa-sauna.svg');
            case 'Playroom':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/playroom.svg');
            case 'Solarium':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/solarium.svg');
            case 'Laundry':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/laundry.svg');
            case 'Microcine':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/microcine.svg');
            case 'Minigolf':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/minigolf.svg');
            default: return 'null';
          }
        },
        getTypeImageNative(type) {
          switch (type) {
            case 'Quincho/parrilla':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/quincho-parrilla.svg';
            case 'Salón de usos múltiples':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/salon-de-usos-multiples.svg';
            case 'Pileta de natación':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/pileta-de-natacion.svg';
            case 'Gimnasio':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/gimnasio.svg';
            case 'Spa/Sauna':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/spa-sauna.svg';
            case 'Playroom':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/playroom.svg';
            case 'Solarium':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/solarium.svg';
            case 'Laundry':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/laundry.svg';
            case 'Microcine':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/microcine.svg';
            case 'Minigolf':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/minigolf.svg';
            default: return 'null';
          }
        },
      },
  computed:
      {
        bookings() {
          return this.$store.getters.amenitiesBookings;
        },
      },
  created() {
    this.$store.dispatch('fetchAmenitiesBookings', this.$store.getters.ownersAssociation.id);
    if (process.env.VUE_APP_MODE === 'web') {
      this.getAmenity(this.$route.params.amenity_id).then((data) => {
        console.log('data:', data);
        this.amenity = data;
        this.isLoading = false;
      }).catch((err) => {
        this.isLoading = false;
        console.log(err);
      });
    }
  },
};

</script>
