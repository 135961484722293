<template>
  <div>
      <img v-if="Number(windowWidth) < 480" src="@/assets/img/img-promo-superior.svg" class="img-promo-superior" style=" display: block;
  margin: auto; width: 100%;
    transform: scale(0.8);"/>
    <div class="text-promo">
    <pre>
             Adherite al <FONT COLOR="#01477e">Formato Digital</FONT> y
    participa de sorteos por el importe de tu
                   expensa del mes!!
    Tambien podras recibirla por <FONT COLOR="#01477e">WhatsApp</FONT>
  </pre>
  </div>
      <img v-if="Number(windowWidth) >= 480" src="@/assets/img/img-promo-superior.svg" class="img-promo-superior" style=" display: block;
  margin: auto; width: 55vw;
    transform: scale(0.8);"/>
      <div class="text-promo">
    <pre>
             Adherite al <FONT COLOR="#01477e">Formato Digital</FONT> y
    participa de sorteos por el importe de tu
                   expensa del mes!!
    Tambien podras recibirla por <FONT COLOR="#01477e">WhatsApp</FONT>
  </pre>
      </div>

    <form v-if="Number(windowWidth) < 480" class="p-4 text-center dualcol-test" v-on:submit="suscribe" style="display: block; margin: auto; width: 100%; transform: scale(0.8);">
      <h4 style="color: white" v-if="ownerAssociation">{{ownerAssociation.administration}}</h4>
      <div class="help-text text-left my-4 font-weight-bold" >
        <div class="mb-2">
          <span>Bienvenido:</span>
          <br>
          <span v-if="ownerAssociation">{{ownerAssociation.functional_unit_owners}}</span>
          <br>
          <span v-if="ownerAssociation">{{ownerAssociation.owners_association_name}}</span>
        </div>
        <span>Estoy de acuerdo con el envío de las expensas por Mail y/o Whatsapp y la cancelación del envío en formato impreso en todas mis unidades funcionales</span>
      </div>
      <label class="b-contain mb-2">
        <span>Acepto <a id="popup" style="text-decoration:none;color:#ce4169">Terminos y Condiciones</a></span>
        <input type="checkbox" v-model="aceptChecked">
        <div class="b-input"></div>
      </label>
      <div v-if="aceptChecked">
          <input v-if="email_decoded && email_decoded !== 'rellename'" type="email"  class="form-control" disabled  v-model="datosUser.email"
                 placeholder="Aquí escriba su correo electrónico">
          <input v-else type="email"  class="form-control"  v-model="datosUser.email"
               placeholder="Aquí escriba su correo electrónico">

        <div class="input-group has-validation" style="margin-bottom: 1.5rem">
          <input v-if="email_decoded && email_decoded !== 'rellename'"  type="email"  :class="!emailIguales && datosUser.emailCheck ? 'form-control is-invalid' : 'form-control'" id="validationServer03" aria-describedby="validationServer03Feedback" v-model="datosUser.emailCheck"
                 placeholder="Repita su correo electrónico" disabled>
          <input v-else  type="email"  :class="!emailIguales && datosUser.emailCheck ? 'form-control is-invalid' : 'form-control'" id="validationServer03" aria-describedby="validationServer03Feedback" v-model="datosUser.emailCheck"
                 placeholder="Repita su correo electrónico">
          <div v-if="!emailIguales && datosUser.emailCheck" id="validationServerEmailFeedback" class="invalid-feedback">
            Los emails no coinciden
          </div>
        </div>
        <input type="number" id="phone_number" v-model="datosUser.phone" class="form-control"
               placeholder="Ingrese su nro. de celular si desea recibirlo por Whatsapp">
        <div class="input-group has-validation" style="margin-bottom: 1.5rem">
          <input type="number"  :class="!numerosIguales && (datosUser.phone || datosUser.phoneCheck) ? 'form-control is-invalid' : 'form-control'" id="validationServer01" aria-describedby="validationServer01Feedback" v-model="datosUser.phoneCheck"
                 placeholder="Repita su nro. de celular">
          <div v-if="!numerosIguales && datosUser.phoneCheck" id="validationServerNumberFeedback" class="invalid-feedback">
            Los numeros de teléfonos no coinciden
          </div>
        </div>

        <button :disabled="loading || !numerosIguales || !emailIguales" type="submit" class="btn btn-rounded btn-primary btn-block mb-2" >
          <div v-if="loading" class="spinner-border spinner-border-sm ma-auto" role="status">
          </div>
          <span v-else>ACEPTAR</span>
        </button>
      </div>
    </form>
    <form v-else class="p-4 text-center dualcol-test" v-on:submit="suscribe" style="display: block; margin: auto; width: 50vw; transform: scale(0.8);">
      <h4 style="color: white" v-if="ownerAssociation">{{ownerAssociation.administration}}</h4>
      <div class="help-text text-left my-4 font-weight-bold" >
        <div class="mb-2">
          <span>Bienvenido:</span>
          <br>
          <span v-if="ownerAssociation">{{ownerAssociation.functional_unit_owners}}</span>
          <br>
          <span v-if="ownerAssociation">{{ownerAssociation.owners_association_name}}</span>
        </div>
        <span>Estoy de acuerdo con el envío de las expensas por Mail y/o Whatsapp y la cancelación del envío en formato impreso en todas mis unidades funcionales</span>
      </div>
      <label class="b-contain mb-2">
        <span>Acepto <a id="popup" style="text-decoration:none;color:#ce4169">Terminos y Condiciones</a></span>
        <input type="checkbox" v-model="aceptChecked">
        <div class="b-input"></div>
      </label>
      <div v-if="aceptChecked">
        <input v-if="email_decoded && email_decoded !== 'rellename'" type="email"  class="form-control" disabled  v-model="datosUser.email"
               placeholder="Aquí escriba su correo electrónico">
        <input v-else type="email"  class="form-control"  v-model="datosUser.email"
               placeholder="Aquí escriba su correo electrónico">

        <div class="input-group has-validation" style="margin-bottom: 1.5rem">
          <input v-if="email_decoded && email_decoded !== 'rellename'"  type="email"  :class="!emailIguales && datosUser.emailCheck ? 'form-control is-invalid' : 'form-control'" id="validationServer03" aria-describedby="validationServer03Feedback"  v-model="datosUser.emailCheck"
                 placeholder="Repita su correo electrónico" disabled>
          <input v-else  type="email"  :class="!emailIguales && datosUser.emailCheck ? 'form-control is-invalid' : 'form-control'" id="validationServer03" aria-describedby="validationServer03Feedback" v-model="datosUser.emailCheck"
                 placeholder="Repita su correo electrónico">
          <div v-if="!emailIguales && datosUser.emailCheck" id="validationServerEmailFeedback" class="invalid-feedback">
            Los emails no coinciden
          </div>
        </div>
        <input type="number" id="phone_number" v-model="datosUser.phone"  class="form-control"
               placeholder="Ingrese su nro. de celular si desea recibirlo por Whatsapp">
        <div class="input-group has-validation" style="margin-bottom: 1.5rem">
          <input type="number"  :class="!numerosIguales && (datosUser.phone || datosUser.phoneCheck) ? 'form-control is-invalid' : 'form-control'" id="validationServer01" aria-describedby="validationServer01Feedback" v-model="datosUser.phoneCheck"
                 placeholder="Repita su nro. de celular">
          <div v-if="!numerosIguales && datosUser.phoneCheck" id="validationServerNumberFeedback" class="invalid-feedback">
            Los numeros de teléfonos no coinciden
          </div>
        </div>

        <button :disabled="loading || !numerosIguales || !emailIguales" type="submit" class="btn btn-rounded btn-primary btn-block mb-2" >
          <div v-if="loading" class="spinner-border spinner-border-sm ma-auto" role="status">
          </div>
          <span v-else>ACEPTAR</span>
        </button>
      </div>
    </form>

  </div>
</template>

<script>
export default {
  name: 'LandingPromo',
  props: [],
  data() {
    return {
      datosUser: {
        is_promotional: true,
      },
      aceptChecked: false,
      loading: false,
      ownerAssociation: null,
      email_decoded: null,
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    settlementId() {
      return this.$route.params.settlement_id;
    },
    email() {
      return this.$route.params.email;
    },
    emailIguales() {
        if ((this.datosUser.phone === this.datosUser.phoneCheck) && (this.datosUser.phone && this.datosUser.phoneCheck)) return true;
        if ((!this.datosUser.email && !this.datosUser.emailCheck) || (this.datosUser.email == 'rellename')) return false;
        return this.datosUser.email === this.datosUser.emailCheck;
    },
    numerosIguales() {
        if ((this.datosUser.email === this.datosUser.emailCheck) && (this.datosUser.email && this.datosUser.emailCheck)) return true;

        return this.datosUser.phone === this.datosUser.phoneCheck;
    },
  },

  methods: {
    async checkEmail(email) {
      return String(email)
              .toLowerCase()
              .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    },
    async checkQR() {
      await this.checkQRSuscriptorPromo(this.settlementId, this.email).then(async (res) => {
         this.ownerAssociation = res.data;
         this.email_decoded = await res.data.email;
         console.log(res.data.email);
           if (res.data.email != 'rellename') {
             this.datosUser.email = this.email_decoded;
             this.datosUser.emailCheck = this.email_decoded;
           }
          console.log('emailIguales', this.emailIguales);
          console.log('numIguales', this.numerosIguales);

      }).catch(() => {
        this.$router.push({ name: 'error-message' });
      });
    },
    suscribe(e) {
      e.preventDefault();
      Object.assign(this.datosUser, this.ownerAssociation);
      if (this.datosUser.email == 'rellename') this.datosUser.email = this.datosUser.emailCheck;

         this.suscribeDigitalExpenses(this.datosUser).then(() => {
      this.$router.push({ name: 'success-message', params: { ownerAssociation: this.ownerAssociation } });
      }).catch(() => {
         this.$swal.fire({
           icon: 'error',
           title: 'Lo sentimos',
           text: 'Hubo un error al realizar la solicitud',
         });
       });
    },
    onResize() {
      this.windowWidth = window.windowWidth;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
 this.checkQR();
document.getElementById('popup').addEventListener('click', () => {
  this.$swal.fire({
           icon: 'info',
           title: 'Terminos y Condiciones',
           html: `1. ORGANIZADOR: Las presentes bases y condiciones que rigen la
promoción “cambia papel x premios” es organizada por Power Solutions
srl (en adelante “el organizador”), con domicilio en la Avda.
Independencia 903 piso 2 de la ciudad de Mar del Plata.<br><br>
2. ACEPTACIÓN DE LAS BASES: Quienes participan de la promoción, por
su sola participación, declaran conocer y aceptar todos y cada uno de
los términos descriptos en estas bases.
Dichos términos podrán ser modificados por el organizador, sin que ello
implique alterar la esencia de la promoción ni afectar derechos
adquiridos por los participantes. Dichas modificaciones serán publicadas
en el mismo medio que las presentes bases, no siendo alterada la
aceptación previamente brindada por el participante.<br><br>
3. PARTICIPACIÓN: Podrán participar de la promoción, todas las personas
humanas mayores de DIECIOCHO (18) años, que sean copropietarios
de las unidades funcionales que trabajen sobre el sistema IDATA.<br><br>
4. MECÁNICA DE PARTICIPACIÓN:
Para participar, cada persona deberá ingresar en el botón “Adherite” y
confirmar el mail que el sistema tiene registrado, pudiendo agregar un
numero de celular para la recepción de sus expensas también por
WhatsApp. La confirmación final indica la aceptación de no recibir mas
en forma impresa la liquidación de expensas, solo digitalmente por mail
y/o WhatsApp.<br><br>
5. SELECCIÓN DE LOS GANADORES: El día 1 de cada mes se sortea
entre todas las personas que se adhirieron el mes anterior. -El
ORGANIZADOR se comunicará con el copropietario y la administración
de su consorcio para realizar el pago del premio.<br><br>
6. PREMIO: El premio consistirá en el monto resultante del prorrateo de los
gastos comunes del mes de la adhesión de la unidad funcional adherida,
no se incluyen deudas anteriores, punitorios, gastos particulares, fondo,
ni otros tipos de gastos. <br><br>
7. RESPONSABILIDAD: El organizador no será responsable si los datos
de los participantes hubieran sido erróneamente consignados. De igual
manera, el organizador no será responsable de ningún reclamo de
ningún tipo efectuado por terceros que se atribuyan cualquier derecho
de propiedad intelectual o de cualquier naturaleza vinculados con esta
campaña.
El organizador no será responsable por las interrupciones, cortes y/o
cualquier deficiencia que pudiere dificultar el acceso y/o navegación en
aplicaciones para celular ni website de la marca por parte de los
participantes.<br><br>
8. ORGANIZADOR. FACULTADES.
El ORGANIZADOR, sin que ello le genere carga, obligación y/o
responsabilidad alguna podrá:<br><br> a) Modificar las presentes BASES<br><br> b)
solicitar la reválida o rectificación de las adhesiones,<br><br> c) Dejar sin efecto o
suspender esta promoción antes de la notificación a los GANADORES, <br><br>
d) Prorrogar cada uno de los plazos de vigencia y fechas fijadas antes
de la finalización del período de inscripción previsto en estas BASES,<br><br> e)
Modificar la fecha de entrega de los PREMIOS o ejecución de los
mismos, la cual no podrá ser superior a DOCE (12) meses,<br><br> f) Solicitar a
los PARTICIPANTES y/o potenciales GANADORES, en cualquier
momento antes de la finalización de la promoción, que verifiquen sus
datos y/o los corroboren y/o rectifiquen los mismos, así como también
ratifiquen su inscripción.<br><br>
9. JURISDICCIÓN: Para cualquier controversia originada en la presente
campaña regirá la jurisdicción de los Tribunales en lo Contencioso
Administrativo Federal con asiento en la Ciudad de Mar del Plata Aires,
a la que los participantes aceptan someterse voluntariamente,
renunciando a cualquier otro fuero o jurisdicción.`,
showClass: {
            popup: 'animate__animated animate__fadeIn',
        },
hideClass: {
            popup: 'animate__animated animate__fadeOut',
        },
         });
});
  },
};
</script>
<link href="https://fonts.cdnfonts.com/css/amplesoft-pro" rel="stylesheet"/>
<prestyle lang='scss'>
@import url('https://fonts.cdnfonts.com/css/amplesoft-pro');
pre{
  font-family: 'AmpleSoft Pro', sans-serif !important;
  font-size: 1vw;
  color: #f2ffff;
}
.text-promo{
    transform: translate(51vw, -8vw) rotate(-3deg);
    position: absolute;
}
.expensas-digital {
  background-color: #ef5d3c !important;
  background-image: linear-gradient(75deg, #ef5d3c 40%, #da198b 75%) !important;
}
.b-contain *, .b-contain *::before, .b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.b-contain span {
  line-height: 1.82;
  font-size: 0.7rem;
  font-family: inherit;
}

.b-contain {
  position: relative;
  padding-left: 1.16rem;
  cursor: pointer;
  margin-bottom: 0.46rem;
}

.b-contain input[type="checkbox"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: rgba(241, 245, 248, 1);
  transition: background 250ms;
  border: none;
  border-radius: 0rem;
}

.b-contain input[type="radio"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1rem;
  width: 1rem;
  background: rgba(241, 245, 248, 1);
  transition: background 250ms;
  border: none;
  border-radius: 2.0rem;
}

.b-contain input[type="checkbox"] ~ .b-input::after {
  content: '';
  position: absolute;
  display: none;
  left: 0.2rem;
  top: 0rem;
  width: 0.30rem;
  height: 0.5rem;
  border: solid rgba(255, 255, 255, 1);
  border-width: 0 4px 4px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.b-contain input[type="radio"] ~ .b-input::after {
  content: '';
  position: absolute;
  display: none;
  left: .25rem;
  top: .25rem;
  width: .75rem;
  height: .75rem;
  border-radius: 2.0rem;
  background: rgba(255, 255, 255, 1);
  transition: background 250ms;
}

.b-contain input:disabled ~ .b-input::after {
  border-color: rgba(135, 149, 161, 1);
}

.b-contain input:checked ~ .b-input::after {
  display: block;
}

.b-contain:hover input ~ .b-input,
.b-contain input:focus ~ .b-input {
  background: rgb(231, 238, 243);
}

.b-contain input:focus ~ .b-input {
  box-shadow: 0 0 0 2px rgba(206, 65, 105, 0.6);
}

.b-contain input:checked ~ .b-input {
  background: rgba(206, 65, 105, 1);
  border-color: rgba(206, 65, 105, 1);
}

.b-contain input[type="checkbox"]:disabled ~ .b-input {
  background: rgba(241, 245, 248, 1);
  border-color: rgba(184, 194, 204, 1);
  opacity: 0.6;
  cursor: not-allowed;
}

.b-contain input[type="radio"]:disabled ~ .b-input {
  background: rgba(241, 245, 248, 1);
  border-color: rgba(184, 194, 204, 1);
  opacity: 0.6;
  cursor: not-allowed;
}

.b-contain input[type="radio"]:disabled ~ .b-input::after {
  background: rgba(135, 149, 161, 1);
}

.b-contain input:checked:focus ~ .b-input, .b-contain:hover input:not([disabled]):checked ~ .b-input {
  background: rgba(206, 65, 105, 1);
  border-color: rgba(206, 65, 105, 1);
}

.b-contain .b-input::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.411rem;
  margin-top: -0.411rem;
  background: rgba(206, 65, 105, 1);
  border-radius: 0.104rem;
  opacity: .6;
  z-index: 99999;
  transform: scale(0);
}

@keyframes b-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes b-ripple-duplicate {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.b-contain input + .b-input::before {
  animation: b-ripple 250ms ease-out;
}

.b-contain input:checked + .b-input::before {
  animation-name: b-ripple-duplicate;
}

.b-contain .b-input::before {
  visibility: hidden;
}

.b-contain input:focus + .b-input::before {
  visibility: visible;
}

.b-contain:first-child .b-input::before {
  visibility: hidden;
}
.fondo-gradiente {
  background-color: #ef5d3c !important;
  background-image: linear-gradient(75deg, #ef5d3c 40%, #da198b 75%) !important;
    }
.dualcol-test{
background: #002639 !important;
-webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
-moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
margin-top: 0 !important;
    }
</prestyle>
