<template>
  <div class="page-owners-associations-overview">
    <section class="last-settlement">
      <div class="container-lg" style="position: relative; max-width: 1030px;">
        <div class="home_card">
          <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />
          <div id="divPadre">
            <div class="featured">
              <p class="section-header">
                Última liquidación de expensas
              </p>
              <div class="separator"></div>
              <div v-if="period_title" class="settlement-header">
                <h2 class="settlement-month">
                  {{ period_title }}
                </h2>
                <h4 class="settlement-year">
                  <br>
                </h4>
              </div>
            </div>
            <div id="divHijo">
              <img :src="city_image.img_normal" :srcset="city_image.img_2x + ' 2x'"
                class="owners-association-city-image" />
            </div>

            <section class="settlement-overview">
              <div class="data" v-if="period.first_due_date">
                <div class="settlement-due-date">
                  <div class="value">{{ period.first_due_date | moment('DD/MM/YY') }}</div>
                  <div class="label">{{ period.second_due_date != null ? "1er " : "" }}Vencimiento</div>
                </div>
                <div class="settlement-amount">
                  <div class="value">{{ period.amount_on_first_due_date | currency }}</div>
                  <div class="label">{{ period.second_due_date != null ? "Monto 1er Vto." : "Monto total" }}</div>
                </div>
              </div>
              <div v-if="period.second_due_date" class="actions_separator"> </div>
              <div class="data" v-if="period.second_due_date">
                <div class="settlement-due-date" style="padding-top:2rem !important">
                  <div class="value">{{ period.second_due_date | moment('DD/MM/YY') }}</div>
                  <div class="label">2do Vencimiento</div>
                </div>
                <div class="settlement-amount" style="padding-top:2rem !important">
                  <div class="value">{{ period.amount_on_second_due_date | currency }}</div>
                  <div class="label">Monto 2do Vto.</div>
                </div>
              </div>
              <div class="actions_separator">
              </div>
              <div class="data_actions">
                <div id="download" class="secondary-actions">
                  <button id="settlement-expenses-btn" class="btn btn-white value" @click="getAnnexDocuments">
                    <img alt="" class="icon" src="@/assets/img/icons/document-alt.svg" style="vertical-align: top;" />
                    PLANILLA DE GASTOS
                  </button>

                  <button id="payment-notice-btn" class="btn btn-white value" @click="getPaymentsDocuments">
                    <img alt="" class="icon" src="@/assets/img/icons/document-alt.svg" style="vertical-align: top;" />
                    TALÓN DE PAGO
                  </button>

                </div>
                <div class="actions_separator">
                </div>
                <div v-if="owners_association.id" class="actions">
                  <!--  <button v-if="owners_association.mp_access_token"
                             class="btn btn-rounded btn-outline-primary value"
                             @click="redirect('pagar', { owners_association_id: owners_association.id })">
                       Pagar
                     </button>
-->

                  <button v-if="owners_association.expense_payment_methods!=null && owners_association.expense_payment_methods.length>0 " class="btn btn-rounded btn-outline-primary value"
                    @click="redirect('pagarexpensaspagas', { owners_association_id: owners_association.id })">
                    Pagar
                  </button>
                  <button v-if="bankAccounts.length !== 0 || cbuFunctionalUnit.length !== 0"
                    class="btn btn-rounded btn-primary value" @click="payNotification" style="height: 100%">
                    Notificar pago
                  </button>
                  <button v-if="bankAccounts.length !== 0 || cbuFunctionalUnit.length !== 0"
                    class="btn btn-rounded btn-outline-primary value" @click="payNotificationsList"
                    style="height: 100%">
                    Ver Notificaciones de pago
                  </button>
                  <!--  <button class="btn btn-rounded btn-primary value"
                          @click="redirect('expensas', { owners_association_id: owners_association.id })">
                    Ver detalles
                  </button>-->
                  <button v-if="this.owners_association.invoices_show_type != 0" class="btn btn-rounded btn-primary value"
                    @click="resolveRedirect()">
                    Ver comprobantes
                  </button>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <section v-if="owners_association.administration" class="administration theme-fuchsia">
        <div class="container">
          <div class="administration-logo">
            <div class="administration-avatar"><span>{{ owners_association.administration.name | to_avatar }}</span>
            </div>
            <div class="administration-name">
              <h3 class="administration-firm-name">{{ owners_association.administration.name }}</h3>
              <h5 class="administration-tagline">{{ owners_association.administration.firm_name }}</h5>
            </div>
          </div>
          <div class="actions">
            <router-link :to="{ name: 'contacto', params: { owners_association_id: owners_association.id } }"
              class="btn btn-rounded btn-outline-light">Contacto
            </router-link>
          </div>
        </div>
      </section>
    </section>
    <default-modal v-if="showModal" style="z-index: 10000">
      <div slot="header">
        <div class="col-12" style="padding: 2rem 1rem">
          <h3 v-if="isCBU.length === 0">¿Has realizado la transferencia hacia alguno de estos CVU?</h3>
          <h3 v-else-if="isCVU.length === 0">¿Has realizado la transferencia hacia alguno de estos CBU?</h3>
          <h3 v-else>¿Has realizado la transferencia hacia alguno de estos (CBU/CVU)?</h3>
        </div>
      </div>
      <div slot="body">
        <div>
          <div v-for="unidad of cbuFunctionalUnit" :key="unidad.cbu" class="mb-2 col-12">
            <template v-if="unidad.cbu && unidad.alias_cbu">
              <span v-if="unidad.cbu_type === 'b'" class="text-muted">
                CBU: {{ unidad.cbu }}<br>
                <span v-if="unidad.alias_cbu">Alias: {{ unidad.alias_cbu }}</span>
              </span>
              <span v-else class="text-muted">
                CVU: {{ unidad.cbu }}<br>
                <span v-if="unidad.alias_cbu">Alias: {{ unidad.alias_cbu }}</span>
              </span>
              <hr>
            </template>
          </div>

        </div>
      </div>
      <template slot="footer">
        <div>
          <button class="btn btn-rounded" @click="showModal = false;">Cerrar</button>
        </div>
        <div>
          <button class="btn btn-rounded btn-outline-primary mr-2" style="width: 57px"
            @click="redirect('notificar-pago', { owners_association_id: owners_association.id })">No</button>
          <button class="btn btn-rounded btn-primary" style="width: 57px"
            @click="showModal = false; showModal2 = true">Si</button>
        </div>
      </template>
    </default-modal>
    <default-modal id="modal-2" v-if="showModal2">
      <img slot="header" style="width: 100%"
        src="https://assets-latam-suite.s3.sa-east-1.amazonaws.com/joinup/GeneralAssets/header-top-comunicado.png">
      <div slot="body">
        <h4 v-if="isCBU.length === 0">
          Al transferir a estos CVU no es necesario que nos informes cada
          transferencia, el sistema identificará automáticamente tu pago y unidades
          funcionales
        </h4>
        <h4 v-else-if="isCVU.length === 0">
          Al transferir a estos CBU no es necesario que nos informes cada
          transferencia, el sistema identificará automáticamente tu pago y unidades
          funcionales
        </h4>
        <h4 v-else>
          Al transferir a estos (CBU/CVU) no es necesario que nos informes cada
          transferencia, el sistema identificará automáticamente tu pago y unidades
          funcionales
        </h4>
      </div>
      <div slot="footer">
        <button class="btn btn-rounded btn-primary" @click="showModal2 = false">Finalizar</button>
      </div>
    </default-modal>
    <!--
    <p-d-fview v-if="pdf" :url="pdf" :title="title" :localuri="localuri"></p-d-fview>
-->

  </div>
</template>

<script>
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import axios from 'axios';
import { Dialog } from '@capacitor/dialog';
import DefaultModal from '@/components/Modal/DefaultModal';
import OwnersAssociationHeader from '@/components/Layouts/OwnersAssociationHeader';

export default {
  name: 'Home',
  components:
  {
    OwnersAssociationHeader,
    DefaultModal,
  },
  props: ['owners_association', 'city_image'],
  filters:
  {
    to_avatar(string) {
      return string.substr(0, 1);
    },
  },
  data() {
    return {
      period: {},
      period_title: '',
      isLoading: false,
      showModal: false,
      showModal2: false,
      vouchers: null,
      pdf: null,
      title: null,
      localuri: null,

    };
  },

  methods: {
    async checkCapacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
    async downloadOrMergePeriodDocuments(documents) {
      console.log(documents.length);
      const checkCapacitor = await this.checkCapacitor();
      switch (true) {
        case documents.length === 1:
          if (checkCapacitor) {
            await this.downloadFallback(documents[0].url);
          } else {
            window.location.href = documents[0].url;
          }
          break;
        case documents.length > 1:
          try {
            const inputDocs = documents.map((d) => d.url);
            await this.mergeDocuments(inputDocs).then(async (result) => {
              if (checkCapacitor) {
                this.downloadFallback(result.url);
              } else {
                window.location.href = result.url;
              }
            }).catch(() => {
              this.$swal.fire({
                icon: 'error',
                title: 'Ocurrió un error',
                text: 'No se ha podido generar el archivo!',
              });
            });
            break;
          } catch (err) {
            break;
          }
        default:
          return this.$swal.fire({
            icon: 'info',
            title: 'Lo siento...',
            text: 'No tiene ningún documento para descargar',
          });
      }
      return true;
    },

    async getPaymentsDocuments() {
      const documents = this.period.period_documents.filter((d) => d.type === 'Avisos de pago');
      await this.$store.dispatch('setLoading', 'Descargando datos');
      await this.downloadOrMergePeriodDocuments(documents);
      await this.$store.dispatch('setNotLoading');
      return true;
    },
    async getAnnexDocuments() {
      console.log('x1');
      const liquidaciones = this.period.period_documents.filter((d) => d.type === 'Liquidaciones de gastos');
      const anexos = this.period.period_documents.filter((d) => d.type === 'Anexos');
      const documents = liquidaciones.concat(anexos);
      await this.downloadOrMergePeriodDocuments(documents);

      return true;
    },

    async writeAndPreviewPdf(base64Data, fileName) {
      await Filesystem.mkdir({ path: 'joinup', directory: Directory.Cache }).then(async (createdDirectory) => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;
          console.log(`local uri  ${writedFile.uri}`);
        });
      }).catch(async (error) => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;
          console.log(`local uri  ${writedFile.uri}`);
        });
      });
    },
    async hasFilePermisions() {
      if (Capacitor.getPlatform() === 'ios') {
        return Filesystem.checkPermissions()
          .then(async (result) => {
            if ((`${result.publicStorage}` === 'prompt') || (`${result.publicStorage}` === 'denied') || (`${result.publicStorage}` === 'prompt-with-rationale')) {
              return Filesystem.requestPermissions()
                .then(async (publicStorage) => {
                  const permissionsRequested = await publicStorage.publicStorage;
                  console.log('prompt || denied || prompt-with-rationale');
                  return `${permissionsRequested}` === 'granted';
                });
            }
            return true;
          });
      }
      return true;
    },

    async checkPayments() {
      try {
        if (this.owners_association.id) {
          const result = await this.checkExpensasPagasPayment(this.owners_association.id);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async downloadFallback(docURL) {
      this.loading = true;
      this.pdf = null;
      console.log('doc:', docURL);
      // eslint-disable-next-line no-shadow

      await this.hasFilePermisions().then(result => {
        axios.get(docURL, {
          responseType: 'arraybuffer',
        }).then(async (success) => {
          this.hasFilePermisions().then(async (resultData) => {
            //    if (resultData) {
            const arrayUrl = docURL.split('/');
            const fileName = arrayUrl[arrayUrl.length - 1];
            const pdfData = await success.data;
            await this.writeAndPreviewPdf(pdfData, fileName.replace(' ', '-'));
            this.title = fileName;

            this.pdf = docURL;
            this.title = fileName;
            await this.$router.push({
              name: 'pdf-view',
              params: { url: this.pdf, localuri: this.localuri },
            });
            //  }
            /* else {
              await Dialog.alert({
                title: 'Información',
                message: 'Denegaste los permisos de almacenamiento y esta operación no puede ser realizada',
              });
            } */
          });

        });

      });
    },

    getPeriodInfo(period_id) {
      this.getSettlementsSummary(this.owners_association.id, period_id)
        .then((data) => {
          console.log(data);
          this.period = data;
          this.period_title = this.owners_association.periods.filter((p) => p.id === data.period_id)[0].title;
          this.isLoading = false;
        }).catch(() => {
          this.isLoading = false;
        });
    },
    payNotification() {
      if (this.cbuFunctionalUnit.length !== 0) {
        this.showModal = true;
      } else {
        this.$router.push({ name: 'notificar-pago', params: { owners_association_id: this.owners_association.id } });
      }
    },
    payNotificationsList() {

      this.$router.push({ name: 'listado-pagos', params: { owners_association_id: this.owners_association.id } });

    },
    redirect(name, params, query = null) {
      this.$router.push({ name, params, query });
    },
    resolveRedirect() {
      console.log(`type:${this.owners_association.invoices_show_type}`);
      if (this.owners_association.invoices_show_type === 1) {
        this.redirect('comprobantes', { owners_association_id: this.owners_association.id }, { period_id: this.owners_association.periods[this.owners_association.periods.length - 1].id });
      } else {
        this.redirect('documentos', { owners_association: this.owners_association }, { docType: 'comprobantes', period_id: this.owners_association.periods[this.owners_association.periods.length - 1].id });
      }
      //  this.redirect('comprobantes', { owners_association_id: this.owners_association.id }, { period_id: this.owners_association.periods[this.owners_association.periods.length - 1].id });

      //    this.redirect('documentos', { owners_association: this.owners_association }, { docType: 'comprobantes' });
    },
  },
  computed: {
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
    cbuFunctionalUnit() {
      return this.owners_association.functional_units.filter((unidad) => unidad.cbu !== null);
    },
    isCBU() {
      return this.cbuFunctionalUnit && this.owners_association.functional_units.filter((unidad) => unidad.cbu_type === 'b');
    },
    isCVU() {
      return this.cbuFunctionalUnit && this.owners_association.functional_units.filter((unidad) => unidad.cbu_type === 'v');
    },
    bankAccounts() {
      return this.owners_association.bank_accounts;
    },
  },

  created() {
    this.checkPayments();
  },
  mounted() {
    this.isLoading = true;
    this.getPeriodInfo(this.owners_association.periods[this.owners_association.periods.length - 1].id);
    this.getConfirmVouchers(this.owners_association.id).then((voucher) => {
      this.vouchers = voucher;
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });

  },
};
</script>

<style lang='scss'>
#modal-2 .modal-body {
  height: 100% !important;
}

#modal-2 .modal-footer {
  justify-content: flex-end;
}
</style>
