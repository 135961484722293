<template web>

  <section class="news">
    <vue-element-loading
        :active="isLoading"
        spinner="spinner"
        color="#CE4169"

    />
    <div class="container">
      <p class="section-header">Últimas noticias</p>
    </div>

    <div class="news-index">
      <div class="container">

        <br>

        <!-- si se deseas poner un div de carga: -->
        <!-- <div v-if="loading">Cargando...</div> -->

        <div class="empty-state empty-state-news" v-if="!isLoading && news && news.length===0">
          <div class="wrapper">
            <h4>¡Aún no hay noticias!</h4>
            <p>Tu administración todavía no publicó ninguna noticia sobre este consorcio.</p>
            <img src="@/assets/img/empty-states/no-hay-noticias.png" class="image-empty" alt="">
          </div>
        </div>

        <div class="news-item news-item-with-image" v-for="n in news"
             :key="n.id">
          <router-link
                  v-if="n.is_user_allowed"
                  :key="n.id"
                  :to="{ name: 'detalle-noticia', params: { id: n.id, owners_association_id: owners_association.id } }"
                  class="news-item news-item-with-image"
          >
            <div class="news-main">
              <div class="text-center news-image" v-if="n.thumbnail">
                <img class="news-image-new rounded" :src="n.thumbnail" alt="">
              </div>
              <div class="news-data">

                <h3 class="news-title">{{n.category}} - {{n.title | truncate(40)}}</h3>
                <div class="news-body body_news">{{n.excerpt}}</div>
              </div>
            </div>
            <p class="news-meta">
              <span class="news-administration-name">Publicado el </span>
              <span class="news-date">{{ n.created_at | moment('ll') }}</span>
            </p>
          </router-link>

        </div>
      </div>

    </div>
    <div class="actions text-center" v-if="!isLoading && !stopLoading">
      <br>
      <button
          :disabled="stopLoading"
          @click="loadNews"
          class="btn btn-rounded btn-outline-primary">
        Más noticias
      </button>
    </div>
  </section>

</template>

<script>

export default {
  name: 'news',
  props: ['owners_association'],
  data() {
    return {
      news: [],
      stopLoading: false,
      isLoading: false,
      page: 0,
    };
  },
  mounted() {
    this.loadNews();
  },
  methods:
      {
        loadNews() {
          this.page += this.page + 1;
          this.isLoading = true;

          this.getNews(this.owners_association.id, this.page)
            .then((data) => {
              this.news = this.news.concat(data);
              const json = JSON.stringify(this.news);
              localStorage.setItem('News', json);
              this.$store.commit('new_notification', false);
              if (this.page >= data.paging.pageCount) {
                this.stopLoading = true;
              }

              this.isLoading = false;
            })
            .catch(() => {
              /*
                  Stop loading se pone en true debido a que en este punto,
                  El API va a arrojar un 404
                  */

              this.stopLoading = true;
              this.isLoading = false;
            });
        },
      },
};

</script>
