<template>
 <Home
     v-if="owners_association !== null"
     :owners_association="owners_association"
     :city_image="city_image"
     @ongetAnnexDocuments="getAnnexDocuments"
     @ongetPaymentsDocuments="getPaymentsDocuments"
     ></Home>
</template>
<script>
import Home from '@/components/Home/Home';

export default {
  components: { Home },

  data() {
    return {
      notification: false,
    };
  },
  methods:
      {
        async getPaymentsDocuments(period) {
          const documents = period.period_documents.filter((d) => d.type === 'Avisos de pago');
          await this.$store.dispatch('setLoading', 'Descargando datos');
          await this.downloadPeriodDocuments(documents);
          await this.$store.dispatch('setNotLoading');
          return true;
        },
        async getAnnexDocuments(period) {
          console.log('x1');
          const liquidaciones = period.period_documents.filter((d) => d.type === 'Liquidaciones de gastos');
          const anexos = period.period_documents.filter((d) => d.type === 'Anexos');
          const documents = liquidaciones.concat(anexos);
          await this.downloadPeriodDocuments(documents);
          return true;
        },
        async downloadPeriodDocuments(documents) {
          console.log(documents.length);
          switch (true) {
            case documents.length === 1:
              window.location.href = documents[0].url;
              break;
            case documents.length > 1:
              try {
                const inputDocs = documents.map((d) => d.url);
                await this.mergeDocuments(inputDocs).then((result) => {

                  window.location.href = result.url;
                    }).catch(() => {
                    this.$swal.fire({
                        icon: 'error',
                        title: 'Ocurrió un error',
                        text: 'No se ha podido generar el archivo!',
                    });
                });
                break;
              } catch (err) {
                break;
              }
            default:
              return this.$swal.fire({
                icon: 'info',
                title: 'Lo siento...',
                text: 'No tiene ningún documento para descargar',
              });
          }
          return true;
        },
      },
  computed:
      {
        city_image() {
          const date = this.$moment();
          const season = this.getSeasonByDate(date);
          if (this.$store.getters.ownersAssociation) {
            switch (this.$store.getters.ownersAssociation.city) {
              case 'Mar del Plata':
                if (process.env.VUE_APP_MODE === 'web') {
                  return {
                    // eslint-disable-next-line global-require,import/no-dynamic-require
                    img_normal: require(`@/assets/img/cities/mardelplata_${season}.svg`),
                    // eslint-disable-next-line global-require,import/no-dynamic-require
                    img_2x: require(`@/assets/img/cities/mardelplata_${season}.svg`),
                  };
                  // eslint-disable-next-line no-template-curly-in-string
                } return `~/assets/img/cities/mardelplata_${season}.png`;
              case 'Cordoba':
                if (process.env.VUE_APP_MODE === 'web') {
                  return {

                    // eslint-disable-next-line global-require,import/no-dynamic-require
                    img_normal: require(`@/assets/img/cities/cordoba_${season}.svg`),
                    // eslint-disable-next-line global-require,import/no-dynamic-require
                    img_2x: require(`@/assets/img/cities/cordoba_${season}.svg`),
                  };
                }
                // eslint-disable-next-line no-template-curly-in-string
                return `~/assets/img/cities/cordoba_${season}.png`;
              case 'Rosario':

                if (process.env.VUE_APP_MODE === 'web') {
                  return {
                    // eslint-disable-next-line global-require,import/no-dynamic-require
                    img_normal: require(`@/assets/img/cities/rosario_${season}.svg`),
                    // eslint-disable-next-line global-require,import/no-dynamic-require
                    img_2x: require(`@/assets/img/cities/rosario_${season}.svg`),
                  };
                }
                // eslint-disable-next-line no-template-curly-in-string
                return `~/assets/img/cities/rosario_${season}.png`;
              case 'C.A.B.A.':
              case 'CABA':
              case 'C.A.B.A':

                if (process.env.VUE_APP_MODE === 'web') {
                  return {
                    // eslint-disable-next-line global-require,import/no-dynamic-require
                    img_normal: require(`@/assets/img/cities/caba_${season}.svg`),
                    // eslint-disable-next-line global-require,import/no-dynamic-require
                    img_2x: require(`@/assets/img/cities/caba_${season}.svg`),
                  };
                }
                // eslint-disable-next-line no-template-curly-in-string
                return `~/assets/img/cities/caba_${season}.png`;

              default:
                if (process.env.VUE_APP_MODE === 'web') {
                  return {

                    // eslint-disable-next-line global-require,import/no-dynamic-require
                    img_normal: require(`@/assets/img/cities/generica_${season}.svg`),
                    // eslint-disable-next-line global-require,import/no-dynamic-require
                    img_2x: require(`@/assets/img/cities/generica_${season}.svg`),
                  };
                }
                // eslint-disable-next-line no-template-curly-in-string

                return `~/assets/img/cities/generica_${season}.png`;
            }
          } else {
            console.log('no tiene');
            return '';
          }
        },
        owners_association() {
          return this.$store.getters.ownersAssociation;
        },
      },
};
</script>
