const mutations = {
  ownersAssociation(state, data) {
    state.owners_association = data;
  },
  notifications(state, data) {
    state.notifications = data;
  },
  new_notification(state, data) {
    state.new_notification = data;
  },
  ownersAssociations(state, data) {
    state.owners_associations = data;
  },
  loading(state, data) {
    state.loading = data;
  },
  loadingText(state, data) {
    state.loadingText = data;
  },
  hasMultiplesOwnersAssociations(state, data) {
    state.hasMultiplesOwnersAssociations = data;
  },
  user(state, data) {
    state.user = data;
  },
  token(state, data) {
    state.token = data;
  },
  IdToken(state, data) {
    state.IdToken = data;
  },
  refreshToken(state, data) {
    state.refreshToken = data;
  },
  logged(state, data) {
    state.logged = data;
  },
  newUser(state, data) {
    state.new_user = data;
  },
  temporalPassword(state, data) {
    state.temporalPassword = data;
  },
  email(state, data) {
    state.email = data;
  },
  messages(state, data) {
    state.messages = data;
  },
  administrationFeatures(state, data) {
    state.administrationFeatures = data;
  },
  amenity(state, data) {
    state.amenity = data;
  },
  amenities(state, data) {
    state.amenities = data;
  },
  amenitiesBookings(state, data) {
    state.amenitiesBookings = data;
  },
  booking(state, data) {
    state.booking = data;
  },
  openRequestModal(state, value) {
    state.openRequestModal = value;
  },
  activeConnection(state, value) {
    state.activeConnection = value;
  },
};
export default mutations;
