<template web>
  <div class="page-account-view">
    <header-back title=" VOLVER" url="/cuenta" ></header-back>
    <section class="bar theme-blue" >
      <div class="container">
        <div class="bar-avatar">
          <span><img src="@/assets/img/mi-cuenta.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">
          Editar contraseña
        </h3>
      </div>
    </section>
    <div class="container bar-edit">
      <div class="card border-none mb-4 mt-4 card-type">
        <div class="card-body">
          <h5 class="title">Editar contraseña</h5>
          <form method="POST" action="" @submit="changeUserPassword">

            <div class="form-group cont_pass_home">
              <input
                  v-model="password.old"
                  placeholder="Ingresa contraseña anterior"
                  id="old_password"
                  required="required"
                  :type="passwordTypeOld"
                  class="form-control"
              >
              <div class="btn_eye">
                <img  :src="[passwordIconOld]" @click="hidePasswordOld" class="img_info">
              </div>
            </div>
            <div class="form-group cont_pass_home">
              <input
                  v-validate="'required|min:8|verify_password'"
                  v-model="password.new"
                  name="new_password"
                  id="new_password"
                  ref="new_password"
                  required="required"
                  :type="passwordType"
                  class="form-control"
                  placeholder="Ingresa nueva contraseña"
              >
              <div class="btn_eye">
                <img  :src="[passwordIcon]" @click="hidePassword" class="img_info">
              </div>
            </div>
            <div class="alert alert-danger" v-if="errors.first('new_password')">
              <img src="@/assets/img/error.svg" alt="error" class="img_info"> {{ errors.first('new_password').replace('new_password', '') }}
            </div>
            <div class="form-group cont_pass_home">
              <input
                  v-model="password.confirm"
                  v-validate="'confirmed:new_password'"
                  id="new_password_confirm"
                  name="new_password_confirm"
                  required="required"
                  :type="passwordTypeConfirm"
                  placeholder="Confirmar nueva contraseña"
                  class="form-control"
              >
              <div class="btn_eye">
                <img  :src="[passwordIconConfirm]" @click="hidePasswordConfirm" class="img_info">
              </div>
            </div>
            <div class="alert alert-danger" v-if="errors.first('new_password_confirm')">
              <img src="@/assets/img/error.svg" alt="error" class="img_info">{{ errors.first('new_password_confirm').replace('new_password_confirm', 'Confirmar contraseña nueva') }}
            </div>
            <input type="submit" value="Cambiar contraseña" class="btn btn-rounded btn-primary btn-block" :disabled="errors.items.length > 0"/>
          </form>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'ChangePassword',
  components: { HeaderBack },
  props: ['passwordType', 'passwordIcon', 'passwordTypeConfirm', 'passwordIconConfirm', 'passwordTypeOld', 'passwordIconOld'],
  data() {
    return {
      password:
          {
            old: '',
            new: '',
            confirm: '',
          },
    };
  },
  computed: {},
  methods: {
    hidePassword() {
      this.$emit('onhidePassword');
    },
    hidePasswordConfirm() {
      this.$emit('onhidePasswordConfirm');
    },
    hidePasswordOld() {
      this.$emit('onhidePasswordOld');
    },
    changeUserPassword(e) {
      e.preventDefault();
      this.$emit('onchangeUserPassword', this.password, this.errors);
    },
  },
};
</script>

<style lang='scss'>

</style>
