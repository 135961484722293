import { Directory, Filesystem } from '@capacitor/filesystem';
import axios from 'axios';
import { Dialog } from '@capacitor/dialog';
import { Capacitor } from '@capacitor/core';
import router from '../routes/router';

export default {
    methods: {
        async writeAndPreviewPdf(base64Data, fileName) {
            await Filesystem.mkdir({ path: 'joinup', directory: Directory.Cache }).then(async () => {
                await Filesystem.writeFile({
                    path: `joinup/${fileName.replace(' ', '-')}`,
                    data: Buffer.from(base64Data).toString('base64'),
                    directory: Directory.Cache,
                }).then(async (writedFile) => {
                    this.localuri = writedFile.uri;
                    return writedFile.uri;
                });
            }).catch(async () => {
                await Filesystem.writeFile({
                    path: `joinup/${fileName.replace(' ', '-')}`,
                    data: Buffer.from(base64Data).toString('base64'),
                    directory: Directory.Cache,
                }).then(async (writedFile) => writedFile.uri);
            });
        },
        async hasFilePermisions() {
            if (Capacitor.getPlatform() === 'ios') {
                return Filesystem.checkPermissions()
                    .then(async (result) => {
                        if ((`${result.publicStorage}` === 'prompt') || (`${result.publicStorage}` === 'denied') || (`${result.publicStorage}` === 'prompt-with-rationale')) {
                            return Filesystem.requestPermissions()
                                .then(async (publicStorage) => {
                                    const permissionsRequested = await publicStorage.publicStorage;
                                    console.log('prompt || denied || prompt-with-rationale');
                                    return `${permissionsRequested}` === 'granted';
                                });
                        }
                        return true;
                    });
            }
            return true;
        },
        async downloadFallback(doc) {

            await this.hasFilePermisions().then(() => {
                axios.get(doc.url, {
                    responseType: 'arraybuffer',
                }).then(async (success) => {
                    this.hasFilePermisions().then(async (resultData) => {
                      //  if (resultData) {
                            const arrayUrl = doc.url.split('/');
                            const fileName = arrayUrl[arrayUrl.length - 1];
                            const pdfData = await success.data;
                            const localuri = await this.writeAndPreviewPdf(pdfData, fileName.replace(' ', '-'));
                            await router.push({
                                name: 'pdf-view',
                                params: { url: doc.url, localuri },
                            });
                      //  } 
                       /* else {
                            await Dialog.alert({
                                title: 'Información',
                                message: 'Denegaste los permisos de almacenamiento y esta operación no puede ser realizada',
                            });
                        } */
                    });
                });
            });
        },
    },
};
