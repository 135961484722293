<template>
 <edit-profile :user="user" v-if="user" @onUpdateMe="updateMe"></edit-profile>
</template>
<script>

import EditProfile from '@/components/Account/EditProfile/EditProfile';

export default {
  components:
      {
        EditProfile,
      },
  data() {
    return {
      user: null,
    };
  },
  methods:
      {
        updateMe(user) {
          delete user.email;
          this.updateProfile(user)
            .then(() => {
              if (process.env.VUE_APP_MODE === 'web') {
                this.$router.push(
                  {
                    name: 'cuenta',
                    params:
                          {
                            success_message: 'Tu perfil se editó satisfactoriamente',
                          },
                  },
                );
              } else {
                this.$navigator.navigate('/account');
              }
            })
            .catch(() => {
              if (process.env.VUE_APP_MODE === 'web') {
                this.$router.push(
                  {
                    name: 'cuenta',
                    params:
                          {
                            error_message: 'Ocurrió un error al editar tu perfil, intenta más tarde',
                          },
                  },
                );
              }
            });
        },
      },
  async created() {
    this.user = await this.getProfile();
    console.log('user ', this.user);
  },
};
</script>
