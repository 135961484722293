<template>
  <div class="page-owners-associations-documents">
    <div class="container">
      <p class="section-header">
        <router-link
            :to="{ name: 'listado-amenities'}"
            class="card-link btn btn-rounded btn-primary"
            style="display: inline-block;">
          Amenities
        </router-link>
        <router-link
            :to="{ name: 'listado-reservas'}"
            class="card-link btn btn-rounded btn-outline-primary value"
            style="display: inline-block;">
          Reservas
        </router-link>
      </p>
    </div>
    <div class="documents-index">
      <div class="container">

        <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />

        <div v-if="!isLoading && !amenities.length" class="empty-state empty-state-amenities">
          <div class="wrapper">
            <h4>¡Aún no hay amenities!</h4>
            <p>No se han encontrado amenities disponibles.</p>
            <img alt="" class="image-empty" src="@/assets/img/empty-states/no-hay-amenities.png">
          </div>
        </div>

        <a
            v-for="amenity in amenities"
            :key="amenity.id"
            class="news-item news-item-with-image "
            style="padding: 1rem 1rem !important;"
            @click="redirect(amenity)"
        >
          <div class="news-main" style="align-items: center">
            <span class="document-icon-container circled shadow"><img class="document-icon" style="width: 100%" alt=""  :src="getTypeImage(amenity.type.name)"/></span>

            <div class="news-data" style="flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 85%;">

              <h3 class="news-title" style="font-size: 0.7rem; font-weight: 700">{{amenity.name | truncate(28)}}</h3>
              <p class="news-meta">
                <span class="news-date">{{ amenity.type.name }} &mdash; <span v-if="amenity.is_available">Habilitado</span>
                <span v-else>No habilitado</span></span>
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmenitiesListCapacitor',
  props: ['amenities', 'bookings', 'isLoading', 'getTypeImage'],
  data() {
    return {};
  },
  computed: {},
  methods: {
    redirect(amenity) {
      this.$router.push({
 name: 'card-amenity',
params: {
 amenity_id: amenity.id,
},
});
    },
  },
};
</script>
