<template web>
  <div>
    <div class="card card-expenses mb-4">
      <div class="card-body text-center">
        <h2>ERROR 404</h2>
        <p>El recurso solicitado no pudo ser encontrado</p>
      </div>
    </div>
  </div>
</template>

<script>
export default { name: 'not-found' };
</script>
