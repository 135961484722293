<template>
  <div class="page-account-view amenity-page-wrapper">
    <header-back message="true" title=" VOLVER"></header-back>
    <section v-if="amenity" class="bar theme-blue">
      <div class="container">
        <div class="bar-img" v-if="!is_capacitor">
          <img :src="getTypeImage(amenity.type.name)" />
        </div>
        <h3 :class="!is_capacitor ? 'bar-firm-name' : 'bar-firm-name mt-3'">
          Solicitud de reserva
        </h3>
      </div>
    </section>
    <div v-if="!formResponse.success" class="container bar-details velmld-parent">
      <vue-element-loading :active="isLoading" color="#CE4169" spinner="spinner" />
      <div v-if="amenity && !is_capacitor" class="card border-box">
        <div class="amenity-description-card">
          <section class="description-image">
            <img :src="amenity.amenity_data.image_url" class="img-card-left" />
          </section>
          <h5 class="description-name">{{ amenity.name }}</h5>
          <section class="description-title">Descripción</section>
          <p class="description-text">{{ amenity.description | excerpt | truncate(120) }}</p>
        </div>
      </div>
      <div v-if="amenity" class="card border-box">
        <div class="amenity-booking-card">

          <!--Begin Alerts -->
          <div v-if="!isLoading && !amenity.is_available" class="alert alert-danger">
            Este amenity no esta disponible
          </div>
          <div v-if="!isLoading && !amenity.is_bookable" class="alert alert-danger">
            Este amenity no es reservable
          </div>
          <!--End Alerts-->
          <!--Begin Form-->
          <form v-if="amenity.is_bookable && amenity.is_available" action="" class="container-fluid" method="POST">

            <div class="form-group">
              <h6 class="amenity-booking-subtitle">Unidades funcionales</h6>

              <v-select v-if="!is_capacitor" v-model="selectedFunctionalUnit" :options="amenity.functional_units"
                :getOptionLabel="option => option.location" :value="selectedFunctionalUnit"
                class="form-control btn btn-light" placeholder="Selecciona una unidad funcional">
                <template #no-options>
                  No hay unidades disponibles.
                </template>
                <template slot="option" slot-scope="option">
                  <div :key="option.id" class="amenity-booking-select-picker-element"
                    @mouseenter="setElementHoverState(option)" @mouseleave="resetElementHoverState()">
                    <div>
                      {{ option.location }}
                    </div>
                    <transition name="fade">
                      <div v-if="hoveredElement === option.id && option.can_book_amenity == false"
                        :style="hoveredElementStatusStyle">
                        No disponible
                      </div>
                    </transition>
                  </div>
                </template>
              </v-select>
              <div v-else>
                <h2 class="form-control btn btn-light "
                  style="display: flex; justify-content: space-between; border-color: black" @click="openFu">
                  <span class="font-weight-bold text-uppercase" style="color: #777">{{ selectedFunctionalUnit ?
                    selectedFunctionalUnit.location : 'Selecciona una unidad funcional'}}</span>
                  <span class="dropdown-toggle ml-2"></span>
                </h2>
                <vue-bottom-sheet ref="BottomSheetFu">
                  <div v-for="option in amenity.functional_units" :key="option.id" @click="selectFu(option)">
                    <hr>
                    <span class="ml-3" style="color: #CE4169; font-weight: 600">{{ option.location }}</span>

                  </div>
                  <br>
                </vue-bottom-sheet>
              </div>
            </div>
            <div v-if="selectedFunctionalUnit">
              <div v-if="availableMonthsOptions" class="form-group">
                <h6 class="amenity-booking-subtitle">Mes</h6>
                <v-select v-if="!is_capacitor" v-model="selectedMonth" :options="availableMonthsOptions"
                  :selectable="option => option.available" :value="selectedMonth" class="form-control btn btn-light"
                  placeholder="Selecciona un mes">
                  <template #no-options>
                    No hay meses disponibles.
                  </template>
                  <template slot="option" slot-scope="option">
                    <div :key="option.id" class="amenity-booking-select-picker-element"
                      @mouseenter="setElementHoverState(option)" @mouseleave="resetElementHoverState()">
                      <div>
                        {{ option.label }}
                      </div>
                      <transition name="fade">
                        <div v-if="hoveredElement === option.id" :style="hoveredElementStatusStyle">
                          {{ option.reason }}
                        </div>
                      </transition>
                    </div>
                  </template>
                </v-select>
                <h2 v-else class="form-control btn btn-light "
                  style="display: flex; justify-content: space-between; border-color: black" @click="openMonth">
                  <span class="font-weight-bold text-uppercase" style="color: #777">{{ selectedMonth ?
                    selectedMonth.label :
                    'Selecciona un mes' }}</span>
                  <span class="dropdown-toggle ml-2"></span>
                </h2>
                <vue-bottom-sheet ref="BottomSheetMonth">
                  <div v-for="option in availableMonthsOptions" :key="option.id" @click="selectMonth(option)">
                    <hr>
                    <span class="ml-3" style="color: #CE4169; font-weight: 600">{{ option.label }}</span>
                    <br>
                    <span class="ml-3" :style="'color: ' + option.color + '; font-weight: 600'">{{ option.reason
                      }}</span>
                  </div>
                  <br>
                </vue-bottom-sheet>
              </div>

              <div v-if="selectedMonth" class="form-group">
                <br>
                <h6 class="amenity-booking-subtitle">Fechas disponibles</h6>
                <datepicker @opened="abierto" ref="datePicker" v-if="disabledDates" v-model="selectedDate"
                  :day-cell-content="setDayStatusColor" :disabled-dates="disabledDates" :format="customFormatter"
                  :full-month-name=true :language=locale calendar-class="prueba" :monday-first=true
                  :open-date=minSelectableDate
                  :input-class="!is_capacitor ? 'btn btn-light amenity-booking-day-input' : 'btn btn-light amenity-booking-day-input-capacitor'"
                  maximum-view="day" placeholder="Selecciona un día" wrapper-class="amenity-booking-day-selector">
                  <div slot="beforeCalendarHeader" class="calender-header ml-2">
                    <div class="align-items-center" style="display: flex" v-for="leyend in dayStyleLegends"
                      :key="leyend.id">
                      <div class="cuadradito" :style="getLegendPillStyle(leyend)"></div>
                      <marquee-text id="marquee-bookings" v-if="leyend.color === '#FFC700'"><span class="mr-2">{{
                          leyend.leyend
                          }}</span></marquee-text>
                      <span v-else>
                        {{ leyend.leyend }}
                      </span>

                    </div>
                  </div>
                </datepicker>

              </div>

              <div v-if="selectedDate" class="form-group">
                <h6 class="amenity-booking-subtitle">Turnos Disponibles</h6>
                <v-select v-if="!is_capacitor" v-model="selectedBookingSlot" :options="availableBookingSlots"
                  :selectable="option => option.selectable" :value="selectedBookingSlot"
                  class="form-control btn btn-light" placeholder="Selecciona un turno">
                  <template slot="option" slot-scope="option">
                    <div :key="option.slot_id" class="amenity-booking-select-picker-element"
                      @mouseenter="setElementHoverState(option)" @mouseleave="resetElementHoverState()">
                      <div>
                        {{ option.label }}
                      </div>
                      <transition disabled="true"
                        v-if="hoveredElement === option.slot_id && option.selectable === false && option.available === 0"
                        name="fade">
                        <div :style="hoveredElementStatusStyle">
                          No quedan lugares disponibles
                        </div>
                      </transition>
                      <transition disabled="true"
                        v-if='hoveredElement === option.slot_id && option.selectable === false && option.available !== 0'>
                        <div :style="hoveredElementStatusStyle">
                          {{ option.reason }}
                        </div>
                      </transition>
                      <transition
                        v-if="hoveredElement === option.slot_id && option.selectable === true && option.available > (1.5 * amenity.amenity_conditions.max_uf_booking_invites) && option.available !== 1">
                        <div :style="hoveredElementStatusStyle">
                          Quedan {{ option.available }} lugares disponibles
                        </div>
                      </transition>
                      <transition
                        v-if="hoveredElement === option.slot_id && option.selectable === true && option.available <= (1.5 * amenity.amenity_conditions.max_uf_booking_invites) && option.available !== 1">
                        <div :style="hoveredElementStatusStyle">
                          Ultimos {{ option.available }} lugares disponibles
                        </div>
                      </transition>
                    </div>
                  </template>
                </v-select>
                <h2 v-else class="form-control btn btn-light "
                  style="display: flex; justify-content: space-between; border-color: black" @click="openTurn">
                  <span class="font-weight-bold text-uppercase" style="color: #777">{{ selectedBookingSlot ?
                    selectedBookingSlot.label : 'Selecciona un turno' }}</span>
                  <span class="dropdown-toggle ml-2"></span>
                </h2>
                <vue-bottom-sheet ref="BottomSheetTurn">
                  <div v-for="option in availableBookingSlots" :key="option.id" @click="selectTurn(option)">
                    <hr>
                    <span class="ml-3" style="color: #CE4169; font-weight: 600">{{ option.label }}</span>
                    <br>
                    <span v-if="option.selectable === false && option.available === 0" class="ml-3"
                      :style="'color: ' + option.color + '; font-weight: 600'">No quedan lugares disponibles</span>
                    <span v-if="option.selectable === false && option.available !== 0" class="ml-3"
                      :style="'color: ' + option.color + '; font-weight: 600'">{{ option.reason }}</span>
                    <span
                      v-if="option.selectable === true && option.available > (1.5 * amenity.amenity_conditions.max_uf_booking_invites)"
                      class="ml-3" :style="'color: ' + option.color + '; font-weight: 600'">Quedan {{ option.available
                      }}
                      lugares
                      disponibles</span>
                    <span
                      v-if="option.selectable === true && option.available <= (1.5 * amenity.amenity_conditions.max_uf_booking_invites)"
                      class="ml-3" :style="'color: ' + option.color + '; font-weight: 600'">Últimos {{ option.available
                      }}
                      lugares
                      disponibles</span>
                  </div>
                  <br>
                </vue-bottom-sheet>
              </div>

              <div v-if="selectedBookingSlot" class="form-group">
                <div v-if="selectedBookingSlot">
                  <h6 class="amenity-booking-subtitle">Cantidad de Acompañantes</h6>
                  <p class="description-text" v-if="amenity.amenity_conditions.max_uf_booking_invites > 0"
                    style="padding-left: 0.5em">Hasta {{ amenity.amenity_conditions.max_uf_booking_invites }}
                    acompañantes</p>
                  <div v-if="!is_capacitor">
                    <input v-if="amenity.amenity_conditions.max_uf_booking_invites > 0" :min="0"
                      :max="maximumAvailableInvites" v-model="selectedInvites" type="number"
                      class="amenity-booking-invites-input">
                    <input v-else :min="0" :max="maximumAvailableInvites + Number('500')" v-model="selectedInvites"
                      type="number" class="amenity-booking-invites-input">
                  </div>
                  <div v-else>
                    <h2 v-if="is_capacitor" class="form-control btn btn-light "
                      style="display: flex; justify-content: space-between; border-color: black" @click="openInvited">
                      <span class="font-weight-bold text-uppercase" style="color: #777">{{ selectedInvites }}</span>
                      <span class="dropdown-toggle ml-2"></span>
                    </h2>
                    <vue-bottom-sheet ref="BottomSheetInvited">
                      <div v-for="option in amenity.amenity_conditions.max_uf_booking_invites + 1" :key="option"
                        @click="selectInvited(option - 1)">
                        <hr>
                        <span class="ml-3" style="color: #CE4169; font-weight: 600">{{ option - 1 }}</span>
                      </div>
                      <br>
                    </vue-bottom-sheet>
                  </div>
                </div>
                <div class="form-group">
                <h6 class="amenity-booking-subtitle" style="margin-top:10px">Comentarios</h6>
                <textarea rows="4" cols="50" placeholder="Escribe tu comentario" maxlength="250"
                  class="field__input sendRequestText a-field__input" v-model="book_notes"
                  style="text-align: left; border:#dbdbdb 3px solid;padding-left: 0.5em;">
                </textarea>
              </div>
                <div class="form-check amenity-booking-form-checkbox">
                  <input id="requestCheck" v-model="TOS" class="form-check-input" required="required" type="checkbox"
                    value="">
                  <label class="form-check-label text-muted" for="requestCheck">
                    He leído y estoy de acuerdo con los términos de uso.
                  </label>
                </div>
                <div
                  :class="!is_capacitor ? 'form-group amenity-booking-actions' : 'form-group amenity-booking-actions-capacitor'">
                  <input :disabled="!TOS"
                    :class="!is_capacitor ? 'btn btn-rounded btn-primary value amenity-booking-submit-btn' : 'btn btn-rounded btn-primary value mb-3'"
                    value="Reservar" @click="doAmenityBooking" />
                  <router-link :to="{ name: 'detalle-amenity', params: { amenity_id: amenity.id } }"
                    class="btn btn-rounded btn-outline-primary detalle">
                    Ver Detalles
                  </router-link>
                </div>
              </div>
              <div class="alert alert-danger" v-if="errorMessage">
                <img src="@/assets/img/error.svg" alt="error" class="img_info">
                {{ errorMessage }}
              </div>
            </div>
          </form>
          <!--End Form-->
        </div>
      </div>
    </div>
    <div v-if="formResponse.success" class="container bar-details velmld-parent">
      <vue-element-loading :active="isLoading" color="#CE4169" spinner="spinner" />
      <div class="container bar-details">
        <div v-if="amenity" class="card border-box">
          <img :src="amenity.amenity_data.image_url" class="card-img-top">
          <div class="card-body">
            <div class="row">
              <img alt="" class="icon" src="@/assets/img/icons/check_circle_outline.svg"
                style="width: 64px;height: 64px;">
            </div>
            <div class="card-text">
              <p style="text-align: center">Solicitud de reserva para el</p>
            </div>
            <div class="card-text">
              <h5 style="text-align: center">
                {{ $moment(selectedDate).format('LL') }} - Turno: {{ selectedBookingSlot.slot_name }}
              </h5>
              <h5 style="text-align: center">
                {{ selectedFunctionalUnit.location }}
              </h5>
              <!-- <h6 style="text-align: center">
                Asistentes: {{selectedInvites}} personas
              </h6> -->
            </div>
            <div class="card-text">
              <p style="text-align: center"> Ha sido enviada con éxito</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { es } from 'vuejs-datepicker/dist/locale';
import { Capacitor } from '@capacitor/core';
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'BookAmenity',
  components: { HeaderBack },
  props: ['amenity', 'getTypeImage', 'isLoading', 'availableMonthsOptions'],
  data() {
    return {
      selectedMonth: null,
      selectedFu: null,
      selectedFunctionalUnit: null,
      hoveredElement: null,
      hoveredElementColor: null,
      disabledDates: null,
      selectedDate: null,
      functional_units: null,
      formResponse: {
        success: false,
        error: false,
      },
      availableDays: [],

      locale: es,
      TOS: false,
      disabledDays: [],
      minSelectableDate: null,
      maxSelectableDate: null,
      dayStyleLegends: [],
      selectedBookingSlot: null,
      availableBookingSlots: [],
      selectedInvites: 0,
      book_notes: null,
      errorMessage: null,
    };
  },
  computed: {
    hoveredElementStatusStyle() {
      return {
        color: this.hoveredElementColor,
      };
    },
    maximumAvailableInvites() {
      return this.amenity && this._.min([
        this.amenity.amenity_conditions.max_uf_booking_invites,
        this.selectedBookingSlot.available,
      ]);
    },
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
    owners_association() {
      return this.$store.getters.ownersAssociation;
    },
  },
  watch:
  {
    async selectedMonth(monthObj) {
      if (monthObj !== null) {
        this.$emit('loading', true);
      }
      this.selectedDate = null;
      const month = this.$moment(monthObj.complete_month_date).format('Y-MM-DD');
      const availableDates = await this.getDaysAvailable(this.amenity.id, month);
      const parent = this;
      this.availableDays = availableDates.map(
        (day, id) => {
          const dayStyleLegend = this._.find(parent.dayStyleLegends, { leyend: day.reason });

          if (!dayStyleLegend) {
            parent.dayStyleLegends.push({ leyend: day.reason, color: day.color });
          }
          parent.loadDisabledDates(day);
          const newKeysObj = {
            id,
          };
          return this._.extend(day, newKeysObj);
        },
      );

      this.minSelectableDate = this.$moment(this._.first(this.availableDays).complete_day_date).toDate();
      this.maxSelectableDate = this.$moment(this._.last(this.availableDays).complete_day_date).toDate();
      this.disabledDates = {
        dates: this.disabledDays,
        to: this.minSelectableDate,
        from: this.maxSelectableDate,
      };
      this.$emit('loading', false);

    },
    async selectedDate(date) {
      if (date !== null) {
        this.$emit('loading', true);
      }
      this.selectedBookingSlot = null;
      const day = this.$moment(date).format('Y-MM-DD');
      const availableSlots = await this.getBookingSlotsAvailable(this.amenity.id, day);
      this.availableBookingSlots = availableSlots.map(
        (slot) => {
          const newKeysObj = {
            id: slot.slot_id,
            label: slot.slot_name,
          };
          return this._.extend(slot, newKeysObj);
        },
      );
      this.$emit('loading', false);
    },
    async errorMessage() {
      if (this.formResponse.error === true) {
        this.$swal({
          type: 'error',
          title: 'Ocurrió un error',
          text: this.errorMessage,
          willClose: this.$router.push({
            path: `/consorcios/${this.owners_association.id}/amenities/`,
          }),
        });
      }
    },
  },
  methods: {
    abierto() {
      console.log('ref:', this.$refs.datePicker);
    },
    openMonth() {
      this.$refs.BottomSheetMonth.open();
    },
    openTurn() {
      this.$refs.BottomSheetTurn.open();
    },
    openInvited() {
      this.$refs.BottomSheetInvited.open();
    },

    openFu() {
      this.$refs.BottomSheetFu.open();
    },

    close() {
      if (this.$refs.BottomSheetMonth) this.$refs.BottomSheetMonth.close();

      if (this.$refs.BottomSheetTurn) this.$refs.BottomSheetTurn.close();
      if (this.$refs.BottomSheetInvited) this.$refs.BottomSheetInvited.close();
      if (this.$refs.BottomSheetFu) this.$refs.BottomSheetFu.close();
    },
    selectMonth(month) {
      console.log(`a: ${JSON.stringify(month)}`);
      if (month.available != false) {
        this.selectedMonth = month;
        this.close();
      }
    },
    selectFu(fu) {

      this.selectedFunctionalUnit = fu;
      this.close();

    },
    selectTurn(turno) {
      if (turno.selectable != false) {

        this.selectedBookingSlot = turno;

        this.close();
      }
    },
    selectInvited(invitados) {
      this.selectedInvites = invitados;
      this.close();
    },
    setDayStatusColor(dateObj) {
      const momentDate = this.$moment(this.selectedMonth.complete_month_date);
      const dayObj = this._.find(this.availableDays, this._.matchesProperty('complete_day_date', momentDate.date(dateObj.date).format('Y-MM-DD')));
      return `<div class="amenity-booking-day-element" style="color:${dayObj.color}">${dateObj.date}</div>`;
    },
    customFormatter(date) {
      return this.$moment(date).format('DD/MM/Y');
    },
    getLegendPillStyle(leyend) {
      return `background-color:${leyend.color};color:#fff;`;
    },
    setElementHoverState(element) {
      this.hoveredElement = element.id;
      this.hoveredElementColor = element.color;
    },
    loadDisabledDates(day) {
      if (day.available === false) {
        this.disabledDays.push(this.$moment(day.complete_day_date).toDate());
      }
    },
    resetElementHoverState() {
      this.hoveredElement = null;
      this.hoveredElementColor = null;
    },
    async doAmenityBooking() {
      this.$emit('loading', true);
      const bookingDate = this.$moment(this.selectedDate).format('Y-MM-DD');
      // eslint-disable-next-line radix
      const totalInvites = Number(this.selectedInvites);
      const constantInvite = Number('1');
      const book_data = {
        book_start: bookingDate,
        book_end: bookingDate,
        booking_occupancy: totalInvites + constantInvite,
        slot_id: this.selectedBookingSlot.slot_id,
        is_tos_accepted: this.TOS,
        functional_unit_id: this.selectedFunctionalUnit.id,
        book_notes: this.book_notes,
      };

      try {
        await this.bookAmenity(this.$route.params.amenity_id, book_data);
        this.formResponse.success = true;
        this.errorMessage = null;
        this.$emit('loading', false);
      } catch (err) {
        this.formResponse.error = true;
        this.$emit('loading', false);
        if (err.response.status === 400) {
          this.errorMessage = err.response.data.message;
        }
      }
    },
  },
  filters:
  {
    excerpt(text) {
      return text.length < 250 ? text : `${text.substr(0, 250)}...`;
    },
    capitalize(text) {
      return text ? text.toUpperCase() : '';
    },
  },

};
</script>
<style lang='scss'>
.card {
  border: none;
  margin: 1em auto;
}

/* Description Card */
.amenity-description-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-column-gap: 1em;
  padding: 1em 0px;
  padding-right: 1em !important;
  max-height: 10rem;
  justify-content: center;
  align-items: center;
}

.description-image {
  grid-column: 1/1;
  grid-row: 1/7;
}

.img-card-left {
  object-fit: cover;
  width: 100%;
  height: 10rem;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.description-name {
  grid-row: 1/1;
  font-family: 'Roboto', sans-serif;
  font-size: 1.25rem;
  color: #00263A;
}

.description-title {
  grid-row: 2/2;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  color: #00263A;
}

.description-text {
  grid-column: 2/5;
  grid-row: 3/7;
  object-fit: cover;
  color: #777;
  font-size: 0.875rem;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.1px;
}

/* Booking Card */
.amenity-booking-card {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-flow: row;
  grid-row-gap: 1em;
  padding: 0px 1em;
  object-fit: contain;
  justify-content: center;
  align-items: center;
}

.amenity-booking-subtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: #00263A;
  padding-left: 0.5em;
}

.amenity-booking-form {
  grid-row: 1/8;
}

.amenity-booking-form-checkbox,
.amenity-booking-actions,
.amenity-booking-actions-capacitor {
  margin-top: 1rem;
}

.amenity-booking-actions {
  display: flex;
  justify-content: space-between;
}

.amenity-booking-actions-capacitor {
  display: flex;
  flex-direction: column;
}

.amenity-booking-submit-btn {
  height: 39px;
}

/* Month Selector */
.amenity-booking-month-selector,
.amenity-booking-date-selector,
.amenity-booking-slot-selector {
  width: -webkit-fill-available;
}

.vs__dropdown-toggle,
.vs__dropdown-menu,
.vs__dropdown-option {
  border: none !important;
  color: #CE4169 !important;
  text-transform: uppercase;
}

.vs__dropdown-option--disabled {
  background: inherit;
  color: #BFBDBE;
}

.vs__dropdown-option--highlight {
  background: inherit;
}

.vs__search,
.vs__selected,
.input[type="text"] {
  color: #777;
  text-transform: uppercase;
}

.amenity-booking-select-picker-element {
  display: flex;
  justify-content: space-between;
  border-bottom-color: #000000;
  border-bottom-style: solid;
  border-bottom: 1px;
}

/* Day Selector */
.amenity-booking-day-picker {
  grid-row: 2/2;
}

.amenity-booking-slot-selector {
  border: none;
  color: #00263A
}

.amenity-booking-day-input {
  border: none;
  margin-bottom: 1vh !important;
  text-align: left;
  text-transform: uppercase;
  padding-left: 1.5rem;
  display: inline-table;
  width: 100%;
  font-size: 0.875rem !important;
  font-family: 'Roboto-Medium', sans-serif !important;
  border-radius: 18px;
  letter-spacing: 1.25px !important;
  height: 45px;
}

.amenity-booking-day-input-capacitor {
  margin-bottom: 1vh !important;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  font-size: 0.875rem !important;
  font-family: 'Roboto-Medium', sans-serif !important;
  border-radius: 18px;
  letter-spacing: 1.25px !important;
  display: flex;
  justify-content: space-between;
  border-color: black
}

.amenity-booking-day-element,
.amenity-booking-date-leyend-pill {
  font-size: 0.875rem !important;
  font-family: 'Roboto-Medium', sans-serif !important;
  border-radius: 18px;
}

.number-input__input,
.amenity-booking-invites-input {
  border: none !important;
  margin-bottom: 1vh !important;
  background-color: #f8f9fa !important;
  text-align: left;
  text-transform: uppercase;
  padding-left: 1em !important;
  display: inline-table;
  width: 100%;
  font-size: 0.875rem !important;
  font-family: 'Roboto-Medium', sans-serif !important;
  border-radius: 18px;
  letter-spacing: 1.25px !important;
  height: 45px;
}

.vdp-datepicker__calendar {
  top: -10rem;
  position: relative !important;
}

@media (max-width: 500px) {
  .amenity-booking-submit-btn {
    width: 50% !important;
  }

  .vs__dropdown-menu {
    padding-bottom: 5rem;
    margin-bottom: 5rem;
  }
}

.cuadradito {
  height: 0.7rem;
  width: 0.7rem;
  padding-left: 0.7rem;
  margin-right: 0.5rem;
}

#marquee-bookings .marquee-text-wrap {
  width: 80%;
  margin-left: 0.5rem;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: #CE4169;
}

.amenity-booking-form-checkbox .form-check-input:checked~.form-check-label::before {
  background-color: #CE4169 !important;
}
</style>
