<template web>
  <div class="page-account-view page-owners-associations-payment-notification">
    <header-back v-if="is_capacitor" title=" VOLVER" />
    <section class="bar theme-blue" >
      <div class="container">
        <div class="bar-avatar">
          <span><img src="@/assets/img/notificacion_de_pago_details.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">
          Realizar un pago
        </h3>
        <br>
      </div>
    </section>
    <div class="container bar-payment">
      <!--        <vue-element-loading :active="loading" spinner="spinner" color="#CE4169" />-->
      <div class="card border-none shadow mb-4 mt-4">
        <div class="card-body">
          <!--            <h5 class="title">Aquí podrás visualizar el monto a pagar y notificar si lo has realizado por depósito o transferencia.</h5>-->
          <p class="sub_title payment-space-p">Estos son los montos vigentes a pagar en este período</p>
          <!--            <table class="table table-payment-notification" style="background:aliceblue;">-->
          <!--              <tr>-->
          <!--                <th  class="functional-unit row-table-payment">Unidad funcional</th>-->
          <!--                <th  class="amount row-table-payment" style="text-align: right;padding-right: 2rem; ">Monto</th>-->
          <!--              </tr>-->
          <!--              <tr v-for="fu in owners_association.functional_units" :key="fu.id">-->
          <!--                <td class="row-payment">  {{fu.location}}</td>-->
          <!--                <td class="row-payment" style="float:right;">-->
          <!--                  <div class="field__input a-field__input">{{inputComponent.amountsShow[fu.id] | currency}}</div>-->
          <!--                </td>-->
          <!--              </tr>-->
          <!--              <tr>-->
          <!--                <td scope="col" class="total">Total</td>-->
          <!--                <td scope="col" class="total_amount" style="text-align: right;">{{ total_amountShow | currency}}</td>-->
          <!--              </tr>-->
          <!--            </table>-->
          <!--            <br>-->
          <table class="table table-payment-notification" style="background:#eef0f2;">
            <tr>
              <th  class="functional-unit row-table-payment">Unidad funcional</th>
              <th  class="amount row-table-payment" style="text-align: right;padding-right: 2rem; ">Monto</th>
            </tr>
            <tr v-for="fu in owners_association.functional_units" :key="fu.id">
              <td class="row-payment"> <br> {{fu.location}}</td>
              <td class="row-payment" style="float:right;">
                <label class="field a-field a-field_a1" >
                  <money  class="field__input a-field__input"
                          @input="handleAmount"
                          v-model="inputComponent.amounts[fu.id]"
                          v-bind="money"
                          style="margin-bottom: 0"
                  />
                </label>
              </td>
            </tr>
            <tr>
              <td scope="col" class="total">Total a pagar</td>
              <td scope="col" class="total_amount" style="text-align: right;">{{ total_amount | currency}}</td>
            </tr>
          </table>
          <div class="actions_payments">
            <button :disabled="loading" class="btn btn-primary btn-rounded">
              <div v-if="loading" class="spinner-border spinner-border-sm ma-auto" role="status">
              </div>
              <span v-else @click="sendPay">Pagar con Mercado Pago</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import { Capacitor } from '@capacitor/core';
  import HeaderBack from '@/components/Layouts/HeaderBack';

  export default {
    name: 'Pay',
    components: { HeaderBack },
    props: ['owners_association', 'input', 'total_amount', 'loading', 'total_amountShow'],
    data() {
      return {
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '$',
          precision: 2,
        },
        inputComponent:
                {
                  payment_date: this.input.payment_date,
                  amounts: this.input.amounts,
                  amountsShow: this.input.amountsShow,
                },
      };
    },
    computed: {
      is_capacitor() {
        if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {
          return true;
        }
        return false;
      },
    },
    methods: {
      handleAmount() {
        return this.$emit('onhandleAmount', this.inputComponent);
      },
      sendPay() {
        return this.$emit('onPay', this.inputComponent);
      },
    },
  };
</script>

<style lang='scss'>

</style>
