<template>
  <transition name="fade" appear mode="out-in">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header">
            <slot name="header">
              default header
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              default footer
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DefaultModal',
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang='scss'>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 500px) {
  .modal-container {
    width: 37%;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }
}
@media (max-width: 500px) {
  .modal-container {
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }
}

.modal-header {
  margin-top: 0;
  padding: 0;
  color: #fff;
  background-color: #002234;
  justify-content: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}
@media screen and (min-height:800px){
  .modal-body {
    margin: 20px 0;
    height: 30rem;
    overflow-y: auto;
  }
}
@media screen and (min-height:600px) and (max-height:800px){
  .modal-body {
    margin: 20px 0;
    height: 20rem;
    overflow-y: auto;
  }
}

@media screen and (max-height:600px){
  .modal-body {
    margin: 20px 0;
    height: 10rem;
    overflow-y: auto;
  }
}

@media screen and (max-width: 500px){
  .modal-body {
    margin: 20px 0;
    height: 13rem;
    overflow-y: auto;
  }
}

.modal-input {
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.cuerpo {
  text-align: justify;
  text-justify: inter-word;
}
</style>
