<head></head>
<template>
  <div v-if="owners_association" class="page-owners-associations-settlements">
    <div v-if="is_capacitor" class="container mt-2">
      <h2 class="section-header max_header" @click="open" >
        <span class="font-weight-bold text-uppercase" style="color: #CE4169">{{period.title}}<span class="dropdown-toggle ml-2"></span></span>
      </h2>
    </div>
    <dropdown-selector
        v-else
        @selected="changePeriod"
        :options="periods"
        :default="period"
    /> 
    <vue-bottom-sheet ref="myBottomSheet">
      <div v-for="period in periods" :key="period.id" @click="changePeriod(period)">
        <hr>
        <span class="ml-3">{{period.title}}</span>
      </div>
      <br>
    </vue-bottom-sheet>
    <Settlements
        v-if="period_info"
        :is-downloading-period-documents="isDownloadingPeriodDocuments"
        :owners_association="owners_association"
        :period_info="period_info"
        :doc_generator_text="doc_generator_text"
        :settlements="settlements"
        :period="period"
        :isLoadingSettlements="isLoadingSettlements"
        @mergePeriodDocuments="mergePeriodDocuments"
        @downloadSelected="downloadSelected"
    ></Settlements>
  </div>
</template>
<script>

import { Capacitor } from '@capacitor/core';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
import { Directory, Filesystem } from '@capacitor/filesystem';
import axios from 'axios';
import { Dialog } from '@capacitor/dialog';
import OwnersAssociationHeader from '@/components/Layouts/OwnersAssociationHeader';
import DropdownSelector from '@/components/Layouts/DropdownSelector';
import Settlements from '@/components/Settlements/Settlements';

export default {
  components:
      {
        OwnersAssociationHeader,
        DropdownSelector,
        Settlements,
        VueBottomSheet,

      },
  data() {
    return {
      pickerItems: [],
      settlements: null,
      doc_generator_text: 'Descargar seleccionadas',
      period: '',
      isDownloadingPeriodDocuments: false,
      isLoadingSettlements: false,
      period_documents: [],
      period_info: null,
    };
  },
  methods:
      {
        open() {
          this.$refs.myBottomSheet.open();
        },
        close() {
          this.$refs.myBottomSheet.close();
        },
        async checkCapacitor() {
          if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {
            return true;
          }
          return false;
        },
        async writeAndPreviewPdf(base64Data, fileName) {
          await Filesystem.mkdir({ path: 'joinup', directory: Directory.Cache }).then(async (createdDirectory) => {
            await Filesystem.writeFile({
              path: `joinup/${fileName.replace(' ', '-')}`,
              data: Buffer.from(base64Data).toString('base64'),
              directory: Directory.Cache,
            }).then(async (writedFile) => {
              this.localuri = writedFile.uri;

              console.log(`local uri  ${writedFile.uri}`);
            });
          }).catch(async (error) => {
            await Filesystem.writeFile({
              path: `joinup/${fileName.replace(' ', '-')}`,
              data: Buffer.from(base64Data).toString('base64'),
              directory: Directory.Cache,
            }).then(async (writedFile) => {
              this.localuri = writedFile.uri;

              console.log(`local uri  ${writedFile.uri}`);
            });
          });
        },
        async hasFilePermisions() {
          if (Capacitor.getPlatform() === 'ios') {
            return Filesystem.checkPermissions()
                    .then(async (result) => {
                      if ((`${result.publicStorage}` === 'prompt') || (`${result.publicStorage}` === 'denied') || (`${result.publicStorage}` === 'prompt-with-rationale')) {
                        return Filesystem.requestPermissions()
                                .then(async (publicStorage) => {
                                  const permissionsRequested = await publicStorage.publicStorage;
                                  console.log('prompt || denied || prompt-with-rationale');
                                  return `${permissionsRequested}` === 'granted';
                                });
                      }
                      return true;
                    });
          }
          return true;
        },
        async downloadFallback(doc) {
          this.loading = true;
          this.pdf = null;
          console.log('doc:', doc);
          // eslint-disable-next-line no-shadow
          await this.hasFilePermisions().then(result => {
            axios.get(doc, {
              responseType: 'arraybuffer',
            }).then(async (success) => {
              this.hasFilePermisions().then(async (resultData) => {
              //  if (resultData) {
                  const arrayUrl = doc.split('/');
                  const fileName = arrayUrl[arrayUrl.length - 1];
                  const pdfData = await success.data;
                  await this.writeAndPreviewPdf(pdfData, fileName.replace(' ', '-'));
                  this.pdf = doc;
                  this.title = fileName;
                  await this.$router.push({
                    name: 'pdf-view',
                    params: { url: this.pdf, localuri: this.localuri },
                  });
              //  } 
               /* else {
                  await Dialog.alert({
                    title: 'Información',
                    message: 'Denegaste los permisos de almacenamiento y esta operación no puede ser realizada',
                  });
                } */
              });

            });

          });

        },
        async mergePeriodDocuments() {
          this.isDownloadingPeriodDocuments = true;
          await this.mergeDocuments(this.period_documents).then(async (result) => {
            const checkCapacitor = await this.checkCapacitor();

            console.log('llegó hasta acá');
            if (checkCapacitor) {
              console.log('entro');
              this.downloadFallback(result.url);
            } else {
              window.location.href = result.url;
            }
          }).catch(() => {
            this.isDownloadingPeriodDocuments = false;
            this.$swal({
              icon: 'error',
              title: 'Ocurrió un error',
              text: 'No se ha podido generar el archivo!',
            });
          });
          this.isDownloadingPeriodDocuments = false;
        },
        async downloadSelected(settlements_selected) {
          const settlements_ids = Object.keys(settlements_selected);
          const selected = settlements_ids.filter((i) => settlements_selected[i]);
          const download_docs = this.settlements
                  .filter((s) => selected.indexOf(s.id) !== -1)
                  .map((s) => s.functional_unit.payment_notice);

          this.doc_generator_text = 'Generando documento...';
          const checkCapacitor = await this.checkCapacitor();

          this.mergeDocuments(download_docs).then(async (result) => {

            this.doc_generator_text = 'Descargar seleccionadas';
            if (checkCapacitor) {
              this.downloadFallback(result.url);
            } else {
              window.location.href = result.url;
            }
          }).catch(() => {
            this.doc_generator_text = 'Descargar seleccionadas';
            this.$swal({
              type: 'error',
              title: 'Ocurrió un error',
              text: 'No se ha podido generar el archivo!',
            });
          });
        },
        async changePeriod(period) {
          this.isLoadingSettlements = true;
          try {
            // Set settlements
            this.getSettlements(this.owners_association.id, period.id).then((res) => {
              this.settlements = res;
            });
            // Set period related info
            await this.getSettlementsSummary(this.owners_association.id, period.id).then((res) => {
              this.period_info = res;
              [this.period] = this.owners_association.periods.filter((p) => p.id === this.period_info.period_id);
              this.period_documents = this.period_info.period_documents.filter((d) => d.type !== 'Avisos de pago').map((d) => d.url);
            });
            this.isLoadingSettlements = false;
            this.close();
          } catch (err) {
            this.isLoadingSettlements = false;
            this.close();
          }
        },
      },
  watch:
      {
        owners_association:
            {
              handler(owners_association) {
                this.period = owners_association.periods[owners_association.periods.length - 1];
                this.changePeriod(this.period);
              },
            },
      },
  created() {
    if (this.owners_association) {
      this.period = this.owners_association.periods[this.owners_association.periods.length - 1];
      this.changePeriod(this.period);
    }
  },
  computed:
      {
        periods() {
          console.log(this.owners_association.periods);
          return this._.orderBy(this.owners_association.periods, 'settlement_date', 'desc');
        },
        disable_download_selected() {
          const keys = Object.keys(this.settlements_selected);
          const selected = keys.filter((i) => this.settlements_selected[i]);
          return selected.length === 0;
        },
        settlement_date() {
          if (!this.owners_association.periods) {
            return null;
          }
          return this.owners_association.periods.filter((period) => period.id === this.period.id)[0].settlement_date;
        },
        owners_association() {
          return this.$store.getters.ownersAssociation;
        },
        is_capacitor() {
          return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
        },
      },
};
</script>

<style lang='scss'>
.bottom-sheet__content{
  height: 20rem !important;
}
</style>
