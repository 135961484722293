<template>
  <div class="amenities-page-wrapper">
    <div class="container">
      <p class="section-header">
        <router-link
            :to="{ name: 'listado-amenities'}"
            class="card-link btn btn-rounded btn-primary"
            style="display: inline-block;">
          Amenities
        </router-link>
        <router-link
            :to="{ name: 'listado-reservas'}"
            class="card-link btn btn-rounded btn-outline-primary value"
            style="display: inline-block;">
          Reservas
        </router-link>
      </p>
    </div>
    <div class="page-content container-fluid">
      <vue-element-loading :active="isLoading" background-color="rgba(255, 255, 255, .0)" color="#CE4169"
                           spinner="spinner"/>
      <div v-if="!isLoading && !amenities.length" class="empty-state empty-state-amenities">
        <div class="wrapper">
          <h4>¡Aún no hay amenities!</h4>
          <p>No se han encontrado amenities disponibles.</p>
          <img alt="" class="image-empty" src="@/assets/img/empty-states/no-hay-amenities.png">
        </div>
      </div>
      <div class="card-deck" style="align-content: space-evenly; justify-content: center; margin-top: 1em">
        <div v-for="amenity in amenities" class="amenity" :key="amenity.id">
          <div class="card">
            <img :src="amenity.amenity_data.image_url" class="card-img-top">
            <div class="card-body">
              <div class="card-title">
                <img :src="getTypeImage(amenity.type.name)"/>
                <div class="amenity-title-wrapper">
                  <h5 class="amenity-title">{{ amenity.name | truncate(15) }}</h5>
                  <p class="amenity-subtitle">
                    {{ amenity.type.name }} &mdash;
                    <span>
                                        <span v-if="amenity.is_available">Habilitado</span>
                                        <span v-else>No habilitado</span>
                                     </span>
                  </p>
                </div>
              </div>
              <p class="card-text">{{ amenity.description | excerpt | truncate(120) }}</p>
              <div class="data_actions">
                <div class="actions">
                  <router-link
                      :to="{ name: 'detalle-amenity', params: { amenity_id: amenity.id } }"
                      id="btn-acti"
                      class="btn btn-rounded btn-outline-primary"
                      style="display: inline-block">
                    Detalles
                  </router-link>
                  <div
                      v-if="amenity.is_bookable &&
                            amenity.is_available"
                      @click="goToReservation(amenity)"
                      class="btn btn-rounded btn-outline-primary"
                      style="display: inline-block; ">
                    Solicitar Reserva
                  </div>
                  <!--
                  <router-link
                      v-if="amenity.is_bookable &&
                            amenity.is_available"
                      :to="{ name: 'reservar-amenity', params: { amenity_id: amenity.id } }"
                      class="btn btn-rounded btn-outline-primary"
                      style="display: inline-block; ">
                    Solicitar Reserva
                  </router-link>
                  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmenitiesList',
  props: ['amenities', 'bookings', 'isLoading', 'getTypeImage'],
  data() {
    return {};
  },
  computed: {},
  methods: {

    goToReservation(amenity) {
      
      if (amenity.blocked_for_user) {
       this.$swal({
         icon: 'warning',
         allowOutsideClick: false,
         allowEscapeKey: false,
         confirmButtonText: 'Contactar administración',
         showDenyButton: true,

         denyButtonText: 'Cerrar',
         title: 'Amenity bloqueado',
         text: 'En este momento no puedes solicitar una reserva',
       }).then((result) => {
         if (result) {
           if (result.isConfirmed) {
             this.$router.push(
                 {
                   name: 'contacto',

                 },
             );

           }
         }
       });
     } else {
       this.$router.push(
           
       { name: 'reservar-amenity', params: { amenity_id: amenity.id } },
       );
      
     }
  },
    },
  filters:
      {
        excerpt(text) {
          return text.length < 250 ? text : `${text.substr(0, 250)}...`;
        },
      },
};
</script>

<style lang='scss'>
#btn-acti {
  border: 0;
  margin-right: 2%;
}
</style>
