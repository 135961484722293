import Vue from 'vue';
import Vuex from 'vuex';

import mutations from './mutations';
import getters from './getters';
import actions from './actions';

Vue.use(Vuex);

const store = new Vuex.Store(
  {
    state: {
      owners_association: null,
      owners_associations: [],
      notifications: [],
      messages: [],
      new_notification: false,
      user: null,
      token: localStorage.getItem('AccessToken'),
      IdToken: localStorage.getItem('IdToken'),
      refreshToken: localStorage.getItem('RefreshToken'),
      logged: !!localStorage.getItem('AccessToken'),
      loading: false,
      loadingText: '',
      temporalPassword: '',
      email: '',
      hasMultiplesOwnersAssociations: false,
      new_user: 'n',
      openRequestModal: false,
      administrationFeatures: [],
      amenity: [],
      amenities: [],
      amenitiesBookings: [],
      booking: [],
      activeConnection: true,
    },
    mutations,
    getters,
    actions,
  },
);

export default store;
