<template>
  <notifications-payment-list
      v-if="paymentNotifications"
      :is-loading="isLoading"
      :payment-notifications="paymentNotifications"
      :img-no="imgNo"
  ></notifications-payment-list>
</template>
<script>

import NotificationsPaymentList from '@/components/OwnersAssociation/NotificationsPaymentList/NotificationsPaymentList';

export default {
  components: { NotificationsPaymentList },
  data() {
    return {
      paymentNotifications: [],
      isLoading: true,
      imgNo: false,
    };
  },
  methods:
      {
        async loadNotifications() {
          try {
            this.isLoading = true;
            this.paymentNotifications = await this.getPaymentNotifications();
            this.isLoading = false;
          } catch (err) {
            this.imgNo = true;
            this.isLoading = false;
          }
        },
      },
  created() {
    this.loadNotifications();
  },

};
</script>
