<template>
  <div id="expenses-landing" class="page-owners-associations-overview">

    <section id="nav" class="globalnav-desktop">
      <div class="container-fluid">
        <nav class="navbar navbar-dark">
          <router-link class="navbar-brand" to="/">
            <img src="@/assets/img/idata-alt.svg" class="logo" alt="">
          </router-link>

        </nav>
      </div>
    </section>

    <header-back title=" VOLVER" :url="redirect()" />
    <div class="container" >
     
      <div class="page-owners-associations-documents">

        <div class="container">
          <p class="section-header">Comprobantes</p>
        </div>
<div v-if="isValidLink">
        <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />

        <div>

          <h3 class="text-center">{{ this.period.title }}</h3>
        </div>

        <div class="empty-state empty-state-documents-date" style="margin-top:5rem !important" v-if="!vouchers"
          id="no-documents-message">
          <div class="wrapper">
            <h4>No hay comprobantes para este período</h4>

            <img src="@/assets/img/empty-states/no-hay-documentos.png" class="image-empty" alt="">
          </div>
        </div>
        <div v-else>

          <div v-if="daysLeft().show === true" class="empty-state empty-state-documents-date">
            <div class="wrapper">
              <h4>¡Aún no hay comprobantes!</h4>
              <p>Tus comprobantes estarán disponibles en {{ daysLeft().days }} dias</p>
              <img src="@/assets/img/empty-states/no-hay-documentos.png" class="image-empty" alt="">
            </div>
          </div>

          <template v-for="(voucher, index) in vouchers" v-else>
            <div class="documents-index" :key="index">
              <div class="container">
                <div class="let">
                  <p>{{ index }}</p>
                </div>
              </div>
              <div class="container">
                <div class="document_card" style="padding-bottom: 0">

                  <router-link
                    :to="{ name: 'detalle-comprobante-landing', params: { id: item.id, voucher: item }, query: { userId: currentUserId } }"
                    class="document-item" v-for="item in voucher" :key="item.id">
                    <span
                      :class="item.invoice_url ? 'document-icon-container circled_active' : 'document-icon-container circled'">

                      <img src="@/assets/img/icons/document.svg" class="document-icon" alt="" /></span>

                    <div class="document-data" style="white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;">

                      <div class="document-title voucher">
                        <h5 class="document-title">{{ item.supplier_name }}</h5>
                        <h4 class="document-category">{{ item.invoice_description }}</h4>
                      </div>

                    </div>
                    <div style="margin-left: 4.5rem;">
                      {{ item.invoice_amount | currency }}
                    </div>
                  </router-link>

                </div>
              </div>
            </div>
          </template>
        </div>
</div>
        <div v-else class="empty-state empty-state-documents-date">
          <div class="wrapper">
            <h4>No hay datos disponibles</h4>

            <img src="@/assets/img/empty-states/no-hay-documentos.png" class="image-empty" alt="">
          </div>
        </div>
      </div>

    </div>

    <footer id="footer" class="container-fluid footer">
      <router-link class="navbar-brand" to="/">
        <img src="@/assets/img/idata-alt.svg" class="logo" alt="">
      </router-link>
    </footer>
  </div>
</template>
<script>
import { Capacitor } from '@capacitor/core';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
import axios from 'axios';
import DropdownSelector from '@/components/Layouts/DropdownSelector';
import HeaderBack from '@/components/Layouts/HeaderBack';
import Settlements from '@/components/Settlements/Settlements';

export default {
  name: 'Vouchers',
  components: {
    DropdownSelector, HeaderBack, VueBottomSheet, Settlements,
  },
  props: [],
  data() {
    return {
      browserUrl: window.location,
      vouchers: null,
      existing_vouchers: false,
      periods: null,
      period: null,
      userId: null,
      created_at: null,
      isLoading: false,
      invoices_delay: 15,
      ownersAssociationId: null,
      currentUserId: this.$route.query.userId,
      isValidLink: true,
    };
  },
  computed: {
    owners_association() {
      if (this.$route.params.owners_association_id) {

        return { id: this.$route.params.owners_association_id };

      }
      return this.$store.getters.ownersAssociation;
    },
    is_capacitor() {
      if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
        return true;
      }
      return false;
    },

  },
  methods: {

    redirect() {

      return `/expenses/id/${this.userId}/ownersAssociation/${this.ownersAssociationId}?period_id=${this.period.period_id}`;
    },

    daysLeft() {
      // Obtener la fecha actual
      const fechaActual = new Date();

      // eslint-disable-next-line no-restricted-globals
      if (!this.period.settlement_created_at || isNaN(this.invoices_delay)) return { show: false, days: 0 };
      // Convertir la fecha proporcionada a un objeto Date
      const fechaComparar = new Date(this.period.settlement_created_at);

      // Sumar la cantidad de días a la fecha dada

      // eslint-disable-next-line radix
      fechaComparar.setDate(fechaComparar.getDate() + parseInt(this.invoices_delay));

      // Calcular la diferencia en días
      const diferenciaMs = Math.abs(fechaActual - fechaComparar);
      const diferenciaDias = Math.ceil(diferenciaMs / (1000 * 60 * 60 * 24));

      // Verificar si la fecha resultante es menor o igual a la fecha actual
      if (fechaComparar <= fechaActual) {
        return { show: false, days: diferenciaDias };
      }
      return { show: true, days: diferenciaDias };

    },

  },
  async created() {

    this.isLoading = true;

    this.period = JSON.parse(localStorage.getItem('period'));

    this.userId = localStorage.getItem('user_id');
    this.invoices_delay = localStorage.getItem('invoices_delay');
    this.ownersAssociationId = localStorage.getItem('owners_association_id');

    if (this.period.period_id) {

      if (this.ownersAssociationId) {
        await axios.get(
          /// owners_associations/:owners_association_id/aws_user_id/:aws_user_id/interactive_invoices/:period_id'
          `${process.env.VUE_APP_CORE_SERVICE_URL}/owners_associations/${this.ownersAssociationId}/aws_user_id/${this.userId}/interactive_invoices/${this.period.period_id}`, {
          headers:
          {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
        ).then(async (res) => {
          this.vouchers = await res.data.data.interactive_invoices;

          //  this.period = await res.data.data.period;
          console.log(JSON.stringify(this.period));

          this.isLoading = false;
        }).catch(() => {
          this.isValidLink = false;
        });
      } else {
        alert('a');
        await this.getInteractiveInvoices(this.ownersAssociationId, this.$route.query.period_id).then(async (res) => {
          this.vouchers = await res.interactive_invoices;

          this.periods = await res.all_periods;
          this.isLoading = false;
        });

      }
    } else {
      alert('c');
      this.getConfirmVouchers(this.ownersAssociationId).then(async (voucher) => {

        this.vouchers = await voucher.interactive_invoices;
        this.periods = await voucher.all_periods;

        this.period = await voucher.period;

        this.isLoading = false;
      }).catch(() => {
        this.isLoading = false;
      });

    }
    this.isLoading = false;
  },
};
</script>

<style lang='scss'>
.header {
  min-height: 6em;
}

.header-text {
  font-weight: 300;
  letter-spacing: 0.075em;
  padding: 1em 0;
}

.owners-associations-card {
  padding: .5em 0;
  border-radius: 6px;
  margin-top: 1.5rem;
}

.owners-association-data {
  line-height: -1.25rem;
}

.settlement-card {
  padding: .5em 0;
  border-radius: 6px;
  margin-top: 1.5rem;
  margin-bottom: -5.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 12), 0 1px 2px rgba(0, 0, 0, 24);
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
}

.settlement-month {
  margin-bottom: .5rem;
  font-size: 1.5rem;
  color: #000;
  text-transform: uppercase;
  font-weight: 300;
}

.owners-association-city-image {
  max-width: 98%;
  height: auto;
  display: block;
  z-index: 998;
  margin-left: auto;
  margin-right: auto;
  margin-top: -20px;
}

.settlement-overview {
  display: block;
  margin-top: -5rem;
  z-index: 999;
}

.settlement-data {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

#settlement-actions {
  padding: .75em 1.25em .75em 1.25em;
  justify-content: center;
}

#download_documents,
#download_settlements,
#view_invoices {
  padding-top: 0.75em !important;
  padding-bottom: 0 !important;
}

.owners-associations-name-landing {
  margin-top: .5rem;
  font-size: 1.25rem;
  font-weight: 400;
  color: #000;
}

.owners-associations-address-landing {
  margin-top: -.5rem;
  margin-bottom: .5rem;
  font-size: 1rem;
  font-weight: 300;
  color: #676a69
}

#footer-login-text {
  letter-spacing: .075em;
  margin-top: -3.5rem;
  font-weight: 300;
}

#footer-login-btn {
  margin-bottom: -3.5em;
}

#expenses-landing {
  background-color: white
}

.footer {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%; 
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 30vh;
  padding: .5rem 1rem;
  justify-content: center;
  background-color: #081a2b;
}

.logo {
  width: 100px;
  height: auto;
}

@media all and (max-width: 360px) {
  .btn {
    min-width: 1px !important;
  }
}

.document-item {
  margin-bottom: 0;
}

.documents-index .document-item .document-data {
  margin-top: 0 !important;
}

.documents-index .document-item .document-data .document-title {
  margin-top: 0 !important;
  font-weight: bold;
}

.let p {
  text-transform: lowercase;
  font-weight: bold;
  color: #858991;
}

.let p:first-letter {
  text-transform: uppercase;
}

.page-owners-associations-documents {
  padding-bottom: 5rem;
  min-height: 90vw;
}

.empty-state-documents-date {
  margin-top: 5rem !important;
}

@media (max-width: 770px) {
  .let p {
    margin-bottom: 2rem;
  }
}
</style>
