<template>

  <div class="page-account-view">

    <section class="bar theme-blue" >
      <div class="container">
        <div class="bar-avatar">

          <span><img src="@/assets/img/empezar.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">
          Empezar
        </h3>

      </div>
    </section>

    <div class="container bar-details">
      <br>
      <div class="card mb-4 card_margin card-type">
        <div class="card-body">
          <div>
            <h2 class="title max-45">¡Bienvenido/a!</h2>
            <p class="sub_title">Te damos la bienvenida a nuestra plataforma de comunicaciones.<br>
              Es importante completar tus datos personales para poder contactarte con mayor facilidad.</p><br>
          </div>
          <div id="divHijo">
            <img
                src="@/assets/img/bienvenido.png"
                class="image_welcome"
            />
          </div>

        </div>
      </div>

      <div class="card mb-4 card_margin card-type">
        <div class="card-body">
          <vue-element-loading :active="!user.email" spinner="spinner" color="#CE4169"/>

          <h5 class="title_sub">Completa tus datos</h5>

          <form method="POST" action="" @submit="updateMe">

            <input required="required" type="text" id="first_name" v-model="user.first_name" class="form-control form_color"
                   placeholder="Ingresa tu nombre">

            <input required="required" type="text" id="last_name" v-model="user.last_name" class="form-control form_color"
                   placeholder="Ingresa tu apellido">

            <input type="number" id="phone_number" v-model="user.phone" class="form-control form_color"
                   placeholder="Ingresa tu teléfono">

            <input disabled="disabled" type="email" v-model="user.email" id="email" class="form-control form_color max-50">

            <div class="actions_welcome">

              <input type="submit" value="Guardar" class="btn btn-rounded btn-primary sw"/>

            </div>

          </form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'getting-started',
  data() {
    return {
      user: {},
      newUser: {},
    };
  },
  methods:
      {
        updateMe(e) {
          e.preventDefault();

          this.getWelcome();
          delete this.user.email;

          this.updateProfile(this.user)
            .then(() => {
              window.open('https://idatasuite.com.ar/welcome/copropietarios/index.html', '_self');

              /* this.$router.push(
              {
                name: 'consorcios',
                params:
                {
                  success_message: 'Tu perfil se editó satisfactoriamente'
                }
              }); */
            })
            .catch(() => {
              this.$router.push(
                {
                  name: 'empezar',
                  params:
                          {
                            error_message: 'Ocurrió un error al editar tu perfil, intenta más tarde',
                          },
                },
              );
            });
        },
      },
  async created() {
    this.user = await this.getProfile();
    this.newUser = this.getProfile();
    delete this.newUser.email;
    this.newUser.first_name = 'aún no completo su nombre';
    this.newUser.last_name = 'aún no completo su apellido';
    this.newUser.phone = '123456';
    this.updateProfile(this.newUser);
  },
};
</script>
