<template>
  <confirm-password
      :error="error"
      :password-type="passwordType"
      :password-icon="passwordIcon"
      :password-icon-confirm="passwordIconConfirm"
      :password-type-confirm="passwordTypeConfirm"
      :user="user"
      @onChangePassword="hidePassword = !hidePassword"
      @onChangePasswordConfirm="hidePasswordConfirm = !hidePasswordConfirm"
      @onConfirm="ConfirmPasswordPage_changeTemporaryPassword"></confirm-password>
</template>
<script>

import ConfirmPassword from '@/components/Login/ConfirmPassword';
import store from '@/store';

export default {
  props: ['resetPassword'],
  components: {
    ConfirmPassword,
  },
  data() {
    return {
      user: this.$route.params.username,
      reset: this.$route.query.reset,
      hidePassword: true,
      hidePasswordConfirm: true,
      password:
          {
            new: '',
            confirmation: '',
          },
      error: null,
    };
  },
  computed: {
    passwordType() {
      return this.hidePassword ? 'password' : 'text';
    },
    passwordIcon() {
      // eslint-disable-next-line global-require
      return this.hidePassword ? require('@/assets/img/ojo-abierto.svg') : require('@/assets/img/ojo-cerrado.svg');
    },
    passwordTypeConfirm() {
      return this.hidePasswordConfirm ? 'password' : 'text';
    },
    passwordIconConfirm() {
      // eslint-disable-next-line global-require
      return this.hidePasswordConfirm ? require('@/assets/img/ojo-abierto.svg') : require('@/assets/img/ojo-cerrado.svg');
    },
  },
  methods:
      {
        ConfirmPasswordPage_changeTemporaryPassword(password) {
          if (password.new !== password.confirmation) {
            this.error = 'Las contraseñas ingresadas deben coincidir entre si';
          } else {
            const temporalPass = store.getters.temporalPassword;

            console.log('temporalPass', temporalPass);
            console.log('email:', store.getters.email);
            this.changePassword(temporalPass, password.new).then(() => {
              if (this.reset) {
                window.location.replace('/');
              } else {
                window.location.replace('/empezar');
              }
            }).catch();
          }

          // const self = this;
          /* this.changeTemporaryPassword(password.new)
            .then((success) => {
              console.log(success);
              if (self.reset) window.location.replace('/');
              else window.location.replace('/empezar');
            })
            .catch(console.log); */
        },
      },
  mounted() {
    console.log('entra a confirm');
    console.log('user confirm:', this.user);
    const temporalPass = store.getters.temporalPassword;
    console.log('this.resetPassword:', temporalPass);
  },
};
</script>

<style lang='scss'>

</style>
