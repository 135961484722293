import axios from 'axios';
import store from '@/store';
import auth from '@/mixins/auth';

let $_NotificationService_http = {};

export default {

  created() {
    $_NotificationService_http = axios.create(
      {
        baseURL: process.env.VUE_APP_NOTIFICATION_SERVICE_URL,
        headers:
          {
            Accept: 'application/json',
          },
      },
    );

    $_NotificationService_http.interceptors.request.use((config) => {
      if (store.getters.user.IdToken) {
        config.headers.Authorization = store.getters.user.IdToken;
      }
      return config;
    });

    $_NotificationService_http.interceptors.response.use((response) => response);
  },

  methods:
    {
      $_NotificationService_getResource(endpoint) {
        return new Promise((resolve, reject) => {
          $_NotificationService_http.get(endpoint).then((result) => {
            resolve(result.data);
          }).catch((err) => {

            reject(err);
          });
        });
      },
      $_NotificationService_postResource(endpoint, data) {
        return new Promise(async (resolve, reject) => {
          try {
            // await updateUserToken();
            const result = await $_NotificationService_http.post(endpoint, data);
            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },
      getNotifications() {
        return new Promise(async (resolve, reject) => {
          this.$_NotificationService_getResource('/notifications').then((result) => {
            const res = resolve(result);
              console.log(JSON.stringify(result));
          }).catch((err) => { reject(err); });
        });
      },
      setNotificationsAsSeen(notifications) {
        return new Promise(async (resolve, reject) => {
          try {
            const result = await $_NotificationService_http.put('/notifications/mark_as_seen',
              {
                notification_ids: notifications,
              });

            resolve(result.data);
          } catch (err) {
            reject(err);
          }
        });
      },

    },
};
