<template>

  <div id="main-app-nav" class="main-app-nav" v-if="displayBar">

    <section class="globalnav-desktop d-none d-sm-block">
      <div class="container-fluid">
        <nav class="navbar navbar-dark">
          <router-link class="navbar-brand" to="/">
            <img src="@/assets/img/idata-alt.svg" class="logo" alt="">
          </router-link>
          <ul class="nav" v-if="displayBar">
            <li class="nav-item nav-item-owners-associations-index">
              <router-link class="nav-link" to="/">
                <span v-if="has_multiples_owners_associations">Mis consorcios</span>
                <span v-else>Mi consorcio</span>
              </router-link>
            </li>
            <li class="nav-item nav-item-messages" v-if="messages_enabled && administration">
              <router-link class="nav-link" :to="'/mensajes/administracion/'+administration.id">
                <img v-if="!new_messages" src="@/assets/img/icons/messages-white.svg" class="icon" alt="">
                <img v-else src="@/assets/img/icons/messages-with-alert-white.svg" class="icon" alt="">
              </router-link>
            </li>
            <li class="nav-item nav-item-notifications">
              <router-link class="nav-link" to="/notificaciones">
                <img v-if="!new_notifications" src="@/assets/img/icons/notifications-white.svg" class="icon" alt="">
                <img v-else src="@/assets/img/icons/notifications-with-alert-white.svg" class="icon" alt="">
              </router-link>
            </li>
            <li class="nav-item nav-item-account">
              <router-link class="nav-link" to="/cuenta">
                <img src="@/assets/img/icons/account-white.svg" class="icon" alt="">
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </section>

    <section :class="is_ios ? 'globalnavIos d-block d-sm-none' : 'globalnav d-block d-sm-none'">
      <div class="container ">
        <ul class="nav">
          <li class="nav-item nav-item-owners-associations-index">
            <router-link class="nav-link" to="/">
              <img v-if="selected==='Consorcios'" src='@/assets/img/icons/owners-associationpleno.svg'  class="icon" alt="">
              <img v-else src='@/assets/img/icons/owners-association.svg'  class="icon" alt="">
              <span v-if="has_multiples_owners_associations">Consorcios</span>
              <span v-else>Consorcio</span>
            </router-link>
          </li>

          <li class="nav-item nav-item-messages" v-if="messages_enabled && administration">
            <router-link class="nav-link" :to="'/mensajes/administracion/'+administration.id">

              <img v-if="!new_messages && selected!=='Mensajes'" src="@/assets/img/icons/messages.svg" class="icon" alt="">
              <img v-else-if="selected==='Mensajes'" src='@/assets/img/icons/messagespleno.svg'  class="icon" alt="">
              <img v-else src="@/assets/img/icons/messages-with-alert.svg" class="icon" alt="">
              <span>Mensajes</span>
            </router-link>
          </li>

          <li class="nav-item nav-item-notifications">
            <router-link class="nav-link" to="/notificaciones">

              <img v-if="!new_notifications && selected!=='Notificaciones'" src="@/assets/img/icons/notifications.svg" class="icon" alt="">
              <img v-else-if="selected==='Notificaciones'" src='@/assets/img/icons/notificationspleno.svg'  class="icon" alt="">
              <img v-else src="@/assets/img/icons/notifications-with-alert.svg" class="icon" alt="">
              <span>Notificaciones</span>
            </router-link>
          </li>
          <li class="nav-item nav-item-account">
            <router-link class="nav-link" to="/cuenta">
              <img v-if="selected==='Cuenta'" src='@/assets/img/icons/accountpleno.svg'  class="icon" alt="">
              <img v-else src='@/assets/img/icons/account.svg'  class="icon" alt="">

              <span>Mi cuenta</span>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
  </div>

</template>

<script>
import { Capacitor } from '@capacitor/core';

export default {
  name: 'main-nav',
  props: ['display'],
  data() {
    return {
      displayBar: null,
      
    };
  },
  created() {

    const token = localStorage.getItem('AccessToken');
    if (!token) this.displayBar = false;
    else this.displayBar = true;
    },
  computed:
      {
        is_ios() {

          return (Capacitor.getPlatform() === 'ios');
        },
        new_notifications() {
          return this.$store.getters.notifications
              && this.$store.getters.notifications.filter((n) => !n.seen).length > 0;
        },
        has_multiples_owners_associations() {
          return this.$store.getters.hasMultiplesOwnersAssociations;
        },
        new_messages() {
          const { messages } = this.$store.getters;
          const newMessages = this.$store.getters.messages.filter(
            (m) => m.last_message && !m.last_message.read_at && m.last_message.is_sent_by_administration,
          );
          return messages && newMessages.length > 0;
        },
        messages_enabled() {
          return this.$store.getters.administrationFeatures.messagesEnabled;
        },
        selected() {
         return this.$route.meta.tag;
        },
        
        administration() {
          return this.$store.state.owners_association && this.$store.state.owners_association.administration;
        },
      },
};
</script>
