<template web>
  <div class="page-account-view page-news-view">
    <header-back :url="'/consorcios/'+owners_association.id+'/noticias'" />

    <section class="bar theme-blue">
      <div class="container" v-if="no_news">
        <div class="bar-avatar">
          <span><img src="@/assets/img/icono-noticias.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">{{news.title}}</h3>
        <h5 class="bar-tagline">{{news.category}}</h5>
        <br>
      </div>
      <div class="container" v-else>
        <div class="bar-avatar">
          <span><img src="@/assets/img/icono-noticias.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">Noticia</h3>
        <h5 class="bar-tagline">No disponible en este momento, intente nuevamente mas tarde.</h5>
        <br>
      </div>
    </section>

    <div class="container bar-news">
      <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />
      <vue-element-loading :active="isLocalLoading" spinner="spinner" color="#CE4169" />
<!--
      <p-d-fview v-if="pdf" :url="pdf" :title="title"></p-d-fview>
-->

      <div class="news-detail shadow" v-if="no_news" style=" min-height: 12rem;">
        <p class="news-body" v-html="news.body" />
        <div class="text-center news-image">

          <div  v-for="image in news.images" :key="image.id">
            <a :href="image.url" v-if="!is_capacitor" data-lightbox="roadtrip" class="d-block mb-4 h-100">
              <img class="img-fluid img-thumbnail" :src="image.thumbnail_xs" alt="">
            </a>

            <a class="d-block mb-4 h-100" data-lightbox="roadtrip" v-if="is_capacitor" >
              <img :src="image.thumbnail_xs" class="img-fluid img-thumbnail" alt="" @click="downloadFallback(image, 'img')">
            </a>
          </div>
        </div>
        <div v-if="news.attachments && news.attachments.length">
          <strong class="ml-1">Adjuntos</strong>
          <div class="mt-3">
            <div class="news-attachments" v-for="attachment in news.attachments" :key="attachment.id">
              <a :href="attachment.url" target="_blank"
                 class="btn btn-white" v-if="!is_capacitor" style="color: #CE4169; margin: 0; padding: 0">
                <img src="@/assets/img/icons/document-alt.svg" class="icon" style="vertical-align: top;"
                     alt="" /> {{attachment.file_name}}
              </a>
              <a @click="downloadFallback(attachment)" target="_blank"
                 class="btn btn-white" v-else style="color: #CE4169; margin: 0; padding: 0">
                <img src="@/assets/img/icons/document-alt.svg" class="icon" style="vertical-align: top;"
                     alt="" /> {{attachment.file_name}}
              </a>

            </div>
          </div>
        </div>
      </div>

      <div class="news-detail shadow" v-else>
        <div class="empty-state empty-state-documents" id="no-documents-message" v-if="imgNo">
          <div class="wrapper">
            <h4>¡La noticia no se encuentra disponible en este momento!</h4>
            <p>Vuelve a intentar nuevamente mas tarde, o contacta a tu administración para mas información.
            </p>
            <img src="@/assets/img/empty-states/mensaje-documento-eliminado.png" class="image-empty" alt="">
          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Dialog } from '@capacitor/dialog';
import axios from 'axios';
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  components: {
    HeaderBack,
  },
  name: 'NewsDetail',
  props: ['owners_association', 'no_news', 'news', 'isLoading', 'imgNo'],
  data() {
    return {
      pdf: null,
      title: null,
      localuri: null,
      isLocalLoading: false,
    };
  },
  computed: {
    is_capacitor() {
      if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async writeAndPreviewPdf(base64Data, fileName) {
      await Filesystem.mkdir({ path: 'joinup', directory: Directory.Cache }).then(async (createdDirectory) => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;

          console.log(`local uri  ${writedFile.uri}`);
        });
      }).catch(async (error) => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;

          console.log(`local uri  ${writedFile.uri}`);
        });
      });
    },
    async hasFilePermisions() {
      if (Capacitor.getPlatform() === 'ios') {
        return Filesystem.checkPermissions()
                .then(async (result) => {
                  if ((`${result.publicStorage}` === 'prompt') || (`${result.publicStorage}` === 'denied') || (`${result.publicStorage}` === 'prompt-with-rationale')) {
                    return Filesystem.requestPermissions()
                            .then(async (publicStorage) => {
                              const permissionsRequested = await publicStorage.publicStorage;
                              console.log('prompt || denied || prompt-with-rationale');
                              return `${permissionsRequested}` === 'granted';
                            });
                  }
                  return true;
                });
      }
      return true;
    },
    async downloadFallback(doc, type = 'pdf') {
      this.loading = true;
      this.pdf = null;
      await this.hasFilePermisions().then(result => {
        axios.get(doc.url, {
          responseType: 'arraybuffer',
        }).then(async (success) => {
          this.hasFilePermisions().then(async (resultData) => {
          //  if (resultData) {
              const arrayUrl = doc.url.split('/');
              const fileName = arrayUrl[arrayUrl.length - 1];
              const pdfData = await success.data;
              this.isLocalLoading = true;
              await this.writeAndPreviewPdf(pdfData, fileName.replace(' ', '-'));
              this.pdf = doc.url;
              this.isLocalLoading = false;
              this.title = doc.title;
              await this.$router.push({
                name: 'pdf-view',
                params: { url: this.pdf, localuri: this.localuri, docType: type },
              });
            // }
           /* else {
              await Dialog.alert({
                title: 'Información',
                message: 'Denegaste los permisos de almacenamiento y esta operación no puede ser realizada',
              });
            } */
          });

        });

      });

    },

  },
};
</script>

<style lang='scss'>

</style>
