<template>
  <div class="page-owners-associations-index">
    <header id="head">
      <div class="container">
        <section class="main">
          <div class="title">
            <img src="@/assets/img/idata-alt.svg" class="logo" alt="idata">
          </div>
        </section>
      </div>
    </header>
    <div class="container">
      <p class="section-header">Mis consorcios</p>
      <div class="separator_owners"></div>
    </div>
    <div class="container">
      <div v-if="is_capacitor" class="" >
        <template v-for="administration in Object.keys(administrations)" >
          <router-link
              :to="{ name: 'inicio', params: { owners_association_id: oa_adm.id } }"
              v-for="oa_adm in administrations[administration]"
              v-bind:key="oa_adm.id"
              style="color:transparent">
            <div class="owners-associations-index mb-3">
              <div class="container" style="padding: 0 !important;">
                <div class="owners-association-item card">
                  <div class="card-body" style="width: 100%">
                    <div class="owners-association-data" style="width: 100%">
                      <div class="owners-association-title my-2" >
                        <h5 class="owners-association-title">{{oa_adm.name.slice(7)}}</h5>
                        <p class="owners-association-address">
                          {{oa_adm.address | truncate(19)}}<br>
                          {{oa_adm.city}} ({{oa_adm.postal_code}})<br>
                          {{oa_adm.province  | truncate(30) }}, Argentina
                        </p>
                        <div class="row">
                          <p class="owners-association-admin col" style="color: #CE4169">{{oa_adm.administration.name }}</p>
                          <p class="owners-association-admin col text-right" style="color: #CE4169">{{oa_adm.name.slice(0,4) }}</p>
                        </div>
                      </div>
                      <div class="owners_association-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </template>
      </div>
      <div v-else class="center" >
        <template v-for="administration in Object.keys(administrations)">
          <router-link
              :to="{ name: 'inicio', params: { owners_association_id: oa_adm.id } }"
              v-for="oa_adm in administrations[administration]"
              v-bind:key="oa_adm.id"
              style="color: transparent;">
            <div class="owners-associations-index">
              <div class="container" style="padding: 0 !important;">
                <div class="owners-association-item-horizontal card">
                  <div class="card-body">
                    <div class="image-card" align="center">
                      <img
                          :src="getAvatar(oa_adm.avatar)"
                          :srcset="get2xAvatar(oa_adm.avatar) + ' 2x'"
                          class="avatar avatar-xl horizontal" />
                    </div>
                    <div class="owners-association-data">
                      <div>
                        <h5 class="owners-association-title">{{oa_adm.name | truncate(28)}}</h5>
                        <p class="owners-association-address" >
                          {{oa_adm.address | truncate(35) }} <br> {{oa_adm.city}} ({{oa_adm.postal_code}})<br>
                          {{oa_adm.province}}, Argentina
                        </p>
                        <p class="owners-association-admin">{{oa_adm.administration.name  | truncate(40) }}</p>
                      </div>
                      <div class="owners_association-icon"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';

export default {
  props: ['has_multiples_owners_associations', 'administrations', 'getAvatar', 'get2xAvatar'],
  computed: {
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
  },
};
</script>

<style lang='scss'>
#head{
  @media (max-width: 576px) { display: none }
}
</style>
