<template>
  <pay
          :owners_association="owners_association"
          title="Realizar un pago"
          textButton="Pagar con expensas pagas"
          :loading="loading"
          :showModalError="showModalError"
          :total_amount="total_amount"
          :total_amountShow="total_amountShow"
          :input="input"
          :dueStatus="dueStatus"
          :hasFee="hasFee"
          :is_with_in_limit_date="is_with_in_limit_date"
          @onPay="sendPay"
          @update:showModalError="showModalError = $event"
          @onhandleAmount="handleAmount">
  </pay>
</template>

<script>
  import { Capacitor } from '@capacitor/core';
  import { InAppBrowser } from '@ionic-native/in-app-browser';
  import Pay from '@/components/ExpensasPagas/Pay/Pay';

  export default {
    components: {
      Pay,
    },
    data() {
      return {
        settlements: [],
        input: {
          payment_date: '',
          amounts: {},
          amountsShow: {},
        },
        total_amount: 0,
        total_amountShow: 0,
        loading: false,
        is_with_in_limit_date: false,
        showModalError: false,
        hasFee: false,
        dueStatus: '',
      };
    },
    computed: {
      owners_association() {
        return this.$store.getters.ownersAssociation;
      },
    },
    methods: {
      showSettlements() {
        let total = 0;
        const fu_ids = Object.keys(this.input.amountsShow);
        for (let i = 0; i < fu_ids.length; i += 1) {
         /* if (this.$moment.utc(this.input.payment_date).format('Y-MM-DD') <= this.$moment.utc(this.settlements[i].first_due_date).format('Y-MM-DD')) {
            total += this.settlements[i].amount_on_first_due_date;
            this.input.amountsShow[fu_ids[i]] = this.settlements[i].amount_on_first_due_date;
          } else {
            total += this.settlements[i].amount;
            this.input.amountsShow[fu_ids[i]] = this.settlements[i].amount;
          }
      */
          total += this.settlements[i].amount;
          this.input.amountsShow[fu_ids[i]] = this.settlements[i].amount;
        }
        this.total_amountShow = total;
      },
      async setSettlements(period, owner) {
        try {
          this.settlements = await this.getSettlements(owner, period);
          console.log(JSON.stringify(this.settlements));

          let total = 0;
          const fu_ids = this.owners_association.functional_units.map((p) => p.id);
          for (let i = 0; i < fu_ids.length; i += 1) {
            console.log(JSON.stringify(this.settlements[i]));
            
            let { amount } = this.settlements[i];
          
            this.is_with_in_limit_date = this.settlements[i].is_with_in_limit_date;
            if (this.settlements[i].amount_with_service_fee != null && this.settlements[i].amount_with_service_fee != this.settlements[i].amount) {
 amount = this.settlements[i].amount_with_service_fee;
            this.hasFee = true;
}
            console.log(amount);
            console.log(total);
            total += amount;
            this.input.amountsShow[fu_ids[i]] = amount;
            this.input.amounts[fu_ids[i]] = amount;
          }
          this.total_amount = total;
          this.total_amountShow = total;
        } catch (err) {
          console.log(err);
        }
      },
      handleAmount(inputComponent) {
        let total = 0;
        this.input.amounts = inputComponent.amounts;
        const fu_ids = Object.keys(this.input.amounts);
        for (let i = 0; i < fu_ids.length; i += 1) {
          if (parseFloat(this.input.amounts[fu_ids[i]]) > 0) {
            const n = parseFloat(this.input.amounts[fu_ids[i]]);
            total += n;
          } else {
            this.input.amounts[fu_ids[i]] = 0;
          }
        }
        this.total_amount = total;
      },
      async sendPay(functionalUnitIds, totalAmount) {
        this.loading = true;
        const user = localStorage.getItem('User');
        const email = JSON.parse(user).data.username;
        const details = functionalUnitIds.map((id) => {
          const functionalUnit = this.owners_association.functional_units.find(fu => fu.id === id);
          return {
            cpe: functionalUnit.cpe,
            monto: parseFloat(this.input.amounts[id]),
        
          };
        });

       const data = {
          email,
          idReferenciaOperacionComercio: '',
          period_id: parseInt(this.period, 10),
          detalle: details,
        };
       
        try {
          const result = await this.initializeExpensasPagasPaymentFlow(this.owners_association.id, data);
          if (result.data.hash) {
            const url = result.data.url + result.data.hash;
            localStorage.setItem('hash', result.data.hash);
            localStorage.setItem('owners_association_id', this.owners_association.id);

            if (Capacitor.getPlatform() === 'ios' || Capacitor.getPlatform() === 'android') {
              const browser = InAppBrowser.create(url, '_blank', 'hidden=no,location=no,clearsessioncache=yes,clearcache=yes');
              browser.on('loadstart').subscribe((e) => {
                if (e.url.includes('joinup')) {
                  browser.close();
                }
              });
              this.loading = false;
            } else {
              this.loading = false;
              window.location.href = url;
            }
          } else {
            this.showModalError = true;
            this.loading = false;
          }
        } catch (err) {
          console.log(err);
          this.loading = false;
          this.showModalError = true;
        }
      },
    },
    async created() {
      this.loading = true;
      if (this.owners_association.periods) {
        this.period = this.owners_association.periods[this.owners_association.periods.length - 1].id;
        this.owner = this.owners_association.id;
        await this.setSettlements(this.period, this.owner);
      }
      this.loading = false;
      this.settlements.forEach(settlement => {
        if (settlement.status === 'Vencido') {
          this.dueStatus = 'Vencido';
        }
      });
    },
  };
</script>

<style lang="scss">
</style>
