<template>
  <div class="page-account-view page-news-view">
    <!-- <header-back title=" VOLVER" :url="'/consorcios_landing/'+this.$route.params.owners_association_id+'/comprobantes?period_id='+voucher.period_id" /> -->
    <header-back title=" VOLVER" @click="this.$router.go(-1)" />
    <section class="bar theme-blue">
      <div class="container">
        <div class="bar-avatar">
          <span><img src="@/assets/img/icono-noticias.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">{{voucher.supplier_name}}</h3>
        <br>
      </div>
    </section>
    <div class="container bar-news">
      <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />
      <div class="news-detail border-box">
        <div class="title_voucher">Fecha</div>
        <div>
          <p class="news-body voucher_details">{{ voucher.invoice_date | moment('ll') }}</p>
        </div>
      </div>
      <div class="news-detail border-box" >
        <div class="title_voucher">Comprobante</div>

        <div>
          <p class="news-body voucher_details">{{ voucher.invoice_number }}</p>
        </div>
      </div>
      <div class="news-detail border-box" >
        <div class="title_voucher">Rubro</div>
        <div>
          <p class="news-body voucher_details">{{ voucher.heading_id }}</p>
        </div>
      </div>
      <div class="news-detail border-box" >
        <div class="title_voucher">Porcentual</div>
        <div>
          <p class="news-body voucher_details">{{ voucher.percentaje }}</p>
        </div>
      </div>
      <div class="news-detail border-box" >
        <div class="title_voucher">Importe</div>
        <div>
          <p class="news-body voucher_details">{{ voucher.invoice_amount | currency }}</p>
        </div>
      </div>

    </div>

    <div class="container info-comprobante">

      <div class="news-detail border-box">
        <div class="title_voucher-description title_voucher">Más información </div>
        <p class="news-body-description">{{ voucher.invoice_description }}</p>
      </div>

    </div>

    <div  class="container mt-4">

      <a v-if="is_capacitor && voucher.invoice_url" @click="downloadFallback(voucher.invoice_url)" :class="voucher.invoice_url ? 'btn btn-primary' : 'btn btn-primary disabled'" style="width: 100%;">DESCARGAR</a>
     <!-- <a v-if="is_capacitor && !voucher.invoice_url" @click="downloadFallback(voucher.invoice_url)" :class="voucher.invoice_url ? 'btn btn-primary' : 'btn btn-primary disabled'" style="width: 100%;">NO HAY COMPROBANTES</a> -->

      <a v-else-if="!is_capacitor && voucher.invoice_url != null && voucher.invoice_url != '' " :href="voucher.invoice_url" target="_blank" class="btn btn-primary" style="width: 100%;">DESCARGAR COMPROBANTE</a>
      <!--<a v-else-if="!is_capacitor && !voucher.invoice_url !== null"   class="btn btn-primary" style="width: 100%;">NO HAY COMPROBANTES</a>-->

    </div>

  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import axios from 'axios';
import { Dialog } from '@capacitor/dialog';
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  components: {
      HeaderBack,
  },
  name: 'VoucherDetail',
  props: ['voucher', 'owners_association'],
  data() {
    return {

      isLoading: false,
      pdf: null,
      title: null,
      localuri: null,
      texto_boton: '',
      currentUserId: this.$route.query.userId,
    };
  },
  computed: {
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },

  },
  methods: {
    async writeAndPreviewPdf(base64Data, fileName) {
      await Filesystem.mkdir({ path: 'joinup', directory: Directory.Cache }).then(async () => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;
          console.log(`local uri  ${writedFile.uri}`);
        });
      }).catch(async () => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;
          console.log(`local uri  ${writedFile.uri}`);
        });
      });
    },
    async hasFilePermisions() {
      if (Capacitor.getPlatform() === 'ios') {
        return Filesystem.checkPermissions()
                .then(async (result) => {
                  if ((`${result.publicStorage}` === 'prompt') || (`${result.publicStorage}` === 'denied') || (`${result.publicStorage}` === 'prompt-with-rationale')) {
                    return Filesystem.requestPermissions()
                            .then(async (publicStorage) => {
                              const permissionsRequested = await publicStorage.publicStorage;
                              console.log('prompt || denied || prompt-with-rationale');
                              return `${permissionsRequested}` === 'granted';
                            });
                  }
                  return true;
                });
      }
      return true;
    },
    async downloadFallback(doc) {
     if (doc != null) {
       this.loading = true;
       this.pdf = null;

       await this.hasFilePermisions().then(() => {
         console.log(doc);
         console.log(encodeURI(doc));
         axios.get(encodeURI(doc), {
            responseType: 'arraybuffer',
         }).then(async (success) => {
           this.hasFilePermisions().then(async (resultData) => {
           //  if (resultData) {
               const arrayUrl = doc.split('/');
               const fileName = arrayUrl[arrayUrl.length - 1];
               const pdfData = await success.data;
               await this.writeAndPreviewPdf(pdfData, fileName.replace(' ', '-'));
               this.pdf = doc;
               await this.$router.push({
                 name: 'pdf-view',
                 params: { url: this.pdf, localuri: this.localuri },
               });
            // } 
              });
         });
       });
     }

    },

  },
  created() {
    if (!this.voucher) {
      this.redirect('comprobantes_landing', { owners_association_id: this.ownersAssociationId });
    }
  },
};
</script>

<style lang='scss'>
.page-account-view .bar-news .border-box{
  display: flex !important;
  justify-content: space-between !important;
}
.page-news-view .news-detail{
  padding-top: 0.8rem;
  padding-bottom: 0;
}
.page-account-view .bar-news .border-box{
  border-radius: 0;
}
.info-comprobante{
  margin-top: 1.5rem;
}
.title_voucher{
  font-weight: bold;
  color: rgb(206, 65, 105);
}
.voucher_details
{
  padding-left: 2em;
  text-align: right;
}

.news-body-description, .title_voucher-description
{
  text-align: left !important;
}

#footer-login-text {
  letter-spacing: .075em;
  margin-top: -3.5rem;
  font-weight: 300;
}

#footer-login-btn {
  margin-bottom: -3.5em;
}

.footer{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 30vh;
  padding: 0.5rem 1rem;
  justify-content: center;
  background-color: #081A2B;
}
.logo{
  width: 100px;
  height: auto;
}
.info-comprobante .border-box {
  text-decoration: none;
  transition: all .6s ease;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 1.5rem 1rem;
  .card-img-top {
    width: 100%;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
  }
}
</style>
