import moment from 'moment';

export default {
  methods:
    {
      obtenerTimestamp(date) {
        return date.getTime();
      },

      getFechaHoy() {
        return moment().format('YYYY-MM-DD');
      },

      dateToString(dateObject) {
        return moment(dateObject).format('YYYY-MM-DD HH:mm:ss');
      },

      getHora(date) {
        return moment(date).format('HH:mm:ss');
      },

      getFecha(dateObject) {
        return moment(dateObject).format('YYYY-MM-DD');
      },

      convertHumanFecha(fecha) {
        return moment(String(fecha)).format('DD/MM/YYYY');
      },

      convertLargeFecha(fecha) {
        moment.locale('es');
        return moment(String(fecha), 'DD-MM-YYYY').format('D MMM YYYY');
      },

      convertHumanFechaFull(fecha) {
        return moment(fecha).format('DD/MM/YYYY HH:mm');
      },

      convertFechaRelativa(fecha) {
        return moment(fecha).fromNow();
      },

    },
};
