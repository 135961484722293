<template>
  <div v-if="this.$route.name==='digital-expenses-promo'" class="fondo-gradiente" :style="getPlatform !== 'web' || (this.$route.name!=='digital-expenses' && this.$route.name!=='success-message') ? `background:  #002639;` : `background:  #002639 url('/assets/img/trama.png')  center 110% repeat-x!important;`">
    <transition name="fade" appear mode="out-in" >
      <router-view/>
    </transition>
    <div id="img_foot" class="footer-image"
         v-if="this.$route.name!=='expenses_landing'
         && this.$route.name!=='ayuda'
         && this.$route.name!=='confirmar-clave'
         && this.$route.name!=='news_landing'
         &&  this.$route.name!=='digital-expenses'
         &&  this.$route.name!=='comprobantes_landing'
         &&  this.$route.name!=='documentos_landing'
         &&  this.$route.name!=='detalle-comprobante-landing'
         && this.$route.name!=='success-message'
         && this.$route.name!=='digital-expenses-promo'
         && this.showBottomIcon
">
      <img src="@/assets/img/idata-alt.svg"  class="idata-profile" alt="idata-profile-pic" style="width:10% !important;">
      <div  v-if=" this.is_capacitor " style="color: white; margin-top:.3em;width: 100%" >
        <div style="font-size: 1em">
          Version: <small>{{version}}{{environment == "production" ? '' : 'D'}}</small>
        </div>
      </div>
    </div>
    <div id="img_foot_2" class=""
         v-if="Number(windowWidth) < 480 && this.$route.name==='digital-expenses-promo'
         && this.showBottomIcon
" style="width: 100% !important; bottom: 0px !important;max-height:100%;">
      <img src="@/assets/img/img-promo-inferior.svg"  class="" alt="idata-profile-pic" style="transform: scale(0.8);">
      <div  v-if=" this.is_capacitor " >
        <div style="font-size: 1em">
          Version: <small>{{version}}{{environment == "production" ? '' : 'D'}}</small>
        </div>
      </div>
    </div>
    <div id="img_foot_2" class="footer-image"
         v-else-if="Number(windowWidth) >= 480 && this.$route.name==='digital-expenses-promo'
         && this.showBottomIcon
" style="width: 55vw !important; bottom: 0px !important;max-height:55vw;">
      <img src="@/assets/img/img-promo-inferior.svg"  class="" alt="idata-profile-pic" style="transform: scale(0.8);">
      <div  v-if=" this.is_capacitor " >
        <div style="font-size: 1em">
          Version: <small>{{version}}{{environment == "production" ? '' : 'D'}}</small>
        </div>
      </div>
    </div>

  </div>

  <div v-else :class="this.$route.name!=='expenses_landing'
  && this.$route.name!=='news_landing'
  && this.$route.name!=='comprobantes_landing'
  && this.$route.name!=='documentos_landing'
  && this.$route.name!=='detalle-comprobante-landing'

  ?
  'fondo' :
  'sin-fondo'" :style="getPlatform !== 'web' || (this.$route.name!=='digital-expenses' && this.$route.name!=='success-message') ? `background:  #002639;` : `background:  #002639 url('/assets/img/trama.png')  center 110% repeat-x!important;`">
    <transition name="fade" appear mode="out-in" >
      <router-view/>
    </transition>
    <div id="img_foot" class="footer-image"
         v-if="this.$route.name!=='expenses_landing'
         && this.$route.name!=='ayuda'
         && this.$route.name!=='confirmar-clave'
           && this.$route.name!=='comprobantes_landing'
  && this.$route.name!=='documentos_landing'
  && this.$route.name!=='detalle-comprobante-landing'
         && this.$route.name!=='news_landing'
         &&  this.$route.name!=='digital-expenses'
         && this.$route.name!=='success-message'
         && this.$route.name!=='digital-expenses-promo'
         && this.showBottomIcon
">
      <img src="@/assets/img/idata-alt.svg"  class="idata-profile" alt="idata-profile-pic" style="width:10% !important;">
      <div  v-if=" this.is_capacitor " style="color: white; margin-top:.3em;width: 100%" >
        <div style="font-size: 1em">
          Version: <small>{{version}}{{environment == "production" ? '' : 'D'}}</small>
        </div>
      </div>
    </div>
    <div id="img_foot_2" class="footer-image" style="width: 100% !important; bottom: 0px !important; top: 55rem !important;"
         v-if="this.$route.name==='digital-expenses-promo'
         && this.showBottomIcon
">
      <img src="@/assets/img/img-promo-inferior.svg"  class="idata-profile" alt="idata-profile-pic" style="color: white; margin-top:.3em;width: 100%">
      <div  v-if=" this.is_capacitor " >
        <div style="font-size: 1em">
          Version: <small>{{version}}{{environment == "production" ? '' : 'D'}}</small>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';

export default {
  name: 'loginLayout',
  props: [],
  data() {
    return {
    version: null,
      build: null,
      showIcon: true,
      environment: process.env.NODE_ENV,
      windowWidth: window.innerWidth,
    };
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  created() {
    this.getVersion();
    Keyboard.addListener('keyboardDidShow', info => {
      console.log('keyboard did show with height:', this.showIcon);
      this.showIcon = false;
    });

    Keyboard.addListener('keyboardDidHide', () => {
      console.log('keyboard did hide', this.showIcon);

      this.showIcon = true;
    });
  },
  computed: {
    getPlatform() {
      return Capacitor.getPlatform();
    },
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
    showBottomIcon() { return this.showIcon; },
  },
  methods: {
    onResize() {
      this.windowWidth = window.windowWidth;
    },
    async getVersion() {
      this.version = await App.getInfo().then(result => result.version);
    },
     },
};
</script>
<link href="https://fonts.cdnfonts.com/css/amplesoft-pro" rel="stylesheet"/>
<style lang='scss'>
@import url('https://fonts.cdnfonts.com/css/amplesoft-pro');
.fondo {
  position: absolute;
  text-align: center;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-size:cover;
}
.sin-fondo {
  background: transparent !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0
}
#img_foot{
  @media (min-width: 576px) {
    display: none;
  }
  @media (max-width: 576px) {
    position: fixed;
    bottom: 1rem;

    z-index: 0;
  }

}

</style>
