<template web>
  <div :class="getPlatform !== 'web' ? 'owners-associations-nav mt-3' : 'owners-associations-nav'">
    <div class="container-fluid">
      <ul class="nav" v-if="owners_association.id">
        <li class="nav-item nav-item-owners-associations-overview">
          <router-link class="nav-link" :to="{ name: 'inicio', params: { owners_association_id: owners_association.id } }"><span>Panel</span></router-link>
        </li>
        <li class="nav-item nav-item-owners-associations-settlements">
          <!-- Notificar pago es una sub-sección de expensas -->
          <router-link class="nav-link" :to="{ name: 'expensas', params: { owners_association_id: owners_association.id } }" :class="{'active router-link-active': $route.name == 'notificar-pago'}">
            <span>Expensas</span>
          </router-link>
        </li>
        <li class="nav-item nav-item-owners-associations-news">
          <router-link class="nav-link" :to="{ name: 'noticias', params: { owners_association_id: owners_association.id } }">
            <span>Noticias</span>
            <span v-if="newNotification" class="badge badge-warning cnt">
              <span class="dot"></span>
            </span>
          </router-link>
        </li>
        <li class="nav-item nav-item-owners-associations-documents">
          <router-link class="nav-link" :to="{ name: 'documentos', params: { owners_association_id: owners_association.id } }"><span>Documentos</span></router-link>
        </li>

        <li class="nav-item nav-item-owners-associations-documents d-none d-md-block">
          <router-link class="nav-link" :to="{ name: 'listado-amenities', params: { owners_association_id: owners_association.id } }">
            <span>Amenities</span>
          </router-link>
        </li>
        <li class="nav-item nav-item-owners-associations-documents d-none d-md-block" v-if="owners_association.useful_telephones!=null && owners_association.useful_telephones.trim()!=''">
          <router-link  class="nav-link" :to="{ name: 'telefonos-utiles', params: { owners_association_id: owners_association.id } }">
            <span>Teléfonos Útiles</span>
          </router-link>
        </li>
        <li class="nav-item dropdown nav-item-owners-associations-documents d-block d-sm-none">
          <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            Más
          </a>
          <div class="dropdown-menu dropdown-menu-right">
            <router-link class="dropdown-item" :to="{ name: 'listado-amenities', params: { owners_association_id: owners_association.id } }">
              Amenities
            </router-link>
            <router-link v-if="owners_association.useful_telephones!=null && owners_association.useful_telephones.trim()!=''" class="dropdown-item" :to="{ name: 'telefonos-utiles', params: { owners_association_id: owners_association.id } }">
              Teléfonos Útiles
            </router-link>
          </div>
        </li>

      </ul>
    </div>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';

export default {

  computed: {
    owners_association() {
      return this.$store.getters.ownersAssociation;
    },
    newNotification() {
      return this.$store.getters.new_notification;
    },
    getPlatform() {
      return Capacitor.getPlatform();
    },
  },
};
</script>
<style>
.cnt {
  position: relative;
  top: -5px;
  right: -5px;
  height: 9px;
  width: 9px;
}
.dot {
  padding-left: 5px;
}
.show {
   display: inline !important;
    margin-bottom: 0px !important;
    margin-top: 0em !important;
    padding-bottom: 0px !important;
}
.nav {
.show {
   display: inline !important;

    margin-top: 0em !important;
}
}
</style>
