import axios from 'axios';
import store from '@/store';

let $_PDFService_http = {};

export default {

  created() {
    $_PDFService_http = axios.create();
    $_PDFService_http.interceptors.request.use((config) => {
      if (store.getters.user.token) {
        config.headers.Authorization = store.getters.user.token;
      }
      return config;
    });

    $_PDFService_http.interceptors.response.use((response) => response);
  },

  methods:
    {
      mergeDocuments(pdfs) {
        return new Promise(async (resolve, reject) => {
          $_PDFService_http.post(process.env.VUE_APP_PDF_SERVICE_URL,
            {
              pdfs_urls: pdfs,
            })
            .then((result) => {
              resolve(result.data);
            })
            .catch((e) => {
              reject(e);
            });
        });
      },
    },
};
