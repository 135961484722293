<template>
 <ForgotPassword :error_message="error_message" :loading="loading" :email_sent="email_sent" @onSend="sendResetPassword"></ForgotPassword>
</template>
<script>
import ForgotPassword from '@/components/ForgotPassword/ForgotPassword';
import ForgotPasswordSuccess from '@/components/ForgotPassword/ForgotPasswordSuccess';

export default {
  components: {
    ForgotPassword,
    ForgotPasswordSuccess,
  },
  data() {
    return {
      email_sent: false,
      error_message: null,
      loading: false,
    };
  },
 methods: {
    sendResetPassword(email) {
      this.loading = true;
      email = email.trim();
      email = email.toLowerCase();
      this.checkUserExist(email).then((response) => {
        this.loading = false;
        console.log('response:', response);
      }).catch(() => {
        this.loading = false;
      });

      this.error_message = '';
      this.sendResetRequest(email).then(() => {
        console.log('enviado');

        this.email_sent = true;
      }).catch((e) => {
        console.log(e.response.data);
        if (process.env.VUE_APP_MODE === 'web') {
          this.error_message = e.response.data['Descipción'];
        } else {
          const estado = e.response.data.Estado;
          switch (estado) {
            // case 'PASSWORD_CHANGE':
            //   login.$router.push('/confirmar-clave/username/'+err.user);
            //   break;
            // La password es incorrecta
            case 'Not Found':
              this.error_message = 'El correo electónico no se encuentra registrado en nuestra base de datos.\n\nPonte en contacto con tu administrador.';
              break;
              // Not found = no se encuentra registrado el usuario en el sistema

            default:
              this.error_message = 'Ha ocurrido un error, intente nuevamente.';
              break;
          }
        }
      });

      /* switch (UserExist) {
        case 'FORCE_CHANGE_PASSWORD':
          await this.rememberInvitation(this.email).catch((err) => {
            this.error_message('Ocurrió un error, intenta nuevamente');
          });
          this.email_sent = true;
          document.body.classList.add('black-body');
          break;
        case 'CONFIRMED':
          await this.sendResetRequest(this.email).catch((err) => {
            this.error_message('Ocurrió un error, intenta nuevamente');
          });
          this.email_sent = true;
          document.body.classList.add('black-body');
          break;
        default:
          this.error_message = 'Usuario Incorrecto.';
          document.body.classList.add('white-body');
      } */
      // e.preventDefault();
    },
  },
};

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*@import '~styles/style-one';
@import '~styles/style-two';

img, Image {
  height: 20%;
  width: 20%;
  display: block;
  margin: auto;
  margin-top: 4em;

}
*/
</style>
