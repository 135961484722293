const getters = {
  ownersAssociations: (state) => state.owners_associations,
  ownersAssociation: (state) => state.owners_association,
  notifications: (state) => state.notifications,
  new_notification: (state) => state.new_notification,
  loading: (state) => state.loading,
  loadingText: (state) => state.loadingText,
  hasMultiplesOwnersAssociations: (state) => state.hasMultiplesOwnersAssociations,
  isUserNew: (state) => state.new_user,
  user: (state) => state.user,
  logged: (state) => state.logged,
  openRequestModal: (state) => state.openRequestModal,
  messages: (state) => state.messages,
  administrationFeatures: (state) => state.administrationFeatures,
  amenity: (state) => state.amenity,
  amenities: (state) => state.amenities,
  amenitiesBookings: (state) => state.amenitiesBookings,
  booking: (state) => state.booking,
  temporalPassword: (state) => state.temporalPassword,
  email: (state) => state.email,
  activeConnection: (state) => state.activeConnection,
};

export default getters;
