<template>
  <div v-if="this.$route.name!=='digital-expenses-promo'" class="fondo">
    <div class="p-4" style="background: #002639">
      <h4 style="color: white">{{ownerAssociation.administration}}</h4>
      <div class="help-text text-left my-4 font-weight-bold" >
        <div class="mb-5">
          <span>Bienvenido:</span>
          <br>
          <span>{{ownerAssociation.functional_unit_owners}}</span>
          <br>
          <span>{{ownerAssociation.owners_association_name}}</span>
        </div>
        <div class="card mt-5" style="color: black">
          <div class="card-title text-center mt-4">
            <span>Gracias por su respuesta</span>
          </div>
          <div class="card-body text-center">
             <span>Desde la próxima liquidación recibirá la expensas digitalmente</span>
            <br>
            <br>
            <span>Muchas gracias</span>
          </div>
          <div class="card-footer text-center">
            <img src="@/assets/img/idata.svg"  class="idata-profile" alt="idata-profile-pic">
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'SuccessMessage',
  props: ['ownerAssociation'],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
  },
};
</script>

<style lang='scss'>

</style>
