<template web>
  <div>
    <header-back title="VOLVER" url="/" />
    <div class="container bar-details velmld-parent">
      <!--    <vue-element-loading :active="isLoading" color="#CE4169" spinner="spinner"/>-->
      <div class="container bar-details">
        <div class="card border-box">
          <div class="card-img-top"  :style="status === 'OK' ? 'background-color: #26b36a; text-align: center; padding: 5%' : 'background-color: rgb(255 57 57); text-align: center; padding: 5%'">
              <div v-if="status === 'OK'">
              <img  alt="" class="icon" src="@/assets/img/icons/check_circle_outline.png"
                    style="width: 64px;height: 64px;">
              <h3 class="mt-4" style="color: whitesmoke">¡Listo!</h3>
              <h5 class="mt-4" style="color: whitesmoke">Se ha enviado una notificación con el pago realizado.</h5>
            </div>
            <div v-else>
              <img  alt="" class="icon" src="@/assets/img/icons/error_circle_outline.png"
                    style="width: 64px;height: 64px;">
            </div>
          </div>

          <!--        <img :src="amenity.amenity_data.image_url" class="card-img-top">-->
          <div class="card-body">
            <div class="card-text">
              <p style="text-align: center">Información del Pago</p>
            </div>
            <div class="card-text">
              <h5 style="text-align: center">
                {{ this.message }}
              </h5>
              <!--            <h6 style="text-align: center">-->
              <!--              Subtitulo-->
              <!--            </h6>-->
            </div>
            <!--          <div class="card-text">-->
            <!--            <p style="text-align: center"> Descripción</p>-->
            <!--          </div>-->
          </div>
          <div class="card-footer mt-4" align="center">
            <img src="/assets/img/idata.svg" alt="idata" style="width: 10%">
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  components: { HeaderBack },
  props: ['status', 'message'],
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {
  },
};
</script>

<style lang='scss'>

</style>
