<template>
  <div>
  </div>
</template>

<script>
export default {
  name: 'ForgotPasswordSuccess',
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang='scss'>

</style>
