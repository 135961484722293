// import
// {
//     CognitoUserPool,
//     AuthenticationDetails,
//     CognitoUser
// }
//     from 'amazon-cognito-identity-js';
import axios from 'axios';
import store from '@/store';

// import AWS from "aws-sdk";

/* AWS.config.update({
    region: process.env.COGNITO_REGION,
    'accessKeyId': process.env.COGNITO_ACCESS,
    'secretAccessKey': process.env.COGNITO_SECRET
});

const pool = new CognitoUserPool(
    {
        UserPoolId: process.env.COGNITO_USER_POOL_ID,
        ClientId:   process.env.COGNITO_CLIENT_ID,
    }); */

const userAPI = process.env.VUE_APP_API_USERS_URL;
const coreAPI = process.env.VUE_APP_CORE_SERVICE_URL;

export default {
  methods:
      {

        getUser(token) {
          return new Promise((resolve, reject) => {
            // eslint-disable-next-line
            axios.get(coreAPI+'/me', { headers: { Authorization: token, Accept: 'application/json' } }).then((result) => {
              const json = JSON.stringify(result.data);
              localStorage.setItem('User', json);
              resolve();
            }).catch((error) => {
              reject(error);
            });
          });
        },
        login(user, pass) {
          return new Promise((resolve, reject) => {
            // eslint-disable-next-line
            axios.post(userAPI+'/login', {
              Username: user,
              Password: pass,
            }).then(async (result) => {
              localStorage.setItem('AccessToken', result.data.AuthenticationResult.AccessToken);
              localStorage.setItem('IdToken', result.data.AuthenticationResult.IdToken);
              localStorage.setItem('RefreshToken', result.data.AuthenticationResult.RefreshToken);
              store.commit('token', result.data.AuthenticationResult.AccessToken);
              store.commit('IdToken', result.data.AuthenticationResult.IdToken);
              await this.getUser(result.data.AuthenticationResult.AccessToken);
              resolve(result.data);
            }).catch((error) => {
              reject(error);
            });

            /* let authDetails = new AuthenticationDetails(
                            {
                                Username: user,
                                Password: pass
                            });

                        cognitoUser = new CognitoUser(
                            {
                                Username: user,
                                Pool: pool
                            });

                        cognitoUser.authenticateUser(authDetails,
                            {
                                onSuccess: function (result)
                                {
                                    return resolve(result)
                                },
                                onFailure: function(err)
                                {
                                    if(err.statusCode != 200)
                                    {
                                        return reject(err);
                                    }
                                    return resolve(true);
                                },
                                newPasswordRequired: function(userAttributes, requiredAttributes)
                                {
                                    //console.log(userAttributes, requiredAttributes);
                                    return reject({ code: "PASSWORD_CHANGE",
                                        user :  user
                                    });
                                }

                            }); */
          });
        },
        signOut() {
          localStorage.removeItem('AccessToken');
          localStorage.removeItem('User');

          store.dispatch('setUser');
        },
        checkUserExist(email) {
          return new Promise(async (resolve, reject) => {
            // eslint-disable-next-line
            axios.get(userAPI+'/find/'+email).then((result) => {
              resolve(result.data);
            }).catch((error) => {
              reject(error);
            });
          });
        },
        sendResetRequest(user) {
          return new Promise(async (resolve, reject) => {
            // eslint-disable-next-line
            axios.post(userAPI+'/sendResetRequest', {
              email: user,
            }, {
              headers: { Authorization: process.env.VUE_APP_AUTH_TOKEN },
            }).then((result) => {
              resolve(result.data);
            }).catch((error) => {
              reject(error);
            });
          });
        },
        rememberInvitation(email) {
          return new Promise((resolve, reject) => {
            axios.post(`${process.env.API_USERS_URL}/checkResendInvitation`,
              {
                email,
              }, {
                headers: { Authorization: process.env.AUTH_TOKEN },
              }).then((result) => {
              if ((result.data.status) === 'FOUND') {
                axios.post(`${process.env.API_USERS_URL}/rememberInvitationEmail`,
                  {
                    email,
                    url: result.data.link,
                  },
                  {
                    headers: {
                      Authorization: process.env.AUTH_TOKEN,
                    },
                  }).then(() => {
                  resolve();
                }).catch((e) => { reject(e); });
              } else {
                console.log('No se econtró el usuario');
              }
            }).catch((error) => {
              reject(error);
            });
          });
        },
        resetPassword(user) {
          return new Promise(async (resolve, reject) => {
            await axios.post(`${userAPI}/oldResetPassword`, {
              email: user,
            }, {
              headers: { Authorization: process.env.VUE_APP_AUTH_TOKEN },
            }).then((result) => {
              resolve(result.data);
            }).catch((error) => {
              reject(error);
            });
          });
        },
        refreshToken() {
          return new Promise(async (resolve, reject) => {
            await axios.post(`${userAPI}/refreshToken`, {
              token: localStorage.getItem('RefreshToken'),
              email: store.getters.user.email,
            }).then(async (result) => {
              await localStorage.setItem('AccessToken', result.data.AuthenticationResult.AccessToken);
              await localStorage.setItem('IdToken', result.data.AuthenticationResult.IdToken);
              await store.commit('token', result.data.AuthenticationResult.AccessToken);
              await store.commit('IdToken', result.data.AuthenticationResult.IdToken);
              await this.getUser(result.data.AuthenticationResult.AccessToken);
              resolve(result.data);
            }).catch((error) => {
              reject(error);
            });
          });
        },
        changePassword(oldPassword, newPassword) {
          // Aquí va la consulta al backend que todavía no está terminada
          console.log('email change pass :', store.getters.email);
          return new Promise(async (resolve, reject) => {
            try {
              let result;
              if (store.getters.email) {
                result = await axios.post(`${userAPI}/newResetPassword`, {
                  email: store.getters.email,
                  old_password: oldPassword,
                  new_password: newPassword,
                }, {
                  headers: { Authorization: process.env.VUE_APP_AUTH_TOKEN },
                }).catch((error) => {
                  reject(error);
                });
              } else {
                result = await axios.post(`${userAPI}/newResetPassword`, {
                  email: store.getters.user.email,
                  old_password: oldPassword,
                  new_password: newPassword,
                }, {
                  headers: { Authorization: store.getters.user.token },
                }).catch((error) => {
                  reject(error);
                });
              }
              localStorage.setItem('AccessToken', result.data.AuthenticationResult.AccessToken);
              localStorage.setItem('IdToken', result.data.AuthenticationResult.IdToken);
              localStorage.setItem('RefreshToken', result.data.AuthenticationResult.RefreshToken);
              store.commit('token', result.data.AuthenticationResult.AccessToken);
              store.commit('IdToken', result.data.AuthenticationResult.IdToken);
              await this.getUser(result.data.AuthenticationResult.AccessToken);
              resolve(result.data.AuthenticationResult);
            } catch (err) {
              reject(err);
            }
          });
        },
        /* confirmPassword(verificationCode, newPassword)
            {
                return new Promise((resolve, reject) =>
                {
                    cognitoUser.confirmPassword(verificationCode, newPassword,
                        {
                            onSuccess: resolve,
                            onFailure: reject,
                        });
                });

            },

            changeTemporaryPassword(newPassword)
            {
                return new Promise(function(resolve, reject)
                {
                    cognitoUser.completeNewPasswordChallenge(newPassword, [],
                        {
                            onSuccess: resolve,
                            onFailure: reject
                        });
                });
            },
            retrieveToken()
            {

                return new Promise(function(resolve, reject)
                {

                    if(!pool.getCurrentUser())
                    {
                        return reject('No current user');
                    }

                    pool.getCurrentUser().getSession(function(err, result)
                    {
                        if(err)
                        {
                            return reject(err);
                        }
                        return resolve(result.idToken.jwtToken);

                    });

                });
            },
            CognitoUserExist(email)
            {
                return new Promise(async(resolve, reject) => {
                    var cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
                    var params = {
                        "AttributesToGet": [],
                        "Filter": `email = \"${email}\"`,
                        "Limit": 1,
                        "UserPoolId": process.env.COGNITO_USER_POOL_ID
                    };
                    cognitoidentityserviceprovider.listUsers(params, (err, data) => {
                        if (err) {
                            reject(err)
                        }
                        else {
                            if (data.Users.length){
                                console.log(data.Users[0].UserStatus);
                                resolve(data.Users[0].UserStatus)
                            }else
                                resolve(false)
                        }
                    })
                });

            }, */
      },
};
