<template web>
  <pay-message :status="status" :message="message"></pay-message>
</template>

<script>
import PayMessage from '@/components/MercadoPago/PayMessage/PayMessage';

export default {
  components: {
    PayMessage,
  },
  props: ['status', 'message'],
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang='scss'>

</style>
