<template>

  <div class="">
    <header-back v-if="is_capacitor" :url="'/menu-ayuda'" title="Volver al inicio" color="transparent"/>
    <div class="wrapper_new">
      <div class="cardIMG">
  
    <div align="center" class="head_reset">
      <img alt="user-profile" src="@/assets/img/idata-copropietarios-blanco.svg">
    </div>
    <div class="contacto-admin">
      <div class="alert alert-light new_border" >
        <img src="@/assets/img/Rectangulo527.png"  style="width:60%">
                <div id="forgot-email-line"></div>
        <div id="forgot-message">
          Para ser parte de nuestra plataforma, tendrás que comunicarte con tu Administración. Ellos van a generar el acceso con tu dirección de correo electrónico.<br><br>
          Luego recibirás un mail para que coloques una contraseña y desde ese momento puedes usar todas las funciones de la app IDATA.
        </div>

        <!--<router-link  to="/ingresar" >
          <input type="button" value="IR AL INICIO" class="btn btn-rounded btn-primary btn-block" style="width:80%"/>
        </router-link>
        <br><br>-->
      </div>
      </div>
    </div>
   
  </div>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'ForgotEmail',
  props: [],
  data() {
    return {};
  },
  components: {
    HeaderBack,
  },
  computed: {
    getPlatform() {
      return Capacitor.getPlatform();
    },
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
  },
};
</script>

<style lang='scss'>
.contacto-admin{
  background: #081A2B;
  border-radius: 16px;
  box-shadow: 0px 1px 3px #00000033;
  opacity: 1;
}
.top-image {
  text-align: center;
  margin: 30px;
  width: 90%;
}
#forgot-message {
  color:black;
  text-align:left;
  margin:30px;
  font: Regular 16px/24px Roboto-Regular;
  letter-spacing: 0.15px;
  color: #404242;
  opacity: 1;
  font-size: .9em;
  h1{
    font-size: 2em !important;
  }
}
#forgot-email-line {
  background: transparent linear-gradient(180deg, #F15E3C 0%, #DD198E 100%) 0% 0% no-repeat;
  opacity: 1; padding-top:2px;margin-top: 40px;margin-bottom: 20px;
}
.cardIMG{
  @media (min-width: 576px) {
    width: 30%;
    margin: 4% auto 4% auto;
    position: relative;
  }
  @media (max-width: 576px) {
    margin: 6% auto 6% auto;
  }
}

</style>
