<template>
    <form class="p-4 text-center" v-on:submit="suscribe" style="background: #002639">
      <h4 style="color: white" v-if="ownerAssociation">{{ownerAssociation.administration}}</h4>
      <div class="help-text text-left my-4 font-weight-bold" >
        <div class="mb-4">
          <span>Bienvenido:</span>
          <br>
          <span v-if="ownerAssociation">{{ownerAssociation.functional_unit_owners}}</span>
          <br>
          <span v-if="ownerAssociation">{{ownerAssociation.owners_association_name}}</span>
        </div>
        <span>Acepto el envío de las expensas por Mail y/o Whatsapp y la cancelación del envío en formato impreso en todas mis unidades funcionales</span>
      </div>
      <label class="b-contain mb-4">
        <span>Acepto</span>
        <input type="checkbox" v-model="aceptChecked">
        <div class="b-input"></div>
      </label>
      <div v-if="aceptChecked">
          <input type="email" class="form-control" v-model="datosUser.email"
                 placeholder="Aquí escribe tu correo electrónico">
        <div class="input-group has-validation" style="margin-bottom: 1.5rem">
          <input type="email" :class="!emailIguales && datosUser.emailCheck ? 'form-control is-invalid' : 'form-control'" id="validationServer03" aria-describedby="validationServer03Feedback" v-model="datosUser.emailCheck"
                 placeholder="Repita su correo electrónico">
          <div v-if="!emailIguales && datosUser.emailCheck" id="validationServerEmailFeedback" class="invalid-feedback">
            Los emails no coinciden
          </div>
        </div>
        <input type="number" id="phone_number" v-model="datosUser.phone" class="form-control"
               placeholder="Ingrese su nro. de celular si desea recibirlo por Whatsapp">
        <div class="input-group has-validation" style="margin-bottom: 1.5rem">
          <input type="number" :class="!numerosIguales &&  datosUser.phoneCheck ? 'form-control is-invalid' : 'form-control'"
                 id="validationServer05" aria-describedby="validationServer05Feedback" v-model="datosUser.phoneCheck"
                 placeholder="Repita su nro. de celular">
          <div v-if="!numerosIguales && datosUser.phoneCheck" id="validationServerNumberFeedback" class="invalid-feedback">
            Los numeros de teléfonos no coinciden
          </div>
        </div>
        <button :disabled="loading || !numerosIguales || !emailIguales || hayDatos" type="submit" class="btn btn-rounded btn-primary btn-block mb-4" >
          <div v-if="loading" class="spinner-border spinner-border-sm ma-auto" role="status">
          </div>
          <span v-else>ACEPTAR</span>
        </button>
      </div>

    </form>
</template>

<script>
export default {
  name: 'Landing',
  props: [],
  data() {
    return {
      datosUser: {
        is_promotional: false,
        email: '',
        phone: '',
        emailCheck: '',
        phoneCheck: '',
      },
      aceptChecked: false,
      loading: false,
      ownerAssociation: null,
    };
  },
  computed: {
    settlementId() {
      return this.$route.params.settlement_id;
    },
    emailIguales() {
      console.log(`mail:${this.datosUser.email}`);
      console.log(`mailc:${this.datosUser.emailCheck}`);
      return this.datosUser.email === this.datosUser.emailCheck;
    },
    hayDatos() {
      console.log(`hay mail:${this.datosUser.email}`);
      console.log(`hay phone:${this.datosUser.phone}`);
      console.log(this.datosUser.email == '' && this.datosUser.phone == '');
      
      return this.datosUser.email == '' && this.datosUser.phone == '';
    },
    numerosIguales() {
      console.log(`phone:${this.datosUser.phone}`);
      console.log(`phonec:${this.datosUser.phoneCheck}`);
      return this.datosUser.phone === this.datosUser.phoneCheck;
    },
  },
  methods: {
    async checkQR() {
      await this.checkQRSuscriptor(this.settlementId).then((res) => {
         this.ownerAssociation = res.data;
       }).catch(() => {
        this.$router.push({ name: 'error-message' });
      });
    },
    suscribe(e) {
      e.preventDefault();
      if (this.datosUser.email == '' && this.datosUser.phone == '') {
 this.$swal.fire({
          icon: 'error',
          title: 'Lo sentimos',
          text: 'Debes indicar un correo electrónico o un numero de whatsapp',
        }); 
}

      Object.assign(this.datosUser, this.ownerAssociation);
      this.datosUser.email = this.datosUser.emailCheck;
          this.suscribeDigitalExpenses(this.datosUser).then(() => {
      this.$router.push({ name: 'success-message', params: { ownerAssociation: this.ownerAssociation } });
      }).catch(() => {
         this.$swal.fire({
           icon: 'error',
           title: 'Lo sentimos',
           text: 'Hubo un error al realizar la solicitud',
         });
       });
    },
  },
  mounted() {
    this.checkQR();
  },
};
</script>

<style lang='scss'>
.expensas-digital {
  background: #002639;
}
.b-contain *, .b-contain *::before, .b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.b-contain span {
  line-height: 1.82;
  font-size: 1.18rem;
  font-family: inherit;
}

.b-contain {
  position: relative;
  padding-left: 3.16rem;
  cursor: pointer;
  margin-bottom: 0.46rem;
}

.b-contain input[type="checkbox"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.2rem;
  width: 2.2rem;
  background: rgba(241, 245, 248, 1);
  transition: background 250ms;
  border: none;
  border-radius: 0rem;
}

.b-contain input[type="radio"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.2rem;
  width: 2.2rem;
  background: rgba(241, 245, 248, 1);
  transition: background 250ms;
  border: none;
  border-radius: 2.0rem;
}

.b-contain input[type="checkbox"] ~ .b-input::after {
  content: '';
  position: absolute;
  display: none;
  left: 0.69rem;
  top: 0.19rem;
  width: 0.63rem;
  height: 1.15rem;
  border: solid rgba(255, 255, 255, 1);
  border-width: 0 4px 4px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.b-contain input[type="radio"] ~ .b-input::after {
  content: '';
  position: absolute;
  display: none;
  left: .25rem;
  top: .25rem;
  width: .75rem;
  height: .75rem;
  border-radius: 2.0rem;
  background: rgba(255, 255, 255, 1);
  transition: background 250ms;
}

.b-contain input:disabled ~ .b-input::after {
  border-color: rgba(135, 149, 161, 1);
}

.b-contain input:checked ~ .b-input::after {
  display: block;
}

.b-contain:hover input ~ .b-input,
.b-contain input:focus ~ .b-input {
  background: rgb(231, 238, 243);
}

.b-contain input:focus ~ .b-input {
  box-shadow: 0 0 0 2px rgba(206, 65, 105, 0.6);
}

.b-contain input:checked ~ .b-input {
  background: rgba(206, 65, 105, 1);
  border-color: rgba(206, 65, 105, 1);
}

.b-contain input[type="checkbox"]:disabled ~ .b-input {
  background: rgba(241, 245, 248, 1);
  border-color: rgba(184, 194, 204, 1);
  opacity: 0.6;
  cursor: not-allowed;
}

.b-contain input[type="radio"]:disabled ~ .b-input {
  background: rgba(241, 245, 248, 1);
  border-color: rgba(184, 194, 204, 1);
  opacity: 0.6;
  cursor: not-allowed;
}

.b-contain input[type="radio"]:disabled ~ .b-input::after {
  background: rgba(135, 149, 161, 1);
}

.b-contain input:checked:focus ~ .b-input, .b-contain:hover input:not([disabled]):checked ~ .b-input {
  background: rgba(206, 65, 105, 1);
  border-color: rgba(206, 65, 105, 1);
}

.b-contain .b-input::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3rem;
  height: 3rem;
  margin-left: -0.411rem;
  margin-top: -0.411rem;
  background: rgba(206, 65, 105, 1);
  border-radius: 0.104rem;
  opacity: .6;
  z-index: 99999;
  transform: scale(0);
}

@keyframes b-ripple {
  0% {
    transform: scale(0);
  }

  20% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@keyframes b-ripple-duplicate {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(1);
  }

  60% {
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.b-contain input + .b-input::before {
  animation: b-ripple 250ms ease-out;
}

.b-contain input:checked + .b-input::before {
  animation-name: b-ripple-duplicate;
}

.b-contain .b-input::before {
  visibility: hidden;
}

.b-contain input:focus + .b-input::before {
  visibility: visible;
}

.b-contain:first-child .b-input::before {
  visibility: hidden;
}
</style>
