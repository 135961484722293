import { render, staticRenderFns } from "./LandingPromo.vue?vue&type=template&id=419a868c&"
import script from "./LandingPromo.vue?vue&type=script&lang=js&"
export * from "./LandingPromo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./LandingPromo.vue?vue&type=custom&index=0&blockType=link&href=https%3A%2F%2Ffonts.cdnfonts.com%2Fcss%2Famplesoft-pro&rel=stylesheet"
if (typeof block0 === 'function') block0(component)
import block1 from "./LandingPromo.vue?vue&type=custom&index=1&blockType=prestyle&lang=scss"
if (typeof block1 === 'function') block1(component)

export default component.exports