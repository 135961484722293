<template>
    <div class="p-4" style="background: #002639">
      <div class="help-text text-left my-4 font-weight-bold" >
        <div class="card mt-5" style="color: black">
          <div class="card-title text-center mt-4">
            <img alt="error" src="@/assets/img/error.svg" style="width: 4rem">
          </div>
          <div class="card-body text-center">
            <span>Lo sentimos</span>
            <br>
            <br>
            <span>El QR con el cuál quiere ingresar ya no es válido. Por favor ingrese con uno nuevo</span>
            <br>
            <br>
            <span>Muchas gracias</span>
          </div>
          <div class="card-footer text-center">
            <img src="@/assets/img/idata.svg"  class="idata-profile" alt="idata-profile-pic">
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'ErrorMessage',
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang='scss'>

</style>
