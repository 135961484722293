<template>
  <AccountOverview></AccountOverview>
</template>
<script>

import AccountOverview from '@/components/Account/AccountOverview/AccountOverview.vue';

export default {
  components: {
    AccountOverview,
  },
  data() {
    return {
    };
  },
};

</script>
<style scoped>

</style>
