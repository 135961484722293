import Vue from 'vue';
import _ from 'lodash';
import 'vue-select/dist/vue-select.css';
import './assets/sass/styles.scss';
import VueCurrencyFilter from 'vue-currency-filter';
import VueSweetalert2 from 'vue-sweetalert2';
import VeeValidate from 'vee-validate';
import validationMessages from 'vee-validate/dist/locale/es';
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet';
import MarqueeText from 'vue-marquee-text-component';
import PinchZoom from 'vue-pinch-zoom';
// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
import Router from 'vue-router';
import VueElementLoading from 'vue-element-loading';
import money from 'v-money';
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import store from '@/store';
import NotificationService from '@/mixins/NotificationService';
import CoreService from '@/mixins/CoreService';
import utils from '@/mixins/utils';

import '@ionic/pwa-elements';
import PDFService from '@/mixins/PDFService';
import auth from '@/mixins/auth';
import App from './App.vue';
import router from './routes/router';
import 'bootstrap/dist/js/bootstrap.bundle';
import DateFormat from '@/mixins/DateFormat';

Vue.component('pinch-zoom', PinchZoom);

// eslint-disable-next-line import/order

Object.defineProperty(Vue.prototype, '_', { value: _ });
Vue.config.productionTip = false;

Vue.use(VeeValidate,
  {
    locale: 'es',
    dictionary:
    {
      es: validationMessages,
    },
  });
defineCustomElements(window);
VeeValidate.Validator.extend('verify_password', {
  getMessage: () => 'La contraseña debe contener al menos un número y una letra.',
  validate: (value) => {
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[0-9])(?=.{8,})');
    return strongRegex.test(value);
  },
});
// register directive v-money and component <money>
Vue.use(money, { precision: 4 });

Vue.filter('truncate', (value, limit) => {
  if (value.length > limit) {
    value = `${value.substring(0, (limit - 3))}...`;
  }

  return value;
});

// Global mixins / services
Vue.mixin(CoreService);
Vue.mixin(NotificationService);
Vue.mixin(auth);
Vue.mixin(PDFService);
Vue.mixin(utils);
Vue.mixin(DateFormat);

Vue.use(VueSweetalert2);
Vue.use(Router);
Vue.use(VueBottomSheet);
const moment = require('moment');
require('moment/locale/es');

Vue.use(require('vue-moment'), {
  moment,
});

Vue.use(VueCurrencyFilter,
  {
    symbol: '$',
    thousandsSeparator: '.',
    fractionCount: 2,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: false,
  });
Vue.component('VueElementLoading', VueElementLoading);
Vue.component('datepicker', Datepicker);
Vue.component('v-select', vSelect);
Vue.component('marquee-text', MarqueeText);

// Registra una directiva personalizada global llamada `v-focus`
Vue.directive('focus', {
  // Cuando el elemento enlazado se inserta en el DOM...
  inserted(el) {
    // Enfoca el elemento
    el.focus();
  },
});

new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount('#app');

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.getElementsByTagName('INPUT');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < elements.length; i++) {
    elements[i].oninvalid = function (e) {
      e.target.setCustomValidity('');
      if (!e.target.validity.valid) {
        e.target.setCustomValidity('Completa este campo');
      }
    };
    elements[i].oninput = function (e) {
      e.target.setCustomValidity('');
    };
  }
});
