export default {
  methods:
    {
      getSeasonByDate(date) {
        // date arg must be moment date
        const current_year = date.year();
        const spring_date = this.$moment(`${current_year}0921`);
        const summer_date = this.$moment(`${current_year}1221`);
        const fall_date = this.$moment(`${current_year}0321`);
        const winter_date = this.$moment(`${current_year}0621`);

        if (date >= summer_date && date < fall_date) {
          return 'summer';
        }

        if (date >= fall_date && date < winter_date) {
          return 'fall';
        }

        if (date >= winter_date && date < spring_date) {
          return 'winter';
        }

        if (date >= spring_date && date < summer_date) {
          return 'spring';
        }

        return 'summer';
      },
      formatPrice(value) {
        if (value) {
          const val = (value / 1).toFixed(2).replace('.', ',');
          // eslint-disable-next-line
          return '$'+val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        }
        return '$0';
      },
    },
};
