<template>
  <div id="newSplash">
    <header-back v-if="is_capacitor"  color="transparent"/>

    <div class="wrapper_new">
      <div :class="getPlatform ==='web' ? 'main_login':''">

        <div id="resetLogin" >
          <div :class="getPlatform ==='web' ? 'login-form' : 'login-form-mobile'" >
            <div id="step-1" v-if="!email_sent">
              <div align="center" class="head_reset">
                <img alt="user-profile" src="@/assets/img/idata-copropietarios-blanco.svg">
              </div>
              <form method="POST" :class="getPlatform ==='web' ? '':'px-4'" :style="getPlatform !=='web' ? 'background: #002639': ''" v-on:submit="sendResetPassword">
                <div class="alert alert-info new_border" v-if="hasQueryParams === true">
                  <center class="reset_text"> {{error_message}}
                  </center>
                </div>
                <div class="alert alert-info new_border" v-else>
                  <center class="reset_text">Para restablecer tu contraseña, ingresa la dirección de
                    correo electrónico que utilizas para iniciar sesión en idata.
                  </center>
                </div>
                <div class="alert alert-danger" v-if="error_message && hasQueryParams === false">
                  <img alt="error" class="img_info" src="@/assets/img/error.svg"> {{error_message}}
                </div>
                <div class="alert alert-warning" v-else-if="hasQueryParams === true">
                  <img alt="error" class="img_info" src="@/assets/img/error.svg"> Reestableceremos la contraseña del usuario:
                </div>

                <input :v-focus="is_capacitor ? false :true" class="form-control max-margin" id="email" placeholder="Escribe el correo electrónico asociado" required="required"
                       type="email" oninvalid="this.setCustomValidity('Completa este campo')"
                       oninput="setCustomValidity('')"
                       :disabled="hasQueryParams"
                       v-model="email">
                <div class="alert alert-danger" v-if="false">
                  <img alt="error" class="img_info" src="@/assets/img/error.svg">Correo invalido.
                </div>
                <button :disabled="loading" type="submit" class="btn btn-rounded btn-primary btn-block change_password">
                  <div v-if="loading" class="spinner-border spinner-border-sm ma-auto" role="status">
                  </div>
                  <span v-else>RESTABLECER CONTRASEÑA</span>
                </button>
              </form>
            </div>
            <div id="step-2" v-if="email_sent">
              <div class="alert alert-light new_border">
                <center class="reset_text" style="width: 85%;margin: 0 auto;">
                  <div>
                    <img alt="" class="icon" src="@/assets/img/icons/check_circle_outline.svg" style="height: 5em;
width: 5em;">
                    <h1 style="color:black">¡Correo Enviado!</h1>
                    En la bandeja de entrada de <br>
                    <strong>{{email}}</strong> <br>
                    encontraras instrucciones sobre<br>
                    cómo reestablecer tu contraseña. <br><br>
                    <router-link to="/ingresar">
                      <input class="btn btn-rounded btn-primary btn-block" type="button"
                             value="IR AL INICIO"/>
                    </router-link>
                    <br><br>
                  </div>
                </center>
              </div>
            </div>
           <!-- <div v-if="getPlatform === 'web'" class="reset-volver">
                <router-link class="font-weight-bold" to="/ingresar">VOLVER AL INICIO</router-link>
            </div>-->
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'ForgotPassword',
  components: {
    HeaderBack,
  },
  props: {
    error_message: String,
    email_sent: Boolean,
    loading: Boolean,
    hasQueryParams: Boolean,
  },
  data() {
    return {
      email: null,
    };
  },
  computed: {
    getPlatform() {
      return Capacitor.getPlatform();
    },
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
  },
  created() {
    if (this.$route.query.error_message !== undefined) {
      console.log(this.$route.query.error_message);
      // eslint-disable-next-line vue/no-mutating-props
      this.error_message = this.$route.query.error_message;
      // eslint-disable-next-line vue/no-mutating-props
      this.hasQueryParams = true;
    } else {
      // eslint-disable-next-line vue/no-mutating-props
      this.hasQueryParams = false;
    }
    if (this.$route.query.email !== undefined) {
      this.email = this.$route.query.email;
    }
  },
  methods: {
    sendResetPassword(e) {
      e.preventDefault();
      this.$emit('onSend', this.email);
      /* switch (UserExist) {
        case 'FORCE_CHANGE_PASSWORD':
          await this.rememberInvitation(this.email).catch((err) => {
            this.error_message('Ocurrió un error, intenta nuevamente');
          });
          this.email_sent = true;
          document.body.classList.add('black-body');
          break;
        case 'CONFIRMED':
          await this.sendResetRequest(this.email).catch((err) => {
            this.error_message('Ocurrió un error, intenta nuevamente');
          });
          this.email_sent = true;
          document.body.classList.add('black-body');
          break;
        default:
          this.error_message = 'Usuario Incorrecto.';
          document.body.classList.add('white-body');
      } */
      // e.preventDefault();
    },
  },
};
</script>

<style lang='scss' scoped>
#newSplash{
  position: absolute;
  z-index: 1000;
  width: 100%;
}
.btn {
  display: inline-table;
  font-size: .700rem;
}
</style>
