<template web>
  <div class="page-account-view page-news-view">

    <header-back title=" VOLVER" :url= "is_capacitor ? null : '/pagos'" />

    <section class="bar theme-blue" >
      <div class="container" v-if="no_news">
        <div class="bar-avatar">
          <span><img src="@/assets/img/notificacion_de_pago_details.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">
          {{news.owners_association}}
        </h3>
        <h5 class="bar-tagline">

          {{news.payment_method}}

        </h5>
      </div>

      <div class="container" v-else>
        <div class="bar-avatar">
          <span><img src="@/assets/img/notificacion_de_pago_details.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">
          Notificación de Pago
        </h3>
        <h5 class="bar-tagline">
          Encontrada
        </h5>
        <br>
      </div>
    </section>

    <div class="container bar-edit">

      <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />
      <vue-element-loading :active="isLocalLoading" spinner="spinner" color="#CE4169" />

      <div class="card border-none shadow mb-4 mt-4" v-if="no_news">

        <div class="notification-edit">
          <label :class="news.status" style="display:grid!important;font-size: 100%;">{{getNotificationStatus(news.status)}}</label>
          <br>

          <div class="form-group">

            <div class="form-check mb-2" >

              <label class="form-check-label">
                <strong class="text-cbu">
                  {{bank.bank_name}}, {{bank.account_number}}
                </strong>
                <br>
                <span class="text-muted">
                  CBU: {{bank.account_cbu}}<br>
                  Alias: {{bank.account_alias}}
                </span>
              </label>

            </div>

          </div>

          <div class="form-group">

            <div class="form-check mb-2" >

              <label class="form-check-label">
                <strong class="text-cbu">
                  Detalle del pago:
                </strong>
              </label>

            </div>

          </div>

          <table class="table table-payment-notification" style="text-align: center;">
            <tr>
              <th>Unidad funcional</th>
              <th>Monto</th>
            </tr>
            <tr v-for="fu in news.amounts" :key="fu">
              <td>
                <label>{{fu.functional_unit}} </label></td>
              <td><label>{{fu.amount | currency}}</label>
              </td>
            </tr>

            <tr>

              <td>Total</td>
              <td>{{ news.total_amount | currency}}</td>
            </tr>
          </table>

          <div class="form-group" v-if="news.payment_number">

            <div class="form-check mb-2" >

              <label class="form-check-label">
                <strong class="text-cbu">
                  Número de comprobante: {{news.payment_number}}
                </strong>
              </label>

            </div>

          </div>

          <div class="form-group" v-if="news.payment_commentary">

            <div class="form-check mb-2" >

              <label class="form-check-label">
                <strong class="text-cbu">
                  Comentario:  </strong>{{news.payment_commentary}}

              </label>

            </div>

          </div>

          <div class="form-group" v-if="news.cancel_reason">

            <div class="form-check mb-2" >

              <label class="form-check-label">
                <strong class="text-cbu">
                  Motivo de {{
                    news.status === 'Anulado' ? 'anulación' : 'rechazo'
                  }}:  </strong><br>{{news.cancel_reason}}

              </label>

            </div>

          </div>

          <div class="news-attachments-block" v-if="news.url">
            <strong>Comprobante de Pago</strong>

            <div class="news-attachments">
          <a :href="news.url" v-if="!is_capacitor" target="_blank" class="btn btn-white">
                <img
                    src="@/assets/img/icons/document-alt.svg"
                    class="icon"
                    style="vertical-align: top;"
                    alt=""/> Descargar
              </a>
              <a v-else  @click="downloadFallback(news)" class="btn btn-white">
                <img
                    src="@/assets/img/icons/document-alt.svg"
                    class="icon"
                    style="vertical-align: top;"
                    alt=""/> Ver comprobante
              </a>

            </div>
          </div>

          <div class="form-group" v-if="news.payment_description">

            <div class="form-check mb-2" >

              <label class="form-check-label">
                <strong class="text-cbu">
                  Motivo: {{news.payment_description}}
                </strong>
              </label>

            </div>

          </div>

        </div>

      </div>

      <div  class="news-detail border-box" v-else>
        <div class="empty-state empty-state-documents"  id="no-documents-message" v-if="imgNo">
          <div class="wrapper" >
            <h4>¡Pago realizado!</h4>
            <p>Alguien asociado a tu unidad funcional ha realizado un pago. Para verlo comunícate con tu administrador.</p>
            <img src="@/assets/img/empty-states/mensaje-pago_realizado.png" class="image-empty" alt="">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Dialog } from '@capacitor/dialog';
import axios from 'axios';
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'NotificationsPaymentDetail',
  components:
      {
        HeaderBack,
      },
  data() {
    return {
      isLocalLoading: false,
    };
  },
  computed: {
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
  },
  methods: {
    extension(url) {
      return url.substring(url.lastIndexOf('.') + 1);
    },

    getNotificationStatus(status) {
      switch (status) {
        case 'Pendiente': return 'Recibido en espera de conciliación';
        case 'Aprobado': return 'Imputado en liquidación';
        default: return status;
      }
      },
    async writeAndPreviewPdf(base64Data, fileName) {
      await Filesystem.mkdir({ path: 'joinup', directory: Directory.Cache }).then(async () => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;
          console.log(`local uri  ${writedFile.uri}`);
        });
      }).catch(async () => {
        await Filesystem.writeFile({
          path: `joinup/${fileName.replace(' ', '-')}`,
          data: Buffer.from(base64Data).toString('base64'),
          directory: Directory.Cache,
        }).then(async (writedFile) => {
          this.localuri = writedFile.uri;
          console.log(`local uri  ${writedFile.uri}`);
        });
      });
    },
    async hasFilePermisions() {
      if (Capacitor.getPlatform() === 'ios') {
        return Filesystem.checkPermissions()
                .then(async (result) => {
                  if ((`${result.publicStorage}` === 'prompt') || (`${result.publicStorage}` === 'denied') || (`${result.publicStorage}` === 'prompt-with-rationale')) {
                    return Filesystem.requestPermissions()
                            .then(async (publicStorage) => {
                              const permissionsRequested = await publicStorage.publicStorage;
                              console.log('prompt || denied || prompt-with-rationale');
                              return `${permissionsRequested}` === 'granted';
                            });
                  }
                  return true;
                });
      }
      return true;
    },

    async downloadFallback(doc, type = 'pdf') {
      this.loading = true;
      this.pdf = null;
      console.log('url:', doc);
      await this.hasFilePermisions().then(() => {
        axios.get(doc.url, {
          responseType: 'arraybuffer',
        }).then(async (success) => {
          this.hasFilePermisions().then(async (resultData) => {
           // if (resultData) {
              const arrayUrl = doc.url.split('/');
              const fileName = arrayUrl[arrayUrl.length - 1];
              const pdfData = await success.data;

              await this.writeAndPreviewPdf(pdfData, fileName.replace(' ', '-'));
              this.pdf = doc.url;

              await this.$router.push({
                name: 'pdf-view',
                params: { url: doc.url, localuri: this.localuri, docType: doc.url.split('.').pop() },

              });
            // } 
            /* else {
              await Dialog.alert({
                title: 'Información',
                message: 'Denegaste los permisos de almacenamiento y esta operación no puede ser realizada',
              });
            } */
          });
        });
      });
    },

  },
  props: ['no_news', 'news', 'bank', 'news_type', 'isLoading', 'imgNo'],
};
</script>

<style lang='scss'>

</style>
