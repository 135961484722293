<template>
  <div class="page-account-view">

    <header-back title=" VOLVER" url="/cuenta"></header-back>

    <section class="bar theme-blue" >
      <div class="container">
        <div class="bar-avatar">
          <span><img src="@/assets/img/mi-cuenta.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">
          Mi perfil
        </h3>

      </div>
    </section>

    <div class="container bar-edit">

      <div class="card border-none mb-4 mt-4 card-type">
        <div class="card-body">

          <vue-element-loading :active="!this.user.email" spinner="spinner" color="#CE4169"/>

          <h5 class="title">Editar perfil</h5>

          <p class="text-table">
            Esta es la información que tienes registrada en la plataforma, por favor asegúrate de mantener tus datos de contacto al día para que las comunicaciones sean efectivas.</p>

          <form method="POST" action="" @submit="updateMe">
            <input required="required" type="text" id="first_name" v-model="newUser.first_name" class="form-control"
                   placeholder="Ingresa tu nombre">

            <input required="required" type="text" id="last_name" v-model="newUser.last_name" class="form-control"
                   placeholder="Ingresa tu apellido">

            <input type="number" id="phone" v-model="newUser.phone"  class="form-control" placeholder="Ingresa tu teléfono">

            <input disabled="disabled" type="email" v-model="newUser.email" id="email" class="form-control max-50">

            <input type="submit" value="Guardar cambios" class="btn btn-rounded btn-primary btn-block min-bottom four_px"/>

          </form>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'EditProfile',
  components: { HeaderBack },
  props: ['user'],
  data() {
    return {
      newUser: {},
    };
  },
  computed: {},
  methods: {
    updateMe(e) {
      e.preventDefault();
      this.$emit('onUpdateMe', this.newUser);
    },
  },
  mounted() {
    this.newUser = this.user;
  },
};
</script>

<style lang='scss'>

</style>
