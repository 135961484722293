<template>
  <amenities-list
      v-if="!is_capacitor"
      :amenities="amenities"
      :bookings="bookings"
      :is-loading="isLoading"
      :get-type-image="getTypeImage"
  ></amenities-list>
  <amenities-list-capacitor
      v-else
      :amenities="amenities"
      :bookings="bookings"
      :is-loading="isLoading"
      :get-type-image="getTypeImage"
  ></amenities-list-capacitor>
</template>
<script>

import { Capacitor } from '@capacitor/core';
import AmenitiesList from '@/components/OwnersAssociation/Amenities/AmenitiesList/AmenitiesList';
import BookingsList from '@/components/OwnersAssociation/Amenities/BookingsList/BookingsList';
import AmenitiesListCapacitor from '@/components/OwnersAssociation/Amenities/AmenitiesList/AmenitiesListCapacitor';

export default {
  components: { AmenitiesListCapacitor, AmenitiesList, BookingsList },
  data() {
    return {
      isLoading: true,
      selectedTab: 0,
    };
  },
  methods:
      {
        // NICO: Lo que podrías hacer es crear una funcion computada nueva igual a la de abajo pero con las imagenes como te andan a vos, asi se te hace mas fácil
        getTypeImage(type) {
          switch (type) {
            case 'Quincho/parrilla':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/quincho-parrilla.svg');
            case 'Salón de usos múltiples':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/salon-de-usos-multiples.svg');
            case 'Pileta de natación':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/pileta-de-natacion.svg');
            case 'Gimnasio':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/gimnasio.svg');
            case 'Spa/Sauna':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/spa-sauna.svg');
            case 'Playroom':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/playroom.svg');
            case 'Solarium':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/solarium.svg');
            case 'Laundry':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/laundry.svg');
            case 'Microcine':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/microcine.svg');
            case 'Minigolf':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/minigolf.svg');
            default: return 'null';
          }
        },
        getTypeImageNative(type) {
          switch (type) {
            case 'Quincho/parrilla':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/quincho-parrilla.svg';
            case 'Salón de usos múltiples':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/salon-de-usos-multiples.svg';
            case 'Pileta de natación':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/pileta-de-natacion.svg';
            case 'Gimnasio':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/gimnasio.svg';
            case 'Spa/Sauna':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/spa-sauna.svg';
            case 'Playroom':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/playroom.svg';
            case 'Solarium':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/solarium.svg';
            case 'Laundry':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/laundry.svg';
            case 'Microcine':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/microcine.svg';
            case 'Minigolf':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/minigolf.svg';
            default: return 'null';
          }
        },
        setActiveTab(tab) {
          this.selectedTab = tab;
        },
      },
  computed:
      {
        is_capacitor() {
          return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
        },
        activeTab() {
          return this.selectedTab;
        },
        amenities() {
          console.log(this.$store.getters.amenities);
          return this.$store.getters.amenities;
        },
        bookings() {
          return this.$store.getters.amenitiesBookings;
        },
        amenitiesBookings() {
          const bookings = this.$store.getters.amenitiesBookings;
          const { amenities } = this.$store.getters;

          const bookingsWithAmenities = [];
          const parent = this;
          // eslint-disable-next-line array-callback-return
          bookings.map((booking) => {
            const amenityId = JSON.parse(Object.keys(booking));
            const slotsDate = JSON.parse(JSON.stringify(booking[Object.keys(booking)[0]]));
            slotsDate.forEach((checkBelongsToUser) => {
              const datekey = (Object.keys(checkBelongsToUser));
              checkBelongsToUser[datekey.toString()].forEach((bookData) => {
                const currentKeyDate = this._.first(datekey);
                const bookingDate = parent.$moment(currentKeyDate);
                if (bookData.belongs_to_user === true && bookData.slotId) {
                  // eslint-disable-next-line consistent-return
                  amenities.forEach((amenity) => {
                    if (amenity.id === amenityId) {
                      const newObject = {
                        ...bookData,
                        image: amenity.amenity_data.image_url,
                        title: amenity.name,
                        cancel_reason: bookData.cancel_reason,
                        is_cancelled: bookData.is_cancelled,
                        is_bookable: amenity.is_bookable,
                        type: amenity.type.name,
                        date: bookingDate.format('DD-MM-Y'),
                        amenity_id: amenity.id,
                      };
                      return bookingsWithAmenities.push(newObject);
                    }
                  });
                }
              });
            });
          });
          return bookingsWithAmenities;
        },
      },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch('fetchAmenitiesBookings', this.$store.getters.ownersAssociation.id).then(() => {

      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  },

};
</script>
