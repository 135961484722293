<template>
    <div class="news-wrapper">
      <news v-if="owners_association" :owners_association="owners_association"/>
    </div>
</template>
<script>

import News from '@/components/News/News';

export default {

  components:
      {
        News,
      },
  computed:
      {
        owners_association() {
          return this.$store.getters.ownersAssociation;
        },
      },

};

</script>
