<template>
 <contact
     v-if="owners_association"
     :owners_association="owners_association"
     :messages_enabled="messages_enabled"
 ></contact>
</template>
<script>

import Contact from '@/components/OwnersAssociation/ Contact/Contact';

export default {
  components:
      {
        Contact,
      },
  computed:
      {
        owners_association() {
          return this.$store.getters.ownersAssociation;
        },
        messages_enabled() {
          return this.$store.getters.administrationFeatures.messagesEnabled;
        },
      },
};
</script>
