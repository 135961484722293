<template>
<Documents v-if="owners_association" :owners_association="owners_association"></Documents>
</template>

<script>
import Documents from '@/components/Documents/Documents';

export default {
  components:
      {
        Documents,
      },
  computed:
      {
        owners_association() {
          return this.$store.getters.ownersAssociation;
        },
      },
  created() {
  },
};
</script>
