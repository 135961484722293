<template web>
  <pay :owners_association="owners_association" :loading="loading" :total_amount="total_amount" :total_amount-show="total_amountShow" :input="input" @onPay="sendPay"  @onhandleAmount="handleAmount"></pay>
</template>

<script>
  import { Capacitor } from '@capacitor/core';
  import { InAppBrowser } from '@ionic-native/in-app-browser';
  import Pay from '@/components/MercadoPago/Pay/Pay';

  export default {
    components: {
      Pay,
    },
    props: [],
    data() {
      return {
        settlements: [],
        input:
                {
                  payment_date: '',
                  amounts: {},
                  amountsShow: {},
                },
        total_amount: 0,
        total_amountShow: 0,
        loading: false,
      };
    },
    computed: {
      owners_association() {
        return this.$store.getters.ownersAssociation;
      },
    },
    methods: {
      showSettlements() {
        // Set settlements by date
        let total = 0;
        const fu_ids = Object.keys(this.input.amountsShow);
        for (let i = 0; i < fu_ids.length; i += 1) {
          if (this.$moment.utc(this.input.payment_date).format('Y-MM-DD') <= this.$moment.utc(this.settlements[i].first_due_date).format('Y-MM-DD')) {
            total += this.settlements[i].amount_on_first_due_date;
            this.input.amountsShow[fu_ids[i]] = this.settlements[i].amount_on_first_due_date;
          } else {
            total += this.settlements[i].amount;
            this.input.amountsShow[fu_ids[i]] = this.settlements[i].amount;
          }
        }
        this.total_amountShow = total;
      },
      async setSettlements(period, owner) {
        try {
          // Set settlements
          this.settlements = await this.getSettlements(owner, period);
          let total = 0;
          const fu_ids = this.owners_association.functional_units.map((p) => p.id);
          for (let i = 0; i < fu_ids.length; i += 1) {
            total += this.settlements[i].amount;
            this.input.amountsShow[fu_ids[i]] = this.settlements[i].amount;
            this.input.amounts[fu_ids[i]] = this.settlements[i].amount;
          }
          this.total_amount = total;
          this.total_amountShow = total;
        } catch (err) {
          console.log(err);
        }
      },
      handleAmount(inputComponent) {
        let total = 0;
        this.input.amounts = inputComponent.amounts;
        const fu_ids = Object.keys(this.input.amounts);
        for (let i = 0; i < fu_ids.length; i += 1) {
          if (parseFloat(this.input.amounts[fu_ids[i]]) > 0) {
            const n = parseFloat(this.input.amounts[fu_ids[i]]);
            total += n;
          } else {
            this.input.amounts[fu_ids[i]] = 0;
          }
        }
        this.total_amount = total;
      },
      async sendPay(inputComponent) {
        this.loading = true;
        this.input = inputComponent;
        if (this.total_amount === 0) {
          this.$swal({
            icon: 'error',
            title: 'Ocurrió un error',
            text: 'Debes ingresar el pago de al menos una unidad funcional!',
          });
          this.loading = false;
        }

        const amounts = Object.keys(this.input.amounts)
                .map((amount) => ({
                  functional_unit_id: amount,
                  amount: this.input.amounts[amount],
                }));

        if (amounts.length === 0) {
          this.form.error = 'Debes ingresar el pago de al menos una unidad funcional';
          this.loading = false;
        }
        const payment_data = {
          functional_units: amounts,
          total_amount: this.total_amount,
        };
        const result = await this.initializeMercadoPagoPaymentFlow(this.owners_association.id, payment_data);
        localStorage.setItem('external_reference', result.data.external_reference);

        // Check the device with Capacitor
        const device = Capacitor.getPlatform();

        if ((Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android')) {
          console.log('opening window in android');
          // Request permission to use push notifications
          // iOS will prompt user and return if they granted permission or not
          // Android and web will just grant without prompting
          const browser = InAppBrowser.create(result.data.init_point, '_blank', 'hidden=no,location=no,clearsessioncache=yes,clearcache=yes');
          browser.on('loadstart').subscribe((e) => {
            console.log(e.url);
            if (e.url.includes('joinup')) {
              browser.close();
            }
          });
          //

          this.loading = false;
        } else {
          this.loading = false;
          window.location.href = result.data.init_point;
        }
      },
    },
    async created() {
      this.loading = true;
      if (this.owners_association.periods) {
        this.period = this.owners_association.periods[this.owners_association.periods.length - 1].id;
        this.owner = this.owners_association.id;
        await this.setSettlements(this.period, this.owner);
      }

      this.loading = false;
    },
  };
</script>

<style lang='scss'>

</style>
