<template web>
  <div id="newSplash">
    <div class="wrapper_newx">
      <div class="main_login">
        <div class="login-form">
          <div align="center" class="head_reset">
            <img alt="user-profile" src="@/assets/img/idata-copropietarios-blanco.svg">
          </div>
          <form @submit="confirm" method="POST">
            <p class="confirmPwdText">Tu correo electrónico para ingresar es:</p>
            <div class="alertEmail alert-info">
              <center class="userText">
                {{user}}
              </center>
            </div>
            <p class="confirmPwdText">Crea la contraseña que usaras para ingresar a idata</p>

            <div class="alertConditions alert-info">
              <center class="reset_text">
                Tu contraseña debe tener:<br>
                - 8 o más caracteres<br>
                - 1 número o mas
              </center>
            </div>

            <div>
              <img :src="passwordIcon" @click="changePassword" class="img_info show-pass" alt="passwordIcon">
              <input :type="passwordType"
                     class="form-control"
                     id="new_password"
                     name="new_password"
                     placeholder="Aquí ingresa la contraseña creada"
                     ref="new_password"
                     v-model="password.new"
                     v-validate="'required|min:8|verify_password'"
              />
            </div>

            <div class="alert alert-danger" v-if="error">
              <img alt="error" class="img_info" src="@/assets/img/error.svg">
              <!--              {{ errors.first('new_password').replace('new_password', '') }}-->
            </div>

            <div>
              <img :src="passwordIconConfirm" @click="changePasswordConfirm" class="img_info show-pass" alt="passwordIconConfirm">
              <input :type="passwordTypeConfirm"
                     class="form-control"
                     id="confirmation"
                     name="new_password_confirm"
                     placeholder="Aquí repite la contraseña creada"
                     required="required"
                     v-model="password.confirmation"
                     v-validate="'confirmed:new_password'"
              />
            </div>

            <div class="alert alert-danger" v-if="error">
              <img alt="error" class="img_info" src="@/assets/img/error.svg">
              <!--              {{ errors.first('new_password_confirm').replace('new_password_confirm', '') }}-->
            </div>

            <div class="alert alert-danger" v-if="error">
              {{error}}
            </div>

            <input class="btn btn-rounded btn-primary btn-block" type="submit"
                   value="CREAR CONTRASEÑA"/>
          </form>

        </div>

      </div>

    </div>

  </div>

</template>
<style lang='scss'>
.confirmar-clave
{
  background: rgb(0, 38, 57) none repeat scroll 0% 0% !important;
}
  .wrapper_newx {

  //  position: fixed;
  ///  top: 50%;
  //  left: 50%;
  //  transform: translate(-50%, -50%);
    width: 100%;
    padding-bottom: 6em;
  }
</style>
<script>

  export default {
    props: {
      user: String,
      passwordType: String,
      passwordIcon: String,
      passwordTypeConfirm: String,
      passwordIconConfirm: String,
      error: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        password:
                {
                  new: '',
                  confirmation: '',
                },
      };
    },
    methods: {
      confirm(e) {
        e.preventDefault();
        this.$emit('onConfirm', this.password);
      },
      changePassword() {
        this.$emit('onChangePassword');
      },
      changePasswordConfirm() {
        this.$emit('onChangePasswordConfirm');
      },
    },
    created() {
      console.log('props:', this.props);
    },
  };
</script>
