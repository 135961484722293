<template>
<change-password
    :password-type-confirm="passwordTypeConfirm"
    :password-icon-confirm="passwordIconConfirm"
    :password-icon="passwordIcon"
    :password-type="passwordType"
    :password-icon-old="passwordIconOld"
    :password-type-old="passwordTypeOld"
    @onhidePassword="hidePassword = !hidePassword"
    @onhidePasswordConfirm="hidePasswordConfirm = !hidePasswordConfirm"
    @onhidePasswordOld="hidePasswordOld = !hidePasswordOld"
    @onchangeUserPassword="changeUserPassword"
></change-password>
</template>
<script>

import HeaderBack from '@/components/Layouts/HeaderBack';
import ChangePassword from '@/components/Account/ChangePassword/ChangePassword';

export default {
  components:
      {
        ChangePassword,
        HeaderBack,
      },

  data() {
    return {
      hidePassword: true,
      hidePasswordConfirm: true,
      hidePasswordOld: true,
    };
  },
  computed: {
    passwordType() {
      return this.hidePassword ? 'password' : 'text';
    },
    passwordIcon() {
      // eslint-disable-next-line global-require
      return this.hidePassword ? require('@/assets/img/ojo-abierto.svg') : require('@/assets/img/ojo-cerrado.svg');
    },
    passwordTypeConfirm() {
      return this.hidePasswordConfirm ? 'password' : 'text';
    },
    passwordIconConfirm() {
      // eslint-disable-next-line global-require
      return this.hidePasswordConfirm ? require('@/assets/img/ojo-abierto.svg') : require('@/assets/img/ojo-cerrado.svg');
    },
    passwordTypeOld() {
      return this.hidePasswordOld ? 'password' : 'text';
    },
    passwordIconOld() {
      // eslint-disable-next-line global-require
      return this.hidePasswordOld ? require('@/assets/img/ojo-abierto.svg') : require('@/assets/img/ojo-cerrado.svg');
    },
  },
  methods:
      {
        changeUserPassword(password, errors) {
          if (errors.any()) {
            return;
          }
          this.changePassword(password.old, password.new)
            .then(() => {
              this.$router.push(
                {
                  name: 'cuenta',
                  params:
                          {
                            success_message: 'Tu contraseña se editó satisfactoriamente',
                          },
                },
              );
            })
            .catch((err) => {
              this.$router.push(
                {
                  name: 'cuenta',
                  params:
                          {
                            error_message: err.code === 'NotAuthorizedException'
                              ? 'La contraseña actual es incorrecta'
                              : 'Ocurrió un error al cambiar tu clave, intenta más tarde',
                          },
                },
              );
            });
        },
      },

};
</script>
