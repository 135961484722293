<template web>
  <div class="page-administrations-view">
       <header-back title=" VOLVER"   />
    <section class="administration bar theme-blue padding_top" v-if="owners_association.administration">
      <div class="container">
        <div class="administration-avatar">
          <span>{{owners_association.administration.name | to_avatar }}</span>
        </div>
        <h3 class="administration-firm-name">
          {{owners_association.administration.name}}
        </h3>
        <h5 class="administration-tagline">{{owners_association.administration.firm_name}}</h5>
      </div>
    </section>
    <div class="container administration-details">
      <div class="card shadow mb-4 mt-4">
        <div class="card-body padding_twenty" v-if="owners_association.administration">
          <p class="card-text">{{owners_association.administration.biography}}</p>
          <table class="table max-concat">
            <tr>
              <th><img src="@/assets/img/icons/md-pin.svg" class="icon icon-lg icon-md-pin" alt=""></th>
              <td class="text-table">
                {{owners_association.administration.address}},
                {{owners_association.administration.city}} ({{owners_association.administration.postal_code}}),
                {{owners_association.administration.province}}, Argentina
              </td>
            </tr>
            <tr v-if="owners_association.administration.phone_number">
              <th><img src="@/assets/img/icons/md-call.svg" class="icon icon-lg icon-md-call" alt=""></th>
              <td class="text-table">{{owners_association.administration.phone_number}}</td>
            </tr>
            <tr v-if="owners_association.administration.email">
              <th><img src="@/assets/img/icons/md-mail.svg" class="icon icon-lg icon-md-mail" alt=""></th>
              <td class="text-table">{{owners_association.administration.email}}</td>
            </tr>
            <tr v-if="owners_association.administration.office_hours">
              <th><img src="@/assets/img/icons/md-time.svg" class="icon icon-lg icon-md-time" alt=""></th>
              <td class="text-table">{{owners_association.administration.office_hours}}</td>
            </tr>
            <tr v-if="owners_association.administration.website">
              <th><img src="@/assets/img/icons/md-globe.svg" class="icon icon-lg icon-md-globe" alt=""></th>
              <td class="text-table"><a :href="owners_association.administration.website">{{owners_association.administration.website}}</a></td>
            </tr>
          </table>
          <router-link v-if="messages_enabled" :to="'/mensajes/administracion/'+owners_association.administration.id"
                       class="btn btn-contact btn-rounded btn-primary btn-block">
            Enviar mensaje
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'Contact',
  components: { HeaderBack },
  props: ['owners_association', 'messages_enabled'],
  filters:
      {
        to_avatar(string) {
          return string.substr(0, 1);
        },
      },
};
</script>

<style lang='scss'>

</style>
