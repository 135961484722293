<template>
<!--  Como se puede observar las Props se pasan con el ( : ) adelante, al parecer WebStorm no tiene en cuenta eso, si no le agregas los ( : ) solo pasa un string-->
  <Login
      :password-icon="passwordIconWeb"
      :password-type="passwordType"
      :error="error"
      :loading="loading"
      @onSwitch="hidePassword = !hidePassword"

      @onLogin="login_submit">
  </Login>
</template>
<script>

import Login from '@/components/Login/Login';

export default {
  name: 'loginView',

  components: {
    Login,
  },

  props: {
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      hidePassword: true,
      hideIcon: false,
      credentials:
          {
            user: null,
            password: null,
          },
      loading: false,
      error: this.errorMessage,
      errorPasswordCounter: 0,
      errorUserCounter: 0,

    };
  },

  computed: {
    passwordType() {
      return this.hidePassword ? 'password' : 'text';
    },
    passwordIcon() {
      // eslint-disable-next-line global-require
      return this.hidePassword ? '~/assets/img/ojo-abierto.svg' : '~/assets/img/ojo-cerrado.svg';
    },
    passwordIconWeb() {
      // eslint-disable-next-line global-require
      return this.hidePassword ? require('@/assets/img/ojo-abierto.svg') : require('@/assets/img/ojo-cerrado.svg');
    },

  },
  methods: {
    login_submit(credentials) {
      this.error = null;
      this.loading = true;
      let username = credentials.user;
      username = username.trim();
      username = username.toLowerCase();
      this.login(username, credentials.password)
        .then(async () => {
          try {
            await this.$store.dispatch('runFirstLoad');
            if (process.env.VUE_APP_MODE === 'web') {
              await this.$router.push('/');
            } else if (process.env.VUE_APP_MODE === 'native') {
              this.$navigator.navigate('/home', { clearHistory: false });
            }
            //
            this.loading = false;
          } catch (e) {
            this.loading = false;
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            if (this.errorUserCounter >= 0) {
              this.$swal({
                icon: 'warning',
                title: 'Usuario Incorrecto.',
                text: 'Estimado usuario: no tenemos registrado tu email en nuestra base de datos. Para gestionar el ingreso tienes que comunicarte con tu administración, gracias',
                confirmButtonText: 'Entendido',
              });
            }
            this.errorUserCounter += 1;
          }
          if (err.response.status === 400) {
            if (this.errorPasswordCounter > 0) {
              this.$router.push({
                name: 'resetear-clave',
                query: {
                  error_message: 'Has intentado iniciar sesión dos veces con credenciales inválidas. Si no las recuerdas, por favor cambia tu contraseña',
                  email: credentials.user,
                },
              });
            }
            this.errorPasswordCounter += 1;
          }
          this.loading = false;
          let INVALID_CREDENTIALS;
          let NO_PERMISSIONS;
          if (process.env.VUE_APP_MODE === 'web') {
            INVALID_CREDENTIALS = 'Los datos ingresados son incorrectos';
            NO_PERMISSIONS = 'No tiene permisos para ingresar al sitio';
          } else {
            INVALID_CREDENTIALS = 'Los datos ingresados son incorrectos';
            NO_PERMISSIONS = 'Los datos ingresados son incorrectos';
          }

          switch (err.response.statusText) {
            // case 'PASSWORD_CHANGE':
            //   login.$router.push('/confirmar-clave/username/'+err.user);
            //   break;
            // La password es incorrecta
            case 'Internal Server Error':
              this.error = INVALID_CREDENTIALS;
              break;
              // Not found = no se encuentra registrado el usuario en el sistema
            case 'Not Found':
              this.error = INVALID_CREDENTIALS;
              break;
            default:
              this.error = INVALID_CREDENTIALS;
              break;
          }
          return this.error;
        });
    },
    forgot_password() {

    },
  },
};

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*@import '~styles/style-one';
@import '~styles/style-two';

img, Image {
  height: 20%;
  width: 20%;
  display: block;
  margin: auto;
  margin-top: 4em;

}
*/
</style>
