<!--<template>
  <div class="page-account-view page-news-view">
    <header-back :url="'/ingresar'" title="Volver al inicio" color="transparent"/>
    <div class="container">
    <div class="wrapper_need_help">

      <div :class="getPlatform ==='web' ? 'main_login':''">

        <div :class="getPlatform ==='web' ? 'login-form' : 'login-form-mobile'"  :style="getPlatform !=='web' ? 'margin: 5% auto 4%;' : ''">
          <div class="head_reset" align="center">
            <img src="@/assets/img/idata-copropietarios-blanco.svg" alt="user-profile">
          </div>
          <form v-on:submit="enviar" :class="getPlatform ==='web' ? '':'px-4 py-2'" :style="getPlatform !=='web' ? 'background: #002639': ''">
            <p class="help-text">Envía tus datos y nos comunicaremos contigo para que puedas ingresar</p>
            <input required="required" type="text" id="first_name" v-model="user.first_name" class="form-control"
                   placeholder="Aquí escribe tu nombre">
            <input  type="text" id="last_name" v-model="user.last_name" class="form-control"
                    placeholder="Aquí escribe tu apellido">
            <input type="number" id="phone_number" v-model="user.phone" class="form-control"
                   placeholder="Aquí escribe tu número de teléfono">
            <input type="email" required="required" id="email" v-model="user.email" class="form-control"
                   placeholder="Aquí escribe tu correo electrónico">
           <input type="email" required="required" id="email" v-model="user.email" class="form-control"
                   placeholder="Aquí escribe tu correo electrónico">
            <input type="email" required="required" id="email" v-model="user.email" class="form-control"
                   placeholder="Aquí escribe tu correo electrónico">
            <input type="email" required="required" id="email" v-model="user.email" class="form-control"
                   placeholder="Aquí escribe tu correo electrónico">
            <input type="email" required="required" id="email" v-model="user.email" class="form-control"
                   placeholder="Aquí escribe tu correo electrónico">
            <input type="email" required="required" id="email" v-model="user.email" class="form-control"
                   placeholder="Aquí escribe tu correo electrónico">
            <label class="field" style="color: #272529;" >
   <textarea rows="6" cols="50" placeholder="Aquí escribe el motivo por el que no puedes ingresar." required="required" maxlength="250" class="form-control max-margin"
             id="commentary" name="comment" v-model="motivo" style="border-radius:0px!important; text-align: left;">
</textarea>
            </label>
            <button :disabled="loading" type="submit" class="btn btn-rounded btn-primary btn-block">
              <div v-if="loading" class="spinner-border spinner-border-sm ma-auto" role="status">
              </div>
              <span v-else>ENVIAR</span>
            </button>
          </form>
          <div v-if="getPlatform === 'web'"  class="reset-volver">
              <router-link class="font-weight-bold" to="/ingresar">
                VOLVER AL INICIO
              </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'NeedHelp',
  data() {
    return {
      user: {},
      motivo: '',
      loading: false,
    };
  },
  components: {
    HeaderBack,
  },
  computed: {
    getPlatform() {
      return Capacitor.getPlatform();
    },
  },
  methods: {
    async enviar(e) {
      e.preventDefault();
      this.loading = true;
      const messageObject = {
        name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone,
        email: this.user.email,
        reason: this.motivo,
      };
      await this.sendRequestApiPower(messageObject);
      this.loading = false;
      this.$swal({
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Aceptar',
        title: 'Información enviada',
        text: 'Nos comunicaremos contigo en breve!',
      }).then((result) => {
        if (result) {
          this.$router.push(
            {
              name: 'ingresar',
              params:
                      {
                        success_message: 'Nos comunicaremos en breve!',
                      },
            },
          );
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
#newSplash{
  position: absolute;
  z-index: 1000;
  width: 100%;
}

</style>
--><template>
  <div id="newSplash">
    <header-back v-if="is_capacitor" :url="'/ingresar'" color="transparent"/>

    <div class="wrapper_new">
      <div :class="getPlatform ==='web' ? 'main_login':''">

        <div id="resetLogin" >
          <div :class="getPlatform ==='web' ? 'login-form' : 'login-form-mobile'" style="min-height: 25em;">
           
              <div align="center" class="head_reset">
                <img alt="user-profile" src="@/assets/img/idata-copropietarios-blanco.svg">
              </div>
              <form method="POST" :class="getPlatform ==='web' ? '':'px-4'" :style="getPlatform !=='web' ? 'background: #002639': ''" v-on:submit="sendResetPassword">
                <div class="alert alert-info new_border" v-if="hasQueryParams === true">
                  <center class="reset_text"> {{error_message}}
                  </center>
                </div>
                <p class="help-text">¿En que podemos ayudarte?</p>
                <br>
                <router-link to="/contacto-administracion" class="btn_login">
                  Olvidé el correo electrónico
                </router-link>  <br><br>
                <router-link to="/resetear-clave" class="btn_login"> Olvidé la contraseña</router-link>
                <br><br>
                <router-link to="/como-registrarme" class="btn_login">
                  ¿Cómo puedo registrarme?
                </router-link>  <br><br>
                <router-link to="/ayuda" class="btn_login">
                  Quiero contactar al soporte técnico
                </router-link>  <br><br>
              </form>
            </div>

           <!-- <div v-if="getPlatform === 'web'" class="reset-volver">
              <router-link class="font-weight-bold" to="/ingresar">VOLVER AL INICIO</router-link>
            </div>-->
          </div>
        </div>

      </div>
    </div>

</template>

<script>
import { Capacitor } from '@capacitor/core';
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {
  name: 'ForgotPassword',
  components: {
    HeaderBack,
  },
  props: {
    error_message: String,
    email_sent: Boolean,
    loading: Boolean,
    hasQueryParams: Boolean,
  },
  data() {
    return {
      email: null,
    };
  },
  computed: {
    getPlatform() {
      return Capacitor.getPlatform();
    },
    is_capacitor() {
      return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
    },
  },
  created() {
    if (this.$route.query.error_message !== undefined) {
      console.log(this.$route.query.error_message);
      // eslint-disable-next-line vue/no-mutating-props
      this.error_message = this.$route.query.error_message;
      // eslint-disable-next-line vue/no-mutating-props
      this.hasQueryParams = true;
    } else {
      // eslint-disable-next-line vue/no-mutating-props
      this.hasQueryParams = false;
    }
    if (this.$route.query.email !== undefined) {
      this.email = this.$route.query.email;
    }
  },
  methods: {
    sendResetPassword(e) {
      e.preventDefault();
      this.$emit('onSend', this.email);
      /* switch (UserExist) {
        case 'FORCE_CHANGE_PASSWORD':
          await this.rememberInvitation(this.email).catch((err) => {
            this.error_message('Ocurrió un error, intenta nuevamente');
          });
          this.email_sent = true;
          document.body.classList.add('black-body');
          break;
        case 'CONFIRMED':
          await this.sendResetRequest(this.email).catch((err) => {
            this.error_message('Ocurrió un error, intenta nuevamente');
          });
          this.email_sent = true;
          document.body.classList.add('black-body');
          break;
        default:
          this.error_message = 'Usuario Incorrecto.';
          document.body.classList.add('white-body');
      } */
      // e.preventDefault();
    },
  },
};
</script>

<style lang='scss' scoped>
#newSplash{
  position: absolute;
  z-index: 1000;
  width: 100%;
}
.btn {
  display: inline-table;
  font-size: .700rem;
}
.btn_login
{
  color: #CE4169;
  display: inline-table;
  text-transform: uppercase;
  text-align: center;
  padding: 0.5rem 1rem;
  font-size: .8em;
  line-height: 1.5;
  border-radius: 0.4rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 2px solid #CE4169 !important;
  border-radius: 18px;
  width: 90%;
  text-decoration: none;
}
</style>
