<template>
  <notifications-payment-detail
      v-if="news"
      :img-no="imgNo"
      :is-loading="isLoading"
      :no_news="no_news"
      :news="news"
      :bank="bank"
      :news_type="news_type"
  ></notifications-payment-detail>
</template>
<script>

import NotificationsPaymentDetail from '@/components/OwnersAssociation/NotificationsPaymentDetail/NotificationsPaymentDetail';

export default {
  components: { NotificationsPaymentDetail },
  data() {
    return {
      news: {},
      bank: null,
      news_type: true,
      isLoading: true,
      imgNo: false,
    };
  },
  props: ['id'],
  created() {
    this.loadNewsInfo();
  },
  computed:
      {
        no_news() {
          return !this.isLoading && this.news_type;
        },
      },
  methods:
      {
        async loadNewsInfo() {
          try {
            let idNotification;
            if (process.env.VUE_APP_MODE === 'web') idNotification = this.$route.params.id;
            else idNotification = this.id;
            console.log('notification_id:', idNotification);
            this.news = await this.getNotificationsPaymentsDetail(idNotification);
console.log(JSON.stringify(this.news));
            console.log('notice:', this.news);
            this.bank = this.news.bank_account;
            this.news_type = true;
            this.isLoading = false;
          } catch (err) {
            this.imgNo = true;
            this.isLoading = false;
            this.news_type = false;
          }
        },
      },
};

</script>
