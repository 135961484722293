<template>
    <OwnersAssociations
        v-if="administrations"
        :has_multiples_owners_associations="has_multiples_owners_associations"
        :administrations="administrations"
        :getAvatar="getAvatar"
        :get2xAvatar="get2xAvatar"></OwnersAssociations>
</template>
<script>

import OwnersAssociations from '@/components/OwnersAssociations/OwnersAssociations';

export default {
  components: {
    OwnersAssociations,
  },
  methods:
      {
        getAvatar(avatar) {
          if (process.env.VUE_APP_MODE === 'web') {
            // eslint-disable-next-line global-require,import/no-dynamic-require
            return require(`@/assets/img/avatars/${avatar}`);
          }
          return `~/assets/img/avatars/${avatar}`;
        },
        get2xAvatar(avatar) {
          // eslint-disable-next-line global-require,import/no-dynamic-require
          return require(`@/assets/img/avatars/${this.to2x(avatar)}`);
        },
        to2x(image) {
          const image_arr = image.split('.');
          return `${image_arr[0]}@2x.png`;
        },
      },

  created() {
    this.$store.dispatch('deleteOwnersAssociation');
  },
  computed:

      {
        has_multiples_owners_associations() {
          return this.$store.getters.hasMultiplesOwnersAssociations;
        },
        administrations() {
          if (process.env.VUE_APP_MODE === 'web') {
            return this.$store.getters.ownersAssociations.reduce((r, a) => {
              r[a.name] = r[a.name] || [];
              r[a.name].push(a);

              return r;
            }, {});
          }
          return this.$store.getters.ownersAssociations;
        },

      },
};

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

img, Image {
  height: 20%;
  width: 20%;
  display: block;
  margin: auto;
  margin-top: 4em;
}
</style>
