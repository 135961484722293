<template>

  <div class="">

    <header-back v-if="is_capacitor" :url="'/menu-ayuda'" color="transparent"/>

    <div class="container" style="background: rgb(0, 38, 57) none repeat scroll 0% 0%;">
      <div :class="getPlatform ==='web' ? 'main_login':''">
       <!-- <router-link v-if="getPlatform !== 'web'" to="/ingresar" style="display: flex;justify-content: flex-start;
    align-items: center;">
          <img src="@/assets/img/icons/arrow-back-white.svg" class="icon icon-lg icon-arrow-back" alt="" style="margin: 0.5rem 0 0 1rem ;">
          <span class="font-weight-bold" style="margin-left: 25%; color: white; margin-top: 0.5rem;">Volver al inicio</span>
        </router-link>-->
        <div :class="getPlatform ==='web' ? 'login-form2' : 'login-form-mobile'"  :style="getPlatform !=='web' ? 'margin: 5% auto 4%;' : ''">
          <div class="head_reset" align="center">
            <img src="@/assets/img/idata-copropietarios-blanco.svg" alt="user-profile">
          </div>
          <form v-on:submit="enviar" :class="getPlatform ==='web' ? '':'px-4 py-2'" :style="getPlatform !=='web' ? 'background: #002639': ''">
            <p class="help-text">Envía tus datos y nos comunicaremos contigo para que puedas ingresar</p>
            <input required="required" type="text" id="first_name" v-model="user.first_name" class="form-control"
                   placeholder="Aquí escribe tu nombre">
            <input  type="text" id="last_name" v-model="user.last_name" class="form-control"
                    placeholder="Aquí escribe tu apellido">
            <input type="number" id="phone_number" v-model="user.phone" class="form-control"
                   placeholder="Aquí escribe tu número de teléfono">
            <input type="email" required="required" id="email" v-model="user.email" class="form-control"
                   placeholder="Aquí escribe tu correo electrónico">
            <label class="field" style="color: #272529;" >
   <textarea rows="6" cols="50" placeholder="Aquí escribe el motivo por el que no puedes ingresar." required="required" maxlength="250" class="form-control max-margin"
             id="commentary" name="comment" v-model="motivo" style="border-radius:0px!important; text-align: left;">
</textarea>
        </label>
        <button :disabled="loading" type="submit" class="btn btn-rounded btn-primary btn-block">
          <div v-if="loading" class="spinner-border spinner-border-sm ma-auto" role="status">
          </div>
          <span v-else>ENVIAR</span>
        </button>
      </form>

    </div>
    </div>

  </div>

  </div>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import HeaderBack from '@/components/Layouts/HeaderBack';

export default {

  components: {
   HeaderBack,
  },

  name: 'NeedHelp',
  data() {
    return {
      user: {},
      motivo: '',
      loading: false,
    };
  },
  computed: {
      getPlatform() {
        return Capacitor.getPlatform();
      },

    is_capacitor() {
      if (Capacitor.getPlatform() === 'android' || Capacitor.getPlatform() === 'ios') {
        return true;
      }
      return false;
    },

  },
  methods: {
    async enviar(e) {
      e.preventDefault();
      this.loading = true;
      const messageObject = {
        name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone,
        email: this.user.email,
        reason: this.motivo,
      };
      await this.sendRequestApiPower(messageObject);
      this.loading = false;
      this.$swal({
        icon: 'success',
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Aceptar',
        title: 'Información enviada',
        text: 'Nos comunicaremos contigo en breve!',
      }).then((result) => {
        if (result) {
          this.$router.push(
              {
                name: 'ingresar',
                params:
                    {
                      success_message: 'Nos comunicaremos en breve!',
                    },
              },
          );
        }
      });
    },
  },

};
</script>

<style lang='scss' >
.ayuda
{
  background: rgb(0, 38, 57) none repeat scroll 0% 0% !important;
}
#newSplash{
  position: absolute;
  z-index: 1000;
  width: 100%;
}

</style>
