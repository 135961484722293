<template>
  <book-amenity
      v-if="amenity && availableMonthsOptions"
      @loading="changeLoad"
      :amenity="amenity"
      :is-loading="isLoading"
      :get-type-image="getTypeImage"
      :available-months-options="availableMonthsOptions"
  ></book-amenity>
</template>
<script>
import BookAmenity from '@/components/OwnersAssociation/Amenities/BookAmenity/BookAmenity';

export default {
  components:
      {
        BookAmenity,
      },
  props: ['amenityNative'],
  data() {
    return {
      amenity: null,
    
      availableMonthsOptions: [],
      isLoading: true,
    };
  },
  methods:
      {
        getTypeImage(type) {
          switch (type) {
            case 'Quincho/parrilla':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/quincho-parrilla.svg');
            case 'Salón de usos múltiples':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/salon-de-usos-multiples.svg');
            case 'Pileta de natación':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/pileta-de-natacion.svg');
            case 'Gimnasio':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/gimnasio.svg');
            case 'Spa/Sauna':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/spa-sauna.svg');
            case 'Playroom':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/playroom.svg');
            case 'Solarium':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/solarium.svg');
            case 'Laundry':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/laundry.svg');
            case 'Microcine':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/microcine.svg');
            case 'Minigolf':
              // eslint-disable-next-line global-require
              return require('@/assets/img/amenities/minigolf.svg');
            default: return 'null';
          }
        },
        getTypeImageNative(type) {
          switch (type) {
            case 'Quincho/parrilla':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/quincho-parrilla.svg';
            case 'Salón de usos múltiples':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/salon-de-usos-multiples.svg';
            case 'Pileta de natación':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/pileta-de-natacion.svg';
            case 'Gimnasio':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/gimnasio.svg';
            case 'Spa/Sauna':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/spa-sauna.svg';
            case 'Playroom':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/playroom.svg';
            case 'Solarium':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/solarium.svg';
            case 'Laundry':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/laundry.svg';
            case 'Microcine':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/microcine.svg';
            case 'Minigolf':
              // eslint-disable-next-line global-require
              return '~/assets/img/amenities/minigolf.svg';
            default: return 'null';
          }
        },
        async calcularMesesDisponibles() {
          this.isLoading = true;
          const availableMonths = await this.getMonthsAvailable(this.amenity.id);
          this.availableMonthsOptions = availableMonths.map(
            (month, id) => {
              const newKeysObj = {
                id,
                label: this.$moment(month.complete_month_date).format('MMMM Y').toUpperCase(),
              };
              return this._.extend(month, newKeysObj);
            },
          );
          this.isLoading = false;
        },
        changeLoad(val) {
          this.isLoading = val;
        },
      },
  created() {

    if (process.env.VUE_APP_MODE === 'web') {
      this.getAmenity(this.$route.params.amenity_id)
        .then((data) => {
          this.amenity = data;
          this.calcularMesesDisponibles();
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
        });
    } else {
      this.amenity = this.amenityNative;
      this.calcularMesesDisponibles();
      this.isLoading = false;
    }
  },
};
</script>
