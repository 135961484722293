<template>

  <div id="newSplash">

    <div class="wrapper_new">

      <div class="main_login">

        <div class="login-form">

          <div class="head" align="center">
            <img src="@/assets/img/usuario.svg" alt="user-profile" class="img_login">
          </div>

          <h1>Datos del usuario</h1>
          <form  method="POST" v-on:submit="$_login_submit">
            <div class="form-group">
              <input v-autofocus id="email" required="required" v-model="credentials.user" class="form-control" type="email" placeholder="Ingresa tu correo electrónico">
            </div>
            <div class="form-group">
              <input id="password" required="required" v-model="credentials.password" class="form-control more_margin" type="password" placeholder="Ingresa tu contraseña">
            </div>

            <div class="alert alert-danger" v-if="error">
              <img src="@/assets/img/error.svg" alt="error" class="img_info"> {{error}}
            </div>

            <div class="alert alert-danger" v-if="$route.params.error_message">
              <img src="@/assets/img/error.svg" alt="error" class="img_info"> {{$route.params.error_message}}
            </div>

            <div class="alert alert-success" v-if="$route.params.success_message">
              <img src="@/assets/img/exito.svg" alt="exito" class="img_info"> {{$route.params.success_message}}
            </div>

            <input type="submit" value="ACCEDER" class="btn btn-rounded btn-primary btn-block"/>
          </form>

          <div class="copy-login">

            <p>
              <router-link to="/resetear-clave">¿Olvidaste tu contraseña?</router-link>
              <br><br>
              <router-link to="/contacto-administracion">¿Olvidaste el correo electronico asociado?</router-link>
              <br><br>
              <router-link to="/ayuda">¿Necesitas ayuda para el ingreso?</router-link>
            </p>

            <div class="footer-image">
              <img src="@/assets/img/idata-alt.svg"  class="idata-profile" alt="idata-profile-pic">
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

export default {
  name: 'loginup',
  props: ['referrer'],
  data() {
    return {
      credentials:
          {
            user: this.$route.params.username,
            password: this.$route.params.tempassword,
          },
      error: null,
    };
  },
  methods: {
    $_login_submit() {
      const login = this;
let username = this.credentials.user;
username = username.trim();
username = username.toLowerCase();

      this.login(username, this.credentials.password)
        .then(async () => {
          try {
            await this.$store.dispatch('runFirstLoad');
            await login.$router.push('/');
          } catch (e) {
            login.error = 'No tiene permisos para ingresar al sitio';
          }
        })
        .catch((err) => {
          // console.log(err);

          const INVALID_CREDENTIALS = 'Los datos ingresados son incorrectos';
          const NO_PERMISSIONS = 'No tiene permisos para ingresar al sitio';
          const regexExp = /^[a-f0-9]{32}$/gi;

          switch (err.code) {
            case 'PASSWORD_CHANGE':
              login.$router.push(`/confirmar-clave/username/${err.user}`);
              break;
            case 'NotAuthorizedException':
              if (regexExp.test(this.credentials.password)) {
                this.axios.get(`${process.env.API_USERS_URL}/find/${username}`, {
                  headers: {
                    Authorization: process.env.AUTH_TOKEN,
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                  },
                }).then((result) => {
                  if (result.data.status === 'OK') {
                    this.axios.get(`${process.env.API_USERS_URL}/find/temporary_password/${username}`, {
                      headers: {
                        Authorization: process.env.AUTH_TOKEN,
                        'Content-type': 'application/json',
                        Accept: 'application/json',
                      },
                    }).then((success) => {
                      window.location = `${window.location.hostname}/ingreso_temporal/username/${username}/tempassword/${success.data.temporary_password}`;
                    });
                  }
                });
                break;
              }
              login.error = INVALID_CREDENTIALS;
              break;
            case 'UserNotFoundException':
              login.error = INVALID_CREDENTIALS;
              break;
            default:
              login.error = NO_PERMISSIONS;
              break;
          }
        });
    },
  },
  mounted() {
    // invocar los métodos
    this.$_login_submit();
  },
};
</script>
<style lang="scss" scoped>
#newSplash {
  height: 100vh;
  width: 100vw;
  background-size:cover;
  background:  #002639 url('../assets/img/trama.png')  center 110% repeat-x!important;
  background-size: 100% 100%;
}
</style>
