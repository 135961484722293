<template>
<div></div>
</template>

<script>
export default {
  name: 'buttomMenu',
  props: [],
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang='scss'>

</style>
