import Router from 'vue-router';
import axios from 'axios';
import ForgotEmail from '@/components/ForgotEmail/ForgotEmail';
import HowToRegister from '@/components/NeedHelp/HowToRegister';
import NeedHelp from '@/components/NeedHelp/NeedHelp';
import MenuHelp from '@/components/NeedHelp/MenuHelp';
import store from '@/store';

// General
import Login from '@/views/Login';
import ConfirmPassword from '@/views/ConfirmPassword';
import AccountOverview from '@/views/Account/AccountOverview';
import ForgotPassword from '@/views/ForgotPassword';
import Home from '@/views/OwnersAssociation/Home';
import OwnersAssociations from '@/views/OwnersAssociations';
import Settlements from '@/views/OwnersAssociation/Settlements';
import PaymentNotification from '@/views/OwnersAssociation/PaymentNotification';
import News from '@/views/OwnersAssociation/News';
import NewsDetail from '@/views/OwnersAssociation/NewsDetail';
import Documents from '@/views/OwnersAssociation/Documents';
import EditProfile from '@/views/Account/EditProfile';
import ChangePassword from '@/views/Account/ChangePassword';
import NotificationsPaymentList from '@/views/OwnersAssociation/NotificationsPayment/NotificationsPaymentList';
import NotificationsPaymentDetail from '@/views/OwnersAssociation/NotificationsPayment/NotificationsPaymentDetail';
import Contact from '@/views/OwnersAssociation/Contact';
import AdministrationChat from '@/views/OwnersAssociation/Chats/AdministrationChat';
import Notifications from '@/views/Notifications';
import AmenitiesList from '@/views/OwnersAssociation/Amenities/AmenitiesList';
import Telephones from '@/views/OwnersAssociation/Telephones';
import AmenityDetail from '@/views/OwnersAssociation/Amenities/AmenityDetail';
import BookAmenity from '@/views/OwnersAssociation/Amenities/BookAmenity';
import BookingsList from '@/views/OwnersAssociation/Amenities/BookingsList';
import BookingDetail from '@/views/OwnersAssociation/Amenities/BookingDetail';
import SuccessPay from '@/views/MercadoPago/PayMessage';
import Pay from '@/views/MercadoPago/Pay';
import PayExpensasPagas from '@/views/ExpensasPagas/Pay';

import auth from '@/mixins/auth';
import LoginUp from '@/views/LoginUp';
import NotFound from '@/views/NotFound';
import NoConnection from '@/views/NoConnection';
import GettingStarted from '@/views/GettingStarted';
import ExpensesLanding from '@/views/ExpensesLanding';
import NewsLanding from '@/views/NewsLanding';
import Vouchers from '@/views/OwnersAssociation/Vouchers';
import PaymentSuccessful from '@/views/OwnersAssociation/PaymentSuccesful';
import PaymentError from '@/views/OwnersAssociation/PaymentError';
import VouchersLanding from '@/views/OwnersAssociation/VouchersLanding/VouchersLanding';
import DocumentsLanding from '@/views/OwnersAssociation/DocumentsLanding/DocumentsLanding';
import VoucherDetail from '@/views/OwnersAssociation/VoucherDetail';
import VoucherDetailLanding from '@/views/OwnersAssociation/VouchersLanding/VoucherDetailLanding';
import Landing from '@/views/DigitalExpenses/Landing';
import LandingPromo from '@/views/DigitalExpenses/LandingPromo';

import SuccessMessage from '@/views/DigitalExpenses/SuccessMessage';
import ErrorMessage from '@/views/DigitalExpenses/ErrorMessage';
import AmenityCard from '@/views/OwnersAssociation/Amenities/AmenityCard';
import PDFview from '@/views/PDFview';

// eslint-disable-next-line consistent-return
const AuthGuard = (to, from, next) => {
  if (!store.getters.logged) {
    const query = (to.path !== '/') ? { redirect_to: to.path } : null;
    return next({ path: '/ingresar', query });
  }
  next();
};
const resetRouteScroll = () => ({ x: 0, y: 0 });

const temporalLogin = async (to, from, next) => {
  console.log('entra');
  const passTemp = to.params.tempassword;
  const email = to.params.username;
  await auth.methods.resetPassword(to.params.username.toLowerCase());
  auth.methods.login(to.params.username.toLowerCase(), to.params.tempassword)
    .then(async () => {
      await store.dispatch('runFirstLoad');
      next('/');
    }).catch((err) => {
      const INVALID_CREDENTIALS = 'Los datos ingresados son incorrectos';
      const NO_PERMISSIONS = 'No tiene permisos para ingresar al sitio';
      const regexExp = /^[a-f0-9]{32}$/gi;
      switch (err.response.data.code) {
        case 'FORCE_CHANGE_PASSWORD':
          store.dispatch('setTemporalPassword', passTemp);
          store.dispatch('setEmail', email);
          next({
            name: 'confirmar-clave',
            params: {
              username: err.response.data.user,
            },
          });
          next();
          break;
        case 'NotAuthorizedException':
          // TODO: Eliminar esta sección se agrega únicamente para fixear envíos de reinvitaciones sin nuevo seteo de password
          if (regexExp.test(to.params.tempassword)) {
            axios.get(`${process.env.VUE_APP_API_USERS_URL}/find/${to.params.username}`, {
              headers: {
                Authorization: process.env.VUE_APP_AUTH_TOKEN,
                'Content-type': 'application/json',
                Accept: 'application/json',
              },
            }).then((result) => {
              if (result.data.status === 'OK') {
                axios.get(`${process.env.VUE_APP_API_USERS_URL}/find/temporary_password/${to.params.username}`, {
                  headers: {
                    Authorization: process.env.VUE_APP_AUTH_TOKEN,
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                  },
                }).then((success) => this.router.push({
                  name: 'ingreso_temporal',
                  params:
                        {
                          username: to.params.username,
                          tempassword: success.data.temporary_password,
                        },
                }));
              }
            });
            break;
          }
          next({
            name: 'ingresar',
            params: {
              errorMessage: INVALID_CREDENTIALS,
            },
          });
          break;
        case 'UserNotFoundException':
          next({
            name: 'ingresar',
            params: {
              errorMessage: INVALID_CREDENTIALS,
            },
          });
          break;
        default:
          next({
            name: 'ingresar',
            params: {
              errorMessage: NO_PERMISSIONS,
            },
          });
          break;
      }
    });
};

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'history',
  scrollBehavior: resetRouteScroll,
  routes: [
    {
      name: 'consorcios',
      path: '/',
      meta:
        {
          title: 'Consorcios',
          check_new: true,
          tag: 'Consorcios',
          validate_onboarding: true,
        },
      component: OwnersAssociations,
      beforeEnter: (to, from, next) => {
        if (!store.getters.logged) {
          const query = (to.path !== '/') ? { redirect_to: to.path } : null;
          next({ path: '/ingresar', query });
        }
        const { hasMultiplesOwnersAssociations } = store.getters;

        if (!hasMultiplesOwnersAssociations && store.getters.ownersAssociations[0]) {
          next({ name: 'inicio', params: { owners_association_id: store.getters.ownersAssociations[0].id } });
        }
        next();
      },
    },
    {
      name: 'inicio',
      path: '/consorcios/:owners_association_id',
      meta:
        {
          title: 'Inicio',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      component: Home,
      beforeEnter: AuthGuard,
    },
    {
      name: 'confirmar-clave',
      meta:
        {
          title: 'Confirmar clave',
        },
      path: '/confirmar-clave/username/:username',
      component: ConfirmPassword,
    },
    {
      name: 'cuenta',
      meta:
        {
          title: 'Cuenta',
          tag: 'Cuenta',
        },
      path: '/cuenta',
      component: AccountOverview,
      props: true,
      beforeEnter: AuthGuard,
    },
    {
      name: 'editar-perfil',
      meta:
        {
          title: 'Editar perfil',
          tag: 'Cuenta',
        },
      path: '/cuenta/editar-perfil',
      component: EditProfile,
      beforeEnter: AuthGuard,
    },
    {
      name: 'cambiar-clave',
      meta:
        {
          title: 'Cambiar clave',
          tag: 'Cuenta',
        },
      path: '/cuenta/cambiar-clave',
      component: ChangePassword,
      beforeEnter: AuthGuard,
    },
    {
      name: 'ingresar',
      meta:
        {
          title: 'Ingresar',
        },
      path: '/ingresar',
      component: Login,
      props: true,
    },
    {
      name: 'empezar',
      meta:
        {
          title: 'Empezar',
        },
      path: '/empezar',
      component: GettingStarted,
      beforeEnter: AuthGuard,
    },
    {
      name: 'expensas',
      meta:
        {
          title: 'Expensas',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/consorcios/:owners_association_id/expensas',
      component: Settlements,
      beforeEnter: AuthGuard,
    },
    {
      name: 'contacto',
      meta:
        {
          title: 'Contacto',
          tag: 'Consorcios',
          validate_onboarding: true,
        },
      path: '/consorcios/:owners_association_id/contacto',
      component: Contact,
      beforeEnter: AuthGuard,
    },
    {
      name: 'detalle-noticia',
      meta:
        {
          title: 'Noticia',
          tag: 'Consorcios',
          validate_onboarding: true,
        },
      path: '/consorcios/:owners_association_id/noticias/:id',
      component: NewsDetail,
      beforeEnter: AuthGuard,
    },
    {
      name: 'documentos',
      meta:
        {
          title: 'Documentos',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/consorcios/:owners_association_id/documentos',
      component: Documents,
      // beforeEnter: AuthGuard,
    },
    {
      name: 'noticias',
      meta:
        {
          title: 'Noticias',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/consorcios/:owners_association_id/noticias',
      component: News,
      beforeEnter: AuthGuard,
    },
    {
      name: 'pago-message',
      meta:
        {
          title: 'Información de pago',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/mercadopago/info',
      props: true,
      component: SuccessPay,
      beforeEnter: AuthGuard,
    },
    {
      name: 'pagar',
      meta:
        {
          title: 'Pagar',
          tag: 'Consorcios',
          validate_onboarding: true,
        },
      path: '/consorcios/:owners_association_id/pagar',
      component: Pay,
      beforeEnter: AuthGuard,
    },
      {
          name: 'pagarexpensaspagas',
          meta:
              {
                  title: 'Pagar con ExpensasPagas',
                  tag: 'Consorcios',
                  validate_onboarding: true,
              },
          path: '/consorcios/:owners_association_id/pagarexpensaspagas',
          component: PayExpensasPagas,
          beforeEnter: AuthGuard,
      },
    {
      name: 'notificar-pago',
      meta:
        {
          title: 'Notificar pago',
          tag: 'Consorcios',
          validate_onboarding: true,
        },
      path: '/consorcios/:owners_association_id/expensas/notificar-pago',
      component: PaymentNotification,
      beforeEnter: AuthGuard,
    },
    {
      name: 'listado-pagos',
      meta:
        {
          title: 'Notificaciones de Pago',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/pagos',
      component: NotificationsPaymentList,
      beforeEnter: AuthGuard,
    },
    {
      name: 'detalle-pago',
      meta:
        {
          title: 'Detalle Pago',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/pagos/:id',
      component: NotificationsPaymentDetail,
      beforeEnter: AuthGuard,
    },
    {
      name: 'listado-amenities',
      meta:
        {
          title: 'Amenities',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/consorcios/:owners_association_id/amenities',
      component: AmenitiesList,
      tag: 'Consorcios',
      beforeEnter: AuthGuard,
    },
    {
      name: 'telefonos-utiles',
      meta:
          {
            title: 'Telefonos Utiles',
            validate_onboarding: true,
            tag: 'Consorcios',
          },
      path: '/consorcios/:owners_association_id/telefonos-utiles',
      component: Telephones,
      tag: 'Consorcios',
      beforeEnter: AuthGuard,
    },
    {
      name: 'card-amenity',
      meta:
        {
          title: 'Amenities',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/consorcios/:owners_association_id/amenities/:amenity_id',
      component: AmenityCard,
      beforeEnter: AuthGuard,
    },
    {
      name: 'detalle-amenity',
      meta:
        {
          title: 'Detalle amenity',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/consorcios/:owners_association_id/amenities/:amenity_id',
      component: AmenityDetail,
      beforeEnter: AuthGuard,
    },
    {
      name: 'reservar-amenity',
      meta:
        {
          title: 'Reservar amenity',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/consorcios/:owners_association_id/amenities/:amenity_id/reservar',
      component: BookAmenity,
      beforeEnter: AuthGuard,
    },
    {
      name: 'listado-reservas',
      meta:
        {
          title: 'Reservas',
          tag: 'Consorcios',
          validate_onboarding: true,
        },
      path: '/consorcios/:owners_association_id/bookings',
      component: BookingsList,
      beforeEnter: AuthGuard,
    },
    {
      name: 'detalle-reserva',
      meta:
        {
          title: 'Detalle reserva',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/consorcios/:owners_association_id/bookings/detalle',
      component: BookingDetail,
      beforeEnter: AuthGuard,
    },
    {
      name: 'comprobantes',
      meta:
        {
          title: 'Comprobantes',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/consorcios/:owners_association_id/comprobantes',
      component: Vouchers,
     beforeEnter: AuthGuard,
    },

      {
          name: 'comprobantes_landing',
          meta:
              {
                  title: 'Comprobantes',
                  validate_onboarding: true,
                  tag: 'Consorcios',
              },
          path: '/consorcios_landing/comprobantes',
          component: VouchersLanding,

      },

      {
          name: 'documentos_landing',
          meta:
              {
                  title: 'Documentos',
                  validate_onboarding: true,
                  tag: 'Consorcios',
              },
          path: '/consorcios_landing/documentos',
          component: DocumentsLanding,

      },
    {
      name: 'detalle-comprobante',
      meta:
        {
          title: 'Detalle comprobante',
          validate_onboarding: true,
          tag: 'Consorcios',
        },
      path: '/consorcios/:owners_association_id/comprobantes/:id',
      component: VoucherDetail,
      props: true,
      beforeEnter: AuthGuard,
    },
      {
          name: 'detalle-comprobante-landing',
          meta:
              {
                  title: 'Detalle comprobante',
                  validate_onboarding: true,
                  tag: 'Consorcios',
              },
          path: '/consorcios_landing/comprobantes/:id',
          component: VoucherDetailLanding,
          props: true,
          // beforeEnter: AuthGuard,
      },
    {
      name: 'resetear-clave',
      meta:
        {
          title: 'Resetear clave',
        },
      path: '/resetear-clave',
      component: ForgotPassword,
      props: true,
    },
    {
      name: 'contacto-administracion',
      meta:
        {
          title: 'Contacto Administración',
        },
      path: '/contacto-administracion',
      component: ForgotEmail,
      props: true,
    },
    {
      name: 'como-registrarme',
      meta:
          {
            title: 'Como registrarme',
          },
      path: '/como-registrarme',
      component: HowToRegister,
      props: true,
    },
    {
      name: 'ayuda',
      meta:
        {
          title: 'Ayuda',
        },
      path: '/ayuda',
      component: NeedHelp,
      props: true,
    },
      {
      name: 'menu-ayuda',
      meta:
        {
          title: 'Ayuda',
        },
      path: '/menu-ayuda',
      component: MenuHelp,
      props: true,
    },
      {
          name: 'payment-success',
          meta:
              {
                  title: 'Pago exitoso',

              },
          path: '/exito',
          component: PaymentSuccessful,
          props: true,
      },
      {
          name: 'payment-error',
          meta:
              {
                  title: 'Pago fallo',

              },
          path: '/error',
          component: PaymentError,
          props: true,
      },

    {
      name: 'notificaciones',
      meta:
        {
          title: 'Notificaciones',
          tag: 'Notificaciones',
        },
      path: '/notificaciones',
      component: Notifications,
      beforeEnter: AuthGuard,
    },
    {
      name: 'mensajes-administracion',
      meta:
        {
          title: 'Mensajes con administración',
          bodyClass: 'page-chat',
          tag: 'Mensajes',

        },
      path: '/mensajes/administracion/:administration_id',
      component: AdministrationChat,
      beforeEnter: AuthGuard,
    },
    {
      name: 'ingreso_temporal',
      meta:
        {
          title: 'Ingreso Temporal',
          tag: 'Mensajes',
        },
      path: '/ingreso_temporal/username/:username/tempassword/:tempassword',
      component: LoginUp,
      props: true,
      beforeEnter: temporalLogin,
    },
    {
      name: 'reset_temporal',
      meta:
        {
          title: 'Reset Temporal',
        },
      path: '/reset_temporal/username/:username/tempassword/:tempassword',
      component: LoginUp,
      props: true,
      beforeEnter: temporalLogin,
    },
    {
      name: 'expenses_landing',
      meta:
        {
          title: 'Tus Expensas',
        },
      path: '/expenses/id/:id/ownersAssociation/:ownersAssociation',
      component: ExpensesLanding,
      props: true,
    },
    {
      name: 'news_landing',
      meta:
        {
          title: 'Tu Noticia',
        },
      path: '/noticias/:id/',
      component: NewsLanding,
      props: true,
    },
    {
      name: 'digital-expenses',
      meta:
        {
          title: 'Expensas Digital',
        },
      path: '/digital-expenses/:settlement_id',
      component: Landing,
      props: true,
    },
    {
      name: 'digital-expenses-promo',
      meta:
        {
          title: 'Expensas Digital Digitalizate',
        },
      path: '/digital-expenses/:settlement_id/:email',
      component: LandingPromo,
      props: true,
    },
    {
      name: 'pdf-view',
      meta:
        {
          title: 'I-Data',
        },
      path: '/pdf-view',
      component: PDFview,
      props: true,
    },
    {
      name: 'success-message',
      meta:
        {
          title: 'Expensas Digital',
        },
      path: '/digital-expenses-success',
      component: SuccessMessage,
      props: true,
    },
    {
      name: 'error-message',
      meta:
        {
          title: 'Expensas Digital',
        },
      path: '/digital-expenses-error',
      component: ErrorMessage,
      props: true,
    },
    {
      name: 'sin-conexion',
      meta:
          {
            title: 'Sin Conexión',
          },
      path: '/sin-conexion',
      component: NoConnection,
    },
    {
      name: '404',

      meta:
        {
          title: 'No encontrado',
        },
      path: '/404',
      component: NotFound,
    },
  ],
});

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  // Handling 404 errors
  if (to.matched[0] && to.matched[0].path === '*') {
    return next('/404');
  }

  next();
});

router.afterEach((to) => {
  // Set title
  document.title = `${to.meta.title} – idata`;
  // Set classes
  let className = to.meta.bodyClass || to.meta.title;
  className = className.replace(' ', '-').toLowerCase();

  document.getElementsByTagName('body')[0].className = className;
  document.getElementsByTagName('html')[0].className = className;

  if (to.params.owners_association_id) {
    store.dispatch('setOwnersAssociation', to.params.owners_association_id);
  }
});

export default router;
