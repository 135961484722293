<template>

  <div class="page-account-view page-notifications">

    <section class="bar theme-blue" style="padding-bottom: 1rem !important">
      <div class="container">
        <div class="bar-avatar">
          <span><img src="@/assets/img/notificaciones.svg" class="img_min"></span>
        </div>
        <h3 class="bar-firm-name">
          Notificaciones
        </h3>
        <br>
      </div>
    </section>
    <div
        style="margin: 10%">
      <button
          v-if="!!notifications.length"
          id="mark-as-seen-button"
          type="button"
          @click="setAsSeen"
          class="btn btn-primary btn-rounded">
        MARCAR TODAS COMO LEÍDAS
      </button>
    </div>

    <div class="notifications-index">
      <vue-element-loading :active="isLoading" spinner="spinner" color="#CE4169" />

      <div class="container bar-notifications">

        <div class="empty-state empty-state-notifications" v-if="!notifications.length">
          <div class="wrapper">
            <h4>¡Aún no hay notificaciones!</h4>
            <p>No hay novedades en tu cuenta y tus unidades funcionales.</p>
            <img src="@/assets/img/empty-states/no-hay-notificaciones.png" class="image-empty" alt="">
          </div>
        </div>

        <div
            v-for="notification in notifications"
            class="item "
            :key="notification.id"
        >

          <div class="image">
            <div>
              <img src="@/assets/img/icono-notificacion-62.svg" class="notification-icon" alt="">
            </div>
          </div>

          <div class="details">

            <div  v-bind:class="{'notification-unread' : notification.seen }">

              <div class="date_shadow">
                {{notification.created_at | moment('ll')}} <br>
              </div>

              <div     v-if="notification.document_type==='16'"  class="link_notifications" 
                       @click="setAsSeenNotificationAndRedirectBooking(notification.news,notification.owners_association)">
                <h1 class="newTitle">{{notification.title}}</h1>
                <div class="notification-date" v-if="notification.created_at">
                  {{notification.created_at | moment('lll')}}
                </div>
                <h1 class="subTitle">{{notification.body.split('-')[0]}}</h1>
                <p>{{notification.body.split('-')[1]}}</p>
              </div>
          
              <router-link
                  v-else-if="notification.document_type==='3'"
                  :key="notification.news"
                  v-on:click.native="setAsSeenNotification(notification.id)"
                  :to="{ name: 'detalle-pago', params: { id: notification.news, owners_association_id: notification.owners_association } }"
                  class="link_notifications"
              >
                <h1 class="newTitle">{{notification.title}}</h1>
                <div class="notification-date" v-if="notification.created_at">
                  {{notification.created_at | moment('lll')}}
                </div>
                <h1 class="subTitle">{{notification.body.split('-')[0]}} </h1>
                <p>{{notification.body.split('-')[1]}}</p>

              </router-link>
              <div v-else-if="notification.document_type==='2' && liquidacionExpensa(notification)"  class="link_notifications" @click="setAsSeenNotificationAndRedirect(notification)">
                <h1 class="newTitle">{{notification.title}}</h1>
                <div class="notification-date" v-if="notification.created_at">
                  {{notification.created_at | moment('lll')}}
                </div>
                <h1 class="subTitle">{{notification.body.split('-')[0]}}</h1>
                <p>{{notification.body.split('-')[1]}}</p>
              </div>
              <div v-else-if="notification.document_type==='2' &&!is_capacitor && nuevoDocumento(notification) && !liquidacionExpensa(notification)"  class="link_notifications" @click="openDocumentWindow(notification.url)">
                <h1 class="newTitle">{{notification.title}}</h1>
                <div class="notification-date" v-if="notification.created_at">
                  {{notification.created_at | moment('lll')}}
                </div>
                <h1 class="subTitle">{{notification.body.split('-')[0]}}</h1>
                <p>{{notification.body.split('-')[1]}}</p>
              </div>
              <div v-else-if="notification.document_type==='2' &&is_capacitor && nuevoDocumento(notification) && !liquidacionExpensa(notification)"  class="link_notifications" @click="downloadFallback(notification)">
                <h1 class="newTitle">{{notification.title}}</h1>
                <div class="notification-date" v-if="notification.created_at">
                  {{notification.created_at | moment('lll')}}
                </div>
                <h1 class="subTitle">{{notification.body.split('-')[0]}}</h1>
                <p>{{notification.body.split('-')[1]}}</p>
              </div>
              <router-link
                  v-else
                  :key="notification.news"
                  v-on:click.native="setAsSeenNotification(notification.id)"
                  :to="{ name: 'detalle-noticia', params: { id: notification.news, owners_association_id: notification.owners_association } }"
                  class="link_notifications"
              >
                <h1 class="newTitle">{{notification.title}}</h1>
                <div class="notification-date" v-if="notification.created_at">
                  {{notification.created_at | moment('lll')}}
                </div>
                <h1 class="subTitle">{{notification.body.split('-')[0]}} </h1>
                <p>{{notification.body.split('-')[1]}}</p>
              </router-link>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>
</template>
<script>

  import { Directory, Filesystem } from '@capacitor/filesystem';
  import { Capacitor } from '@capacitor/core';
  import axios from 'axios';
  import { Dialog } from '@capacitor/dialog';
  import HeaderBack from '@/components/Layouts/HeaderBack';

  export default {
    components:
            {
              HeaderBack,
            },
    data() {
      return {
        allSeen: true,
        isLoading: false,
      };
    },
    computed:
            {

              notifications() {
                return this.$store.getters.notifications;
              },
              is_capacitor() {
                return (Capacitor.getPlatform() === 'ios') || (Capacitor.getPlatform() === 'android');
              },

            },
    watch:
            {
              notifications() {
                this.allSeen = this.notifications.filter((n) => !n.seen).length === 0;
              },
            },
    methods:
            {
              async writeAndPreviewPdf(base64Data, fileName) {
                await Filesystem.mkdir({ path: 'joinup', directory: Directory.Cache }).then(async () => {
                  await Filesystem.writeFile({
                    path: `joinup/${fileName.replace(' ', '-')}`,
                    data: Buffer.from(base64Data).toString('base64'),
                    directory: Directory.Cache,
                  }).then(async (writedFile) => {
                    this.localuri = writedFile.uri;
                    console.log(`local uri  ${writedFile.uri}`);
                  });
                }).catch(async () => {
                  await Filesystem.writeFile({
                    path: `joinup/${fileName.replace(' ', '-')}`,
                    data: Buffer.from(base64Data).toString('base64'),
                    directory: Directory.Cache,
                  }).then(async (writedFile) => {
                    this.localuri = writedFile.uri;
                    console.log(`local uri  ${writedFile.uri}`);
                  });
                });
              },
              async hasFilePermisions() {
                if (Capacitor.getPlatform() === 'ios') {
                  return Filesystem.checkPermissions()
                          .then(async (result) => {
                            if ((`${result.publicStorage}` === 'prompt') || (`${result.publicStorage}` === 'denied') || (`${result.publicStorage}` === 'prompt-with-rationale')) {
                              return Filesystem.requestPermissions()
                                      .then(async (publicStorage) => {
                                        const permissionsRequested = await publicStorage.publicStorage;
                                        console.log('prompt || denied || prompt-with-rationale');
                                        return `${permissionsRequested}` === 'granted';
                                      });
                            }
                            return true;
                          });
                }
                return true;
              },
              async downloadFallback(doc) {
                this.loading = true;
                this.pdf = null;
                this.setAsSeenNotification(doc.id);

                await this.hasFilePermisions().then(() => {
                  axios.get(doc.url, {
                    responseType: 'arraybuffer',
                  }).then(async (success) => {
                    this.hasFilePermisions().then(async (resultData) => {
                     // if (resultData) {
                        const arrayUrl = doc.url.split('/');
                        const fileName = arrayUrl[arrayUrl.length - 1];
                        const pdfData = await success.data;
                        await this.writeAndPreviewPdf(pdfData, fileName.replace(' ', '-'));
                        this.pdf = doc.url;
                        await this.$router.push({
                          name: 'pdf-view',
                          params: { url: this.pdf, localuri: this.localuri },
                        });
                   //   } 
                    /*  else {
                        await Dialog.alert({
                          title: 'Información',
                          message: 'Denegaste los permisos de almacenamiento y esta operación no puede ser realizada',
                        });
                      } */
                    });
                  });
                });
              },
              async setAsSeenNotificationAndRedirectBooking(notification, owners_association_id) {
                this.isLoading = true;
                const parent = this;
                let selected;
                let bookingDate;
                let selectedAmenity;
                 await this.$store.dispatch('setOwnersAssociation', owners_association_id);
                console.log(`${notification} - ${owners_association_id}`);
                    await this.$store.dispatch('fetchAmenitiesBookings', owners_association_id).then(async () => {
                      console.log('descarga amenities');
                      const bookings = this.$store.getters.amenitiesBookings;
                      const amenities = this.$store.getters;
                      console.log(`bookingss: ${JSON.stringify(bookings)}`);
                      bookings.map((booking) => {
                        const amenityId = JSON.parse(Object.keys(booking));
                        const slotsDate = JSON.parse(JSON.stringify(booking[Object.keys(booking)[0]]));
                        console.log(amenityId);
                        console.log(slotsDate);
                        slotsDate.forEach((checkBelongsToUser) => {
                          const datekey = (Object.keys(checkBelongsToUser));
                          checkBelongsToUser[datekey.toString()].forEach((bookData) => {
                            const currentKeyDate = this._.first(datekey);

                            if (bookData.booking_id == notification) {
                              selected = bookData;
                              selectedAmenity = amenityId;
                              bookingDate = parent.$moment(currentKeyDate);
                              // eslint-disable-next-line consistent-return

                            }
                          });
                        });
                        return selected;
                      });
                      console.log(`selected ${JSON.stringify(selected)}`);

                      console.log(`selected ${JSON.stringify(selectedAmenity)}`);
                     /*
                      await this.$store.dispatch('fetchAmenity', selectedAmenity).then(() => {
                        const { amenity } = this.$store.getters.amenity;
                        console.log(`amenity ${amenity}`);
                        console.log('paso');

                        console.log('paso2');
                        const newObject = {
                          ...selected,
                          image: amenity.amenity_data.image_url,
                          title: amenity.name,
                          cancel_reason: selected.cancel_reason,
                          is_cancelled: selected.is_cancelled,
                          is_bookable: amenity.is_bookable,
                          type: amenity.type.name,
                          date: selected.format('DD-MM-Y'),
                          amenity_id: amenity.id,
                        };
console.log('aca');
                         this.$router.push(
                  {
                    name: 'detalle-reserva',
                    params: { booking: newObject },
                  },
              );
                      });
*/
                      this.isLoading = false;
                    }).catch(() => {
                      this.isLoading = false;
                    });
                    console.log(`fetch ${selectedAmenity}`);
              await this.getAmenity(selectedAmenity).then(async (amenity) => {
                 console.log(`data ${JSON.stringify(amenity)}`);
                 console.log(`selected ${JSON.stringify(selected)}`);
                const newObject = {
                  ...selected,
                  image: amenity.amenity_data.image_url,
                  title: amenity.name,
                  cancel_reason: selected.cancel_reason,
                  is_cancelled: selected.is_cancelled,
                  is_bookable: amenity.is_bookable,
                  type: amenity.type.name,
                  date: bookingDate.format('DD-MM-Y'),
                  amenity_id: amenity.id,
                };

                console.log(`newObject ${JSON.stringify(newObject)}`);
               await this.$router.push(
                 {
                   name: 'detalle-reserva',
                   params: { booking: newObject },
                 },
             );
                  this.isLoading = false;
                }).catch((err) => {
                  this.isLoading = false;
                  console.log(err);
                });

               this.isLoading = false;

              },
              async setAsSeenNotificationAndRedirect(notification) {
                this.setNotificationsAsSeen([notification.id])
                        .then(async () => {
                          this.$store.dispatch('fetchNotifications');
                          this.allSeen = true;
                          console.log(`${notification.news.substring(0, 8)}`);
                          await this.$store.dispatch('setOwnersAssociation', Number(notification.news.substring(0, 8))).then(async (data) => {
                            await this.$router.push(`/consorcios/${notification.news.substring(0, 8)}`);
                          });

                        })
                        .catch(console.log);

              },
              setAsSeen() {
                const notifications = this.notifications.map((n) => n.id);

                this.setNotificationsAsSeen(notifications)
                        .then(() => {
                          this.$store.dispatch('fetchNotifications');
                          this.allSeen = true;
                        })
                        .catch(console.log);
              },
              liquidacionExpensa(notificacion) {
                return notificacion.title.includes('Nueva publicación de expensas');
              },
              nuevoDocumento(notificacion) {
                return notificacion.title.includes('Nuevo Documento');
              },
              openDocumentWindow(url) {
                window.open(url);
              },
              setAsSeenNotification(noti) {
                this.setNotificationsAsSeen([noti])
                        .then(() => {
                          this.$store.dispatch('fetchNotifications');
                          this.allSeen = true;
                        })
                        .catch(console.log);
              },
            },
   async created() {
      this.isLoading = true;
      await this.$store.dispatch('fetchNotifications');
      this.allSeen = this.notifications.filter((n) => !n.seen).length === 0;

     this.isLoading = false;
    },
    
  };
</script>
<style lang='scss'>
  @media (max-width: 570px) {
    #mark-as-seen-button {
      width: 80%;
      margin: 0;
      z-index: 1000;
      position: fixed;
      bottom: 5rem;
    }
  }
  @media (min-width: 570px) {
    #mark-as-seen-button {
      margin: 0;
      position: fixed;
      bottom: 5rem;
      z-index: 1000;
      left: 80%;
      transform: translateX(-60%);
      width: max-content;

    }
  }
</style>
